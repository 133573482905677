import { Form, Select, Input } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { ManufactureForm } from '../types'

type Props = {
    form: CustomFormInstance<ManufactureForm>
}

const ComponentsQuantity: FC<Props> = ({ form }) => {
    const primaryComponents =
        form.getFieldValue<ManufactureForm['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    const [component, setComponent] = useState<string>(
        primaryComponents[0].component ?? ''
    )
    const [basePath, setbasePath] = useState<string[]>([
        'componentsQuantity',
        component,
    ])
    const handleChange = (value: SelectValue) => {
        setComponent((value ?? '').toString())
        setbasePath(['componentsQuantity', component])
    }

    return (
        <>
            <Form.Item label="Choose component:">
                <Select
                    options={primaryComponents.map((item) => ({
                        value: item.component ?? '',
                        label: item.component,
                    }))}
                    onChange={handleChange}
                    value={component}
                />
            </Form.Item>
            <Form.Item name={[...basePath, 'min']}>
                <Input type="number" placeholder="min" />
            </Form.Item>
            <Form.Item name={[...basePath, 'max']}>
                <Input type="number" placeholder="max" />
            </Form.Item>
        </>
    )
}

export default ComponentsQuantity
