import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { isAuth, user, claims, figures } from './slices'

export const store = configureStore({
    reducer: {
        isAuth,
        user,
        claims,
        figures,
    },
    middleware: getDefaultMiddleware().concat(logger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
