import { Form, Checkbox, Tooltip } from 'antd'
import { FC } from 'react'
import { useFormItemWatcher } from '../../../../hooks'
import { Common, CustomFormInstance } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import {
    MachineForm,
    MachineNames,
    ManufactureForm,
    SharedQuestionProps,
} from '../types'

type Values = Common<MachineForm, ManufactureForm>

type Props = {
    form: CustomFormInstance<Values>
    characteristicComponentIndex: number
} & SharedQuestionProps

const AdditionalCharacteristic: FC<Props> = ({
    form,
    characteristicComponentIndex,
    setContentBasedItemsToReview,
}) => {
    const primaryComponents =
        form.getFieldValue<Values['primaryComponents']>(
            MachineNames.primaryComponents
        ) ?? []

    const componentsForCharacteristics =
        form.getFieldValue<Values['componentsForCharacteristics']>(
            MachineNames.componentsForCharacteristics
        ) ?? []

    const primaryComponentId =
        componentsForCharacteristics[characteristicComponentIndex]

    const primaryComponent = primaryComponents.find(
        (comp) => comp?.id === primaryComponentId
    )

    const componentId = primaryComponent?.id ?? ''
    const componentName = primaryComponent?.component ?? ''

    const baseFormPath = [
        MachineNames.additionalCharacteristic,
        componentId,
    ] as [MachineNames.additionalCharacteristic, string]

    useFormItemWatcher<Values, boolean>({
        form,
        path: [...baseFormPath, 'defined'],
        callback: (isDefined) => {
            if (setContentBasedItemsToReview) {
                if (isDefined) {
                    setContentBasedItemsToReview((prev) => [
                        ...prev,
                        MachineNames.additionalCharacteristic,
                    ])
                } else {
                    setContentBasedItemsToReview((prev) => [
                        ...prev.filter(
                            (item) =>
                                item !== MachineNames.additionalCharacteristic
                        ),
                    ])
                }
            }
        },
    })

    return (
        <>
            <Form.Item
                name={[...baseFormPath, 'thermalConductivity']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having a thermal conductivity from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thermalConductivity', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thermalConductivity', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>W/(cm °C)</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'meltingPoint']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having a melting point from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'meltingPoint', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'meltingPoint', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>°C</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'specificHeat']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having specific heat from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'specificHeat', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'specificHeat', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>J/kg°C</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'defined']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        a{' '}
                        <Tooltip
                            title="The user defined characteristic is used to add characteristics that are not otherwise already provided in the form.  
                            For ease of reference, the claims show how language typed in the user defined characteristic will appear in the claims."
                        >
                            <span style={{ textDecoration: 'underline' }}>
                                user defined
                            </span>
                        </Tooltip>{' '}
                        characteristic
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'defined', 'description']}
                    >
                        <textarea
                            style={{
                                border: 'none',
                                borderBottom: '2px solid black',
                                width: '150px',
                                transform: 'translateY(-8px)',
                                backgroundColor: 'transparent',
                                outline: 'none',
                                padding: '0px 5px',
                            }}
                            rows={1}
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        (a patent professional will be notified to review this)
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default AdditionalCharacteristic
