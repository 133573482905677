import { createSlice } from '@reduxjs/toolkit'
import { STORAGE_KEYS } from '../../../utils/consts'

const session =
    localStorage.getItem(STORAGE_KEYS.ACCESS) &&
    localStorage.getItem(STORAGE_KEYS.REFRESH) &&
    localStorage.getItem(STORAGE_KEYS.ID)

const initialState = !!session as boolean

export const isAuth = createSlice({
    name: 'isAuth',
    initialState,
    reducers: {
        signIn: () => true,
        signOut: () => false,
    },
})

export const { signIn, signOut } = isAuth.actions
export default isAuth.reducer
