import styled from 'styled-components'

export const TextWrapper = styled.div`
    padding: 1rem 0.8rem 1rem 1rem;
    border-radius: 6px;
    border: 2px solid black;
`

export const StyledText = styled.input<{ width: number }>`
    font-family: monospace;
    width: ${({ width }) => width}px;
    border: none;
    background: none;
    &:focus {
        outline: none;
    }
`
