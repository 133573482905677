import { useEffect, useState } from 'react'

import { SquigglyArrow } from './SguigglyArrow.styles'

const Arrow: React.FC = (): JSX.Element => {
    const [line, setLine] = useState<string>('')
    useEffect(() => {
        let newLine = ''
        for (let i = 0; i <= 300; i++) {
            newLine += '~'
        }
        setLine(newLine)
    }, [])
    return <SquigglyArrow>{line}</SquigglyArrow>
}

export default Arrow
