import styled from 'styled-components'

export const SquigglyArrow = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: lighter;
    font-size: 3rem;
    letter-spacing: -8px;
`
