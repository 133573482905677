import { PositionableContainer } from 're-position'
import { useEffect } from 'react'
import { isClaim } from '../../../components/ClaimSelect/ClaimSelect'
import { useAppSelector } from '../../../store/hooks'
import { IClaimHandler } from './ClaimHandler.types'

const ClaimHandler: React.FC<IClaimHandler> = ({
    id,
    children,
    data: propsData,
    setClaimPosition,
}): JSX.Element => {
    const { claims } = useAppSelector((state) => state)
    const data = {
        top: propsData.top,
        left: propsData.left,
        height: '22px',
        width: '85px',
        rotation: '0deg',
    }
    useEffect(() => {
        const claimNode = document.querySelector(`.claimNode-${id}`)!
        const resize = claimNode.nextElementSibling! as HTMLElement
        resize.style.border = 'none'
    }, [id])
    const findClaimNumber = () => {
        let foundNumber
        const isFound = claims.components.find((x) => x.id === children)
        if (isFound) {
            foundNumber = isFound.number
        } else {
            claims.components.forEach((component) => {
                if (isClaim(component)) {
                    const isSubFound = component.components.find(
                        (x) => x.id === children
                    )
                    if (isSubFound) foundNumber = isSubFound.number
                }
            })
            if (!foundNumber) {
                foundNumber = claims.number
            }
        }
        return foundNumber
    }
    const claimNumber = findClaimNumber()
    return (
        <PositionableContainer
            className={`claimNode-${id}`}
            movable
            position={data}
            onUpdate={(e) => setClaimPosition(id, e)}
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {claimNumber || children}
        </PositionableContainer>
    )
}

export default ClaimHandler
