export const getCurrentListItemIndex = (
    questions: string[],
    currentQuestionIndex: number,
    questionName: string
) => {
    const questionsCopy = [...questions]
    const normalizedQuestions = questionsCopy.slice(0, currentQuestionIndex)
    let numOfItems = 0
    normalizedQuestions.forEach((item) => {
        if (item === questionName) {
            numOfItems += 1
            return false
        }
        return true
    })
    return numOfItems
}
