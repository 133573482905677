import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    name: string
}

const MethodSteps: FC<Props> = ({ form, name }) => {
    const primarySteps = form.getFieldValue<any>('methodOutline') ?? []
    return (
        <>
            <Form.Item name={name}>
                <Select
                    mode="multiple"
                    options={primarySteps.map((item: any) => ({
                        value: item.methodStep,
                        label: item.methodStep,
                    }))}
                />
            </Form.Item>
        </>
    )
}

export default MethodSteps
