import styled from 'styled-components'

export const StyledDiamond = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
        content: '';
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(0 50%, 50% 0, 100% 50%, 50% 100%);
    }
    &::after {
        content: '';
        background: #f2f2f2;
        position: absolute;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        clip-path: polygon(0 50%, 50% 0, 100% 50%, 50% 100%);
    }
`
