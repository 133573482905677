import { Form, Checkbox, Tooltip } from 'antd'
import { FC } from 'react'
import { useFormItemWatcher } from '../../../../hooks'
import { CustomFormInstance, ValueOf } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import { MachineForm, MachineNames, SharedQuestionProps } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
    characteristicComponentIndex: number
    characteristicSubComponentIndex: number
    countDiff: number
} & SharedQuestionProps

const AdditionalCharacteristic: FC<Props> = ({
    form,
    characteristicComponentIndex,
    characteristicSubComponentIndex,
    countDiff,
    setContentBasedItemsToReview,
}) => {
    const componentsForCharacteristics =
        form.getFieldValue<MachineForm['componentsForCharacteristics']>(
            'componentsForCharacteristics'
        ) ?? []

    const componentId =
        componentsForCharacteristics[characteristicComponentIndex - 1]

    const subComponentsForCharacteristics =
        form.getFieldValue<MachineForm['subcomponentsForCharacteristics']>(
            'subcomponentsForCharacteristics'
        ) ?? []

    const subComponents =
        form.getFieldValue<
            ValueOf<MachineForm['primaryComponentSubComponentsList']>
        >(['primaryComponentSubComponentsList', componentId]) ?? []

    const subComponentId =
        subComponentsForCharacteristics[componentId][
            characteristicSubComponentIndex - countDiff
        ]

    const subComponentName =
        subComponents.find((item) => item.id === subComponentId)?.component ??
        ''

    const baseFormPath = [
        MachineNames.subAdditionalCharacteristic,
        subComponentId,
    ] as [MachineNames.subAdditionalCharacteristic, string]

    useFormItemWatcher<MachineForm, boolean>({
        form,
        path: [...baseFormPath, 'defined'],
        callback: (isDefined) => {
            if (setContentBasedItemsToReview) {
                if (isDefined) {
                    setContentBasedItemsToReview((prev) => [
                        ...prev,
                        MachineNames.subAdditionalCharacteristic,
                    ])
                } else {
                    setContentBasedItemsToReview((prev) => [
                        ...prev.filter(
                            (item) =>
                                item !==
                                MachineNames.subAdditionalCharacteristic
                        ),
                    ])
                }
            }
        },
    })

    return (
        <>
            <Form.Item
                name={[...baseFormPath, 'thermalConductivity']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {subComponentName} has a thermal conductivity from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thermalConductivity', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thermalConductivity', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>W/(cm °C)</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'meltingPoint']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {subComponentName} has a melting point from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'meltingPoint', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'meltingPoint', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>°C</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'specificHeat']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {subComponentName} has specific heat from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'specificHeat', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'specificHeat', 'to']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>J/kg°C</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'defined']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        a{' '}
                        <Tooltip
                            title="The user defined characteristic is used to add characteristics that are not otherwise already provided in the form.  
                            For ease of reference, the claims show how language typed in the user defined characteristic will appear in the claims."
                        >
                            <span style={{ textDecoration: 'underline' }}>
                                user defined
                            </span>
                        </Tooltip>{' '}
                        characteristic
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'defined', 'description']}
                    >
                        <textarea
                            style={{
                                border: 'none',
                                borderBottom: '2px solid black',
                                width: '150px',
                                transform: 'translateY(-8px)',
                                backgroundColor: 'transparent',
                                outline: 'none',
                                padding: '0px 5px',
                            }}
                            rows={1}
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        (a patent professional will be notified to review this)
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default AdditionalCharacteristic
