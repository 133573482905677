import { Form, Input } from 'antd'
import styled from 'styled-components'

export const StyledSpan = styled.span`
    display: inline-block;
`
export const StyledFormItem = styled(Form.Item)`
    display: inline-block;
    margin: 0 5px;
`

export const StyledInput = styled(Input)`
    border: none;
    border-bottom: 2px solid black;
    width: 60px;
    transform: translateY(-8px);
    background-color: transparent;
    outline: none;
    padding: 0px 5px;
    &:focus {
        outline: none;
        border: none;
        border-bottom: 2px solid black;
        box-shadow: none;
    }
`
