import { Rule, RuleObject } from 'antd/lib/form'

export const passwordRepeatRules: Rule[] = [
    {
        required: true,
        message: 'Password repeat is required',
    },
    ({ getFieldValue }): RuleObject => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
            }
            return Promise.reject(
                new Error('The two passwords that you entered do not match!')
            )
        },
    }),
]

export const checkboxRules: Rule[] = [
    {
        required: true,
        transform: (value) => value || undefined,
        type: 'boolean',
        message: 'You have to accept terms and conditions',
    },
]
