import { Rule } from 'antd/lib/form'
import { authApi } from '../api/api'
import store from '../store'
import { updateUser } from '../store/slices/user/user'
import { STORAGE_KEYS } from './consts'
import { ISaveUser, REQUEST_STATUS } from './types'

export const isLoading = (val: REQUEST_STATUS): boolean => {
    return val === REQUEST_STATUS.PENDING
}

export const getRegularRules = (val: string): Rule[] => {
    return [
        {
            required: true,
            message: `${val} is required`,
        },
    ]
}

export const fetchSaveUser = (data: ISaveUser): Promise<void> => {
    return authApi
        .put(`/api/users/update/${localStorage.getItem(STORAGE_KEYS.ID)}`, {
            ...data,
        })
        .then(() => {
            store.dispatch(updateUser(data))
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const getClaimNum = (num: number, offset = 0) => {
    const newNum = num + offset
    let prefix = ''
    if (newNum < 100) {
        prefix += '0'
    }
    if (newNum < 10) {
        prefix += '0'
    }
    return `${prefix}${newNum}`
}

export const generateId = () => Math.random().toString(36).substr(2, 9)
