import { useEffect } from 'react'
import { CustomFormInstance } from '../types'

type Args<T, U> = {
    form: CustomFormInstance<T>
    path: Parameters<CustomFormInstance<T>['getFieldValue']>[0]
    callback: (data: U) => void
}

function useFormItemWatcher<T, U>({ form, path, callback }: Args<T, U>) {
    const data = form.getFieldValue<U>(path)
    useEffect(() => {
        if (callback) {
            callback(data)
        }
    }, [JSON.stringify(data)])
}

export default useFormItemWatcher
