import { PositionableContainer } from 're-position'
import React, { useCallback, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { INodeHandler } from './NodeHandler.types'

const NodeHandler: React.FC<INodeHandler> = ({
    id,
    children,
    data,
    setElement,
}): JSX.Element => {
    const hideControls = useCallback(() => {
        const node = document.querySelector(`.node-${id}`)!
        const resize = node.nextElementSibling! as HTMLElement
        resize.style.opacity = '0%'
    }, [id])
    const handleOutsideClick = (e: any): void => {
        if (
            e.target.id === 'diagramsLibContainer' ||
            e.path[2].id === 'diagramsLibContainer' ||
            e.path[3].id === 'diagramsLibContainer'
        ) {
            const node = document.querySelector(`.node-${id}`)!
            const resize = node.nextElementSibling! as HTMLElement
            hideControls()
            resize.addEventListener('pointerleave', hideControls)
        }
    }
    useEffect(() => {
        const node = document.querySelector(`.node-${id}`)!
        const resize = node.nextElementSibling! as HTMLElement
        resize.style.opacity = '0%'
        const showControls = (): void => {
            resize.style.opacity = '100%'
        }
        const handleClick = (): void => {
            showControls()
            resize.removeEventListener('pointerleave', hideControls)
        }
        resize.addEventListener('click', handleClick)
        resize.addEventListener('pointerenter', showControls)
        resize.addEventListener('pointerleave', hideControls)
        for (let i = 0; i < 8; i++) {
            const item = resize.children[i] as HTMLElement
            item.style.opacity = '1'
            item.style.background = 'red'
            if (i === 0) {
                item.style.left = 'calc(.7rem - 1px)'
                item.style.top = '0'
                item.style.width = 'calc(100% - 1.1rem + 2px)'
                item.style.height = '.3rem'
                item.style.border = 'none'
                item.style.borderRadius = '0%'
            }
            if (i === 1) {
                item.style.left = 'calc(100% - .4rem)'
                item.style.top = '-.3rem'
                item.style.borderRadius = '50%'
                item.style.width = '1rem'
                item.style.height = '1rem'
                item.style.border = 'none'
            }
            if (i === 2) {
                item.style.right = '2px'
                item.style.top = 'calc(.7rem - 1px)'
                item.style.width = '.3rem'
                item.style.height = 'calc(100% - 1.1rem + 2px)'
                item.style.border = 'none'
                item.style.borderRadius = '0%'
            }
            if (i === 3) {
                item.style.left = 'calc(100% - .4rem)'
                item.style.top = 'calc(100% - .4rem)'
                item.style.borderRadius = '50%'
                item.style.width = '1rem'
                item.style.height = '1rem'
                item.style.border = 'none'
            }
            if (i === 4) {
                item.style.left = 'calc(.7rem - 1px)'
                item.style.bottom = '0'
                item.style.width = 'calc(100% - 1.1rem + 2px)'
                item.style.height = '.3rem'
                item.style.border = 'none'
                item.style.borderRadius = '0%'
            }
            if (i === 5) {
                item.style.left = '-.3rem'
                item.style.top = 'calc(100% - .4rem)'
                item.style.borderRadius = '50%'
                item.style.width = '1rem'
                item.style.height = '1rem'
                item.style.border = 'none'
            }
            if (i === 6) {
                item.style.left = '0'
                item.style.top = 'calc(.7rem - 1px)'
                item.style.width = '.3rem'
                item.style.height = 'calc(100% - 1.1rem + 2px)'
                item.style.border = 'none'
                item.style.borderRadius = '0%'
            }
            if (i === 7) {
                item.style.left = '-.3rem'
                item.style.top = '-.3rem'
                item.style.borderRadius = '50%'
                item.style.width = '1rem'
                item.style.height = '1rem'
                item.style.border = 'none'
            }
        }
        return () => {
            resize.removeEventListener('click', handleClick)
            resize.removeEventListener('pointerenter', showControls)
            resize.removeEventListener('pointerleave', hideControls)
        }
    }, [id, hideControls])
    return (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <PositionableContainer
                className={`node-${id}`}
                movable
                resizable
                rotatable
                position={data}
                onUpdate={(e) => setElement(id, e)}
            >
                {children}
            </PositionableContainer>
        </OutsideClickHandler>
    )
}

export default NodeHandler
