import {
    Arrow,
    Circle,
    Cone,
    Cube,
    Cylinder,
    Diamond,
    Ellipsis,
    Square,
    Triangle,
    PointerArrow,
    SquigglyArrow,
    SquigglyArrowPointer,
    Text,
} from '../components/DiagramTools'

export const NODE_TYPES = {
    square: Square,
    circle: Circle,
    cone: Cone,
    cube: Cube,
    cylinder: Cylinder,
    diamond: Diamond,
    ellipsis: Ellipsis,
    triangle: Triangle,
    arrow: Arrow,
    image: Square,
    pointerArrow: PointerArrow,
    squigglyArrow: SquigglyArrow,
    squigglyArrowPointer: SquigglyArrowPointer,
} as const

export type NODE_KEYS = keyof typeof NODE_TYPES

export interface INode {
    id: string
    src?: string
    width: string
    height: string
    rotation: string
    top: string
    left: string
    component: NODE_KEYS | 'image'
    text: string
}

export interface IClaimNode {
    id: string
    top: string
    left: string
}
export interface IFigureClaim {
    claim: undefined | string
    components: string[]
}
export interface IFigure {
    elements: INode[]
    claimElements: IClaimNode[]
    claims: IFigureClaim
}
export interface IDiagramsLibHandlers {
    save: () => IFigure[]
}
export interface IDiagramsLib {
    initialState: null | IFigure[]
    onSave?: any
}
