import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFigure } from '../../../DiagramsLib/DiagramsLib.types'

interface IFiguresState {
    figureIndex: number
    figures: IFigure[]
}

const initialState = {
    figureIndex: 0,
    figures: [],
} as IFiguresState

export const figures = createSlice({
    name: 'figures',
    initialState,
    reducers: {
        incrementFigureIndex: (state) => {
            return {
                ...state,
                figureIndex: state.figureIndex + 1,
            }
        },
        decrementFigureIndex: (state) => {
            return {
                ...state,
                figureIndex: state.figureIndex - 1,
            }
        },
        setFigures: (state, action: PayloadAction<IFigure[]>) => {
            return {
                ...state,
                figures: action.payload,
            }
        },
    },
})

export const { incrementFigureIndex, decrementFigureIndex, setFigures } =
    figures.actions
export default figures.reducer
