import { useEffect, useState } from 'react'
import { StyledSquigglyArrowPointer } from './SquigglyArrowPointer.styles'
import Img from '../../../img/next.png'

const SquigglyArrowPointer: React.FC = (): JSX.Element => {
    const [line, setLine] = useState<string>('')
    useEffect(() => {
        let newLine = ''
        for (let i = 0; i <= 300; i++) {
            newLine += '~'
        }
        setLine(newLine)
    }, [])
    return (
        <StyledSquigglyArrowPointer>
            <p>{line}</p>
            <img src={Img} alt="Next" />
        </StyledSquigglyArrowPointer>
    )
}

export default SquigglyArrowPointer
