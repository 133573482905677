import styled from 'styled-components'

export const PointerArrow = styled.div`
    width: calc(100%);
    height: 1px;
    top: -2px;
    display: inline-block;
    background: black;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    img {
        position: absolute;
        top: -7px;
        right: -3px;
        height: 15px;
        width: 10px;
    }
`
