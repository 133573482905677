import { Helmet } from 'react-helmet'
import { Redirect, Route as RouterRoute } from 'react-router'
import { Navbar } from '..'
import { useAppSelector } from '../../store/hooks'
import { IRoute } from './Route.types'

const Route: React.FC<IRoute> = ({
    authenticated = false,
    title,
    children,
    ...rest
}): JSX.Element => {
    const { isAuth, user } = useAppSelector((state) => state)
    if (authenticated) {
        if (isAuth && user)
            return (
                <RouterRoute {...rest}>
                    <Navbar />
                    {children}
                </RouterRoute>
            )
        if (isAuth && !user)
            return (
                <>
                    <Helmet title={title} />
                </>
            )
        return <Redirect to="/sign-in" />
    }
    return isAuth && user ? (
        <Redirect to="/projects" />
    ) : (
        <RouterRoute {...rest}>{children}</RouterRoute>
    )
}

export default Route
