import { message } from 'antd'
import axios from 'axios'
import store from '../store'
import { signOut } from '../store/slices/isAuth/isAuth'
import { API_URL, STORAGE_KEYS } from '../utils/consts'

export const api = axios.create({
    baseURL: API_URL,
})
export const authApi = axios.create({
    baseURL: API_URL,
})
authApi.interceptors.request.use(
    async function requestSuccess(response) {
        const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS)
        response.headers.Authorization = `Bearer ${accessToken}`
        return response
    },
    function requestError(error) {
        return Promise.reject(error)
    }
)
authApi.interceptors.response.use(
    function onResponse(response) {
        return response
    },
    async function onResponseError(error) {
        try {
            const originalRequest = error.config
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                const refresh = localStorage.getItem(STORAGE_KEYS.REFRESH)
                if (!refresh) {
                    return Promise.reject(error)
                }
                const response = (
                    await api.get('/api/auth/refresh', {
                        headers: {
                            Authorization: `Bearer ${refresh}`,
                        },
                    })
                ).data
                localStorage.setItem(STORAGE_KEYS.ACCESS, response.token)
                originalRequest.headers.Authorization = `Bearer ${response.token}`
                return authApi(originalRequest)
            }
            return Promise.reject(error)
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.removeItem(STORAGE_KEYS.ACCESS)
                localStorage.removeItem(STORAGE_KEYS.REFRESH)
                store.dispatch(signOut())
            } else {
                message.error('An unexpected error occurred')
            }
            return Promise.reject(error)
        }
    }
)
