import { ChangeEvent, useState } from 'react'
import { TextWrapper, StyledText } from './Text.styles'
import { IText } from './Text.types'

const Text: React.FC<IText> = ({
    text: propsText,
    setText: propsSetText,
}): JSX.Element => {
    const [text, setText] = [propsText!, propsSetText!]
    return (
        <TextWrapper>
            <StyledText
                type="text"
                value={text}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setText(e.currentTarget.value)
                }
                width={(text.length + 1) * 8.4}
            />
        </TextWrapper>
    )
}

export default Text
