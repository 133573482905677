import styled from 'styled-components'

export const StyledSquare = styled.div`
    width: 100%;
    height: 100%;
    display: inline-block;
    border: 2px solid black;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    background: #f2f2f2;
`
