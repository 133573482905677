import styled from 'styled-components'

export const StyledCube = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 20px;
    &::before {
        content: '';
        width: calc(100% - 16px);
        height: 20px;
        transform: skewX(-40deg);
        position: absolute;
        top: 0;
        left: 8px;
        border: 2px solid black;
        background: #cccccc;
    }
    &::after {
        content: '';
        height: calc(100% - 20px);
        width: 17px;
        transform: skewY(-50deg);
        position: absolute;
        top: 10px;
        left: calc(100% - 17px);
        border: 2px solid black;
        background: #cccccc;
    }
    div {
        width: calc(100% - 17px);
        height: calc(100%);
        border-bottom: 2px solid black;
        border-left: 2px solid black;
        background: #f2f2f2;
    }
`
