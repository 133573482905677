import { Form, Checkbox, Tooltip } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import { ManufactureForm } from '../types'

type Props = {
    form: CustomFormInstance<ManufactureForm>
    characteristicComponentIndex: number
}

const CharacteristicManufacture: FC<Props> = ({
    form,
    characteristicComponentIndex,
}) => {
    const componentsForCharacteristics =
        form.getFieldValue<ManufactureForm['componentsForCharacteristics']>(
            'componentsForCharacteristics'
        ) ?? []

    const primaryComponents =
        form.getFieldValue<ManufactureForm['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    const primaryComponentId =
        componentsForCharacteristics[characteristicComponentIndex]

    const primaryComponent = primaryComponents.find(
        (comp) => comp?.id === primaryComponentId
    )
    const componentName = primaryComponent?.component ?? ''
    const componentId = primaryComponent?.id ?? ''

    const baseFormPath = ['characteristic', componentId]
    return (
        <>
            <label>
                Select characteristics that apply to the {componentName} and
                fill in any blanks for the selected characteristics:
            </label>
            <Form.Item
                name={[...baseFormPath, 'density']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a density of
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'density', 'from']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'density', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>kg/m3</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'porosity']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a porosity of
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'porosity', 'from']}
                    >
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'porosity', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'hardness']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a hardness of
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'hardness', 'from']}
                    >
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'hardness', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>in brinell hardness scale</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item name={[...baseFormPath, 'ph']} valuePropName="checked">
                <Checkbox>
                    <StyledSpan>the {componentName} has a pH of</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'ph', 'from']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'ph', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'length']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a length from
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'length', 'from']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'length', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>m</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'width']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a width from
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'width', 'from']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'width', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>m</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'thickness']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has a thickness from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thickness', 'from']}
                    >
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'thickness', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>m</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'angle']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} has the angle from
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'angle', 'from']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'angle', 'to']}>
                        <StyledInput type="number" />
                    </StyledFormItem>
                    <StyledSpan>degrees</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'characteristic']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        a{' '}
                        <Tooltip
                            title="The user defined characteristic is used to add characteristics that are not otherwise already provided in the form.  
                            For ease of reference, the claims show how language typed in the user defined characteristic will appear in the claims."
                        >
                            <span style={{ textDecoration: 'underline' }}>
                                user defined
                            </span>
                        </Tooltip>{' '}
                        characteristic
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default CharacteristicManufacture
