import { Rule } from 'antd/lib/form'

export const API_URL = 'https://api.innovationx.ai/'

export enum STORAGE_KEYS {
    ID = 'uID',
    ACCESS = 'aToken',
    REFRESH = 'rToken',
}

export const emailRules: Rule[] = [
    {
        type: 'email',
        message: 'E-mail is incorrect',
    },
    {
        required: true,
        message: 'E-mail is required',
    },
]

export const passwordRules: Rule[] = [
    {
        required: true,
        message: 'Password is required',
    },
    {
        pattern:
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        message:
            'Password must contain minimum 8 characters, letters, numbers and special character',
    },
]
