import { Form, Input, Button, Checkbox, Spin } from 'antd/lib'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { api } from '../../api/api'
import { emailRules, passwordRules } from '../../utils/consts'
import { getRegularRules, isLoading } from '../../utils/functions'
import { REQUEST_STATUS } from '../../utils/types'
import { checkboxRules, passwordRepeatRules } from './SignUp.consts'
import { CustomForm, CustomRow } from './SignUp.styles'
import { ISignUpForm } from './SignUp.types'

const SignUp: React.FC = () => {
    const history = useHistory()
    const [form] = useForm()
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(
        REQUEST_STATUS.NONE
    )
    const onSubmit = (formData: unknown): void => {
        setRequestStatus(REQUEST_STATUS.PENDING)
        const data = formData as ISignUpForm
        api.post('/api/users/create', { ...data })
            .then(() => {
                history.push({
                    pathname: '/sign-in',
                    search: '?registerRedirect',
                })
            })
            .catch(() => {
                form.setFields([
                    {
                        name: 'email',
                        errors: ['This e-mail address is already taken.'],
                    },
                ])
                setRequestStatus(REQUEST_STATUS.FAILED)
            })
    }
    const onSubmitFailed = (): void => {
        setRequestStatus(REQUEST_STATUS.FAILED)
    }
    return (
        <>
            <Helmet title="Sign Up" />
            <CustomRow justify="center" align="middle">
                <Spin spinning={isLoading(requestStatus)}>
                    <CustomForm
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        requiredMark="optional"
                        onFinish={onSubmit}
                        onFinishFailed={onSubmitFailed}
                        form={form}
                    >
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={emailRules}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={passwordRules}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Repeat password"
                            name="repeat_password"
                            dependencies={['password']}
                            rules={passwordRepeatRules}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Fullname"
                            name="fullName"
                            rules={getRegularRules('Fullname')}
                            hasFeedback
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            name="remember"
                            valuePropName="checked"
                            rules={checkboxRules}
                        >
                            <Checkbox>
                                I agree to the InnovationX terms and conditions
                            </Checkbox>
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 2 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        <Link to="/sign-in">I already have an account</Link>
                    </CustomForm>
                </Spin>
            </CustomRow>
        </>
    )
}

export default SignUp
