import { FormInstance, Form, Checkbox, Input, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { FC } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { CustomFormInstance } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import {
    CompositionForm,
    MachineForm,
    ManufactureForm,
    MethodsForm,
} from '../types'

type Props = {
    form:
        | CustomFormInstance<MachineForm>
        | CustomFormInstance<ManufactureForm>
        | CustomFormInstance<CompositionForm>
        | CustomFormInstance<MethodsForm>
    characteristicComponentIndex: number
}

const DrawingsDescription: FC<Props> = ({
    form,
    characteristicComponentIndex,
}) => {
    const { figures } = useAppSelector((state) => state)
    // const t = form.getFieldValue('componentsForCharacteristics')
    // console.log(t)
    // const c = form.getFieldValue('subComponents')
    // console.log(c)

    const componentName = `figure ${figures.figureIndex + 1}`

    const baseFormPath = ['drawingsDescription', 'figure', figures.figureIndex]
    return (
        <>
            <Form.Item name={baseFormPath}>
                <label>
                    What type of illustration is provided in {componentName}?
                </label>
                <Form.Item name={[...baseFormPath, 'illustrationType']}>
                    <Select>
                        <Option value="schematic">Schematic</Option>
                        <Option value="scale">To scale</Option>
                    </Select>
                </Form.Item>
                <label>What type of figure is this?</label>
                <Form.Item name={[...baseFormPath, 'figureType']}>
                    <Select>
                        <Option value="flow">Flow chart</Option>
                        <Option value="illustration">
                            Illustration of an embodiment
                        </Option>
                    </Select>
                </Form.Item>
                <label>Select all that apply</label>
                <Form.Item name={[...baseFormPath, 'figureElements']}>
                    <Select mode="multiple" aria-multiline="true">
                        <Option value="photograph">A photograph</Option>
                        <Option value="rendering">
                            a computer-generated rendering that is similar to a
                            photograph
                        </Option>
                        <Option value="color">
                            color besides black and white
                        </Option>
                        <Option value="sketch">a sketch drawn by hand</Option>
                        <Option value="draftedImage">
                            a computer-drafted image that has not been drafted
                            by a professional familiar with the rules for patent
                            drawings
                        </Option>
                        <Option value="formalDrawing">
                            a formal drawing that has been drafted by a
                            professional familiar with the rules for patent
                            drawings
                        </Option>
                    </Select>
                </Form.Item>
                <label>What does the figure describe?</label>
                <Form.Item name={[...baseFormPath, 'figureDescription']}>
                    <Input />
                </Form.Item>
            </Form.Item>
        </>
    )
}

export default DrawingsDescription
