import { MinusCircleOutlined } from '@ant-design/icons'
import { Input, Button, Select, Form, Row, Col } from 'antd'
import { FC } from 'react'
import { indefiniteArticlesOptions } from '../../../../const'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, MachineNames, ManufactureForm } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm> | CustomFormInstance<ManufactureForm>
    subComponentIndex: number
}

const ComponentSubComponents: FC<Props> = ({ form, subComponentIndex }) => {
    const mainSubcomponents =
        form.getFieldValue<MachineForm['mainSubcomponents']>(
            MachineNames.mainSubcomponents
        ) ?? []

    const primaryComponents =
        form.getFieldValue<MachineForm['primaryComponents']>(
            MachineNames.primaryComponents
        ) ?? []

    const primaryComponentId = mainSubcomponents[subComponentIndex]

    const primaryComponent = primaryComponents.find(
        (comp) => comp?.id === primaryComponentId
    )

    if (!primaryComponent) {
        console.warn(
            'There was no primary component with id of',
            primaryComponentId
        )
    }

    const primaryComponentName = primaryComponent?.component ?? ''
    return (
        <>
            <Form.Item
                label={`Please list the subcomponents of the ${primaryComponentName} that you would like to include`}
            />
            <Form.List
                name={[
                    MachineNames.primaryComponentSubComponentsList,
                    primaryComponent?.id ?? '',
                ]}
                rules={[
                    {
                        validator: async (_, components) => {
                            if (
                                components === undefined ||
                                components?.length === 0
                            )
                                throw new Error('At least one sub component!')
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, errors) => (
                    <>
                        {fields.map((field) => (
                            <Row
                                key={field.key}
                                align="middle"
                                gutter={[12, 0]}
                            >
                                <Col span={10}>
                                    <Form.Item noStyle shouldUpdate>
                                        {() => (
                                            <Form.Item
                                                {...field}
                                                label="Indefinite Article"
                                                name={[
                                                    field.name,
                                                    'indefiniteArticle',
                                                ]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'indefiniteArticle',
                                                ]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Indefinite article is required',
                                                    },
                                                ]}
                                                initialValue=" "
                                            >
                                                <Select
                                                    options={
                                                        indefiniteArticlesOptions
                                                    }
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        {...field}
                                        label="subcomponent"
                                        name={[field.name, 'component']}
                                        fieldKey={[field.fieldKey, 'component']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Sub component name is required',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col
                                    style={{
                                        textAlign: 'center',
                                        paddingTop: '0.6rem',
                                    }}
                                    span={2}
                                >
                                    {fields.length > 0 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            style={{
                                                color: '#f00',
                                                fontSize: '1.2rem',
                                            }}
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                        ))}
                        <Form.ErrorList errors={errors.errors} />
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add subcomponent
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}

export default ComponentSubComponents
