import { Form, Select, Input, Radio, RadioChangeEvent } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { ChangeEvent, FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm, MethodsNames } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    name: MethodsNames.methodOutlineDescription
}

const MethodOutlineDescription: FC<Props> = ({ form, name }) => {
    const methodOutline =
        form.getFieldValue<MethodsForm['methodOutline']>('methodOutline') ?? []

    const [choosenMethodOutline, setChoosenMethodOutline] = useState<string>(
        methodOutline[0].methodStep
    )
    const [choosenDescriptionParts, setChoosenDescriptionParts] = useState<
        string[]
    >(['', '', ''])

    const [choosenDescriptionIndex, setChoosenDescriptionIndex] = useState<
        number | null
    >(null)

    const basePath = [name, choosenMethodOutline]
    const options = methodOutline.map((item) => ({
        value: item.methodStep,
        label: item.methodStep,
    }))

    const additionalOptionsCount = [
        ['component 1', 'location 1', 'location 2'],
        ['component 1', 'component 2', 'composition 1'],
        ['component 1', 'component 2', 'product 1'],
        ['component 1'],
        ['component 1'],
        ['group 1', 'selection 1', 'selection 2'],
        ['number 1', 'number 2', 'sum 1'],
        ['number 1', 'number 2', 'difference 1'],
        ['number 1', 'number 2', 'product 1'],
        ['number 1', 'number 2', 'quotient 1'],
    ]

    const exampleOptions = [
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 0
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[0][0]
        } from ${
            choosenDescriptionParts[1] !== '' && choosenDescriptionIndex === 0
                ? choosenDescriptionParts[1]
                : additionalOptionsCount[0][1]
        } to ${
            choosenDescriptionParts[2] !== '' && choosenDescriptionIndex === 0
                ? choosenDescriptionParts[2]
                : additionalOptionsCount[0][2]
        }, thereby providing relocated ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 0
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[0][0]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 1
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[1][0]
        } and ${
            choosenDescriptionParts[1] !== '' && choosenDescriptionIndex === 1
                ? choosenDescriptionParts[1]
                : additionalOptionsCount[1][1]
        } to provide ${
            choosenDescriptionParts[2] !== '' && choosenDescriptionIndex === 1
                ? choosenDescriptionParts[2]
                : additionalOptionsCount[1][2]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 2
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[2][0]
        } with ${
            choosenDescriptionParts[1] !== '' && choosenDescriptionIndex === 2
                ? choosenDescriptionParts[1]
                : additionalOptionsCount[2][1]
        } to provide ${
            choosenDescriptionParts[2] !== '' && choosenDescriptionIndex === 2
                ? choosenDescriptionParts[2]
                : additionalOptionsCount[2][2]
        }`,
        `${choosenMethodOutline} untreated ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 3
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[3][0]
        } to provide treated ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 3
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[3][0]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 4
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[4][0]
        } to provide a ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 4
                ? `${choosenDescriptionParts[0]}s`
                : additionalOptionsCount[4][0]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 5
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[5][0]
        } to provide ${
            choosenDescriptionParts[1] !== '' && choosenDescriptionIndex === 5
                ? choosenDescriptionParts[1]
                : additionalOptionsCount[5][1]
        } and ${
            choosenDescriptionParts[2] !== '' && choosenDescriptionIndex === 5
                ? choosenDescriptionParts[2]
                : additionalOptionsCount[5][2]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 6
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[6][0]
        } and ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 6
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[6][1]
        } to provide ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 6
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[6][2]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 7
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[7][0]
        } from ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 7
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[7][1]
        } to provide ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 7
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[7][2]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 8
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[8][0]
        } and ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 8
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[8][1]
        } to provide ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 8
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[8][2]
        }`,
        `${choosenMethodOutline} ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 9
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[9][0]
        } by ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 9
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[9][1]
        } to provide ${
            choosenDescriptionParts[0] !== '' && choosenDescriptionIndex === 9
                ? choosenDescriptionParts[0]
                : additionalOptionsCount[9][2]
        }`,
    ]

    const handleChange = (value: SelectValue) => {
        const method = (value ?? '').toString()
        setChoosenMethodOutline(method)
        const modifiedOutline =
            form.getFieldValue<MethodsForm['methodOutlineDescription']>(name)[
                method
            ]
        const index = modifiedOutline?.choosenDescriptionIndex ?? null
        setChoosenDescriptionIndex(index)
        const parts = [
            modifiedOutline?.part0 ?? '',
            modifiedOutline?.part1 ?? '',
            modifiedOutline?.part2 ?? '',
        ]
        setChoosenDescriptionParts(parts)
    }

    const handleRadioChange = async (event: RadioChangeEvent) => {
        const index = event.target.value
        setChoosenDescriptionIndex(index)
        setChoosenDescriptionParts(['', '', ''])
        const temp =
            form.getFieldValue<MethodsForm['methodOutlineDescription']>(name)
        temp[choosenMethodOutline].part0 = ''
        temp[choosenMethodOutline].part1 = ''
        temp[choosenMethodOutline].part2 = ''
        form.setFieldsValue({ [name]: temp })
    }

    const updateSentence = (
        index: number,
        event: ChangeEvent<HTMLInputElement>
    ) => {
        choosenDescriptionParts.splice(index, 1, event?.target?.value)
        if (choosenDescriptionIndex != null) {
            const temp =
                form.getFieldValue<MethodsForm['methodOutlineDescription']>(
                    name
                )
            temp[choosenMethodOutline].finalSentence =
                exampleOptions[choosenDescriptionIndex]
            form.setFieldsValue({ [name]: temp })
        }
    }

    let additionalOptions
    if (choosenDescriptionIndex != null) {
        additionalOptions = additionalOptionsCount[choosenDescriptionIndex].map(
            (item, index) => (
                <Form.Item
                    name={[...basePath, `part${index}`]}
                    label={`Provide ${item}`}
                    hasFeedback
                    rules={[
                        { required: true, message: 'This field is required!' },
                    ]}
                >
                    <Input
                        type="text"
                        onChange={(e) => {
                            updateSentence(index, e)
                        }}
                    />
                </Form.Item>
            )
        )
    }

    const radioOptions = exampleOptions.map((item, index) => (
        <Radio value={index}>{item}</Radio>
    ))

    return (
        <>
            <Form.Item label="Choose method step you want to describe">
                <Select
                    options={options}
                    onChange={handleChange}
                    value={choosenMethodOutline}
                />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'choosenDescriptionIndex']}
                label="Which example is most similar to the method step that you have"
                rules={[{ required: true, message: 'This is required!' }]}
            >
                <Radio.Group
                    onChange={handleRadioChange}
                    value={choosenDescriptionIndex}
                >
                    {radioOptions}
                </Radio.Group>
            </Form.Item>
            {additionalOptions}
        </>
    )
}

export default MethodOutlineDescription
