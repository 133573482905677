import { Select, Tooltip } from 'antd'
import { useEffect, useState } from 'react'

import { useAppSelector } from '../../store/hooks'
import {
    ClaimsState,
    IClaim,
    IClaimComponent,
    IExtendedClaimComponent,
} from '../../store/slices/claims/claims.types'
import { IClaimSelect } from './ClaimSelect.types'

const { Option } = Select

export const isClaim = (
    component: IClaimComponent | IExtendedClaimComponent
): component is IExtendedClaimComponent => {
    return (component as IExtendedClaimComponent).components !== undefined
}

const generateTooltipText = (
    id: string,
    number: string,
    claims: ClaimsState
) => {
    const figure = number.substring(0, 3)
    let text = ''
    if (id === '000') {
        text += `It's the main claim - ${
            claims.name
        }. It has several components: ${claims.components.map(
            (component) => ` ${component.name}`
        )}. `
    }
    const findComponent = claims.components.find(
        (component) => component.id === id
    )
    if (findComponent) {
        text += `It's the ${findComponent.name} component, which is a part of ${claims.name}. `
        if (isClaim(findComponent)) {
            text += `It has several subcomponents:${findComponent.components.map(
                (sub) => ` ${sub.name}`
            )}`
            text += '. '
        }
    } else {
        claims.components.forEach((component) => {
            if (isClaim(component)) {
                component.components.forEach((sub) => {
                    if (sub.id === id) {
                        text += `It's the ${sub.name} subcomponent, which is a part of ${component.name}. `
                    }
                })
            }
        })
    }
    if (number.length !== 3) {
        text += `It has been used on figure ${figure} for the first time. `
    }
    return text
}

const getClaimsData = (claims: ClaimsState): IClaim[] => {
    const newClaims: IClaim[] = [
        {
            id: claims.id,
            number: claims.number,
            name: claims.name,
            components: claims.components.map(({ number, name, id, uid }) => ({
                uid,
                id,
                number,
                name,
            })),
        },
    ]
    claims.components.forEach((claim) => {
        if (isClaim(claim)) {
            newClaims.push({
                id: claim.id,
                number: claim.number,
                name: claim.name,
                components: claim.components,
            })
        }
    })
    return newClaims
}

const getOptions = (claim: string, claims: IClaim[]): IClaimComponent[] => {
    let options: IClaimComponent[] = []
    claims.forEach(({ id, components }) => {
        if (id === claim) {
            options = components
        }
    })
    return options
}
const ClaimSelect: React.FC<IClaimSelect> = ({
    data,
    onChange,
}): JSX.Element => {
    const { claim, components } = data
    const { claims: stateClaims } = useAppSelector((state) => state)
    const [claims, setClaims] = useState<IClaim[]>(getClaimsData(stateClaims))
    const [options, setOptions] = useState<IClaimComponent[]>(
        claim ? getOptions(claim, claims) : []
    )
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const handleClaimChange = (e: string): void => {
        if (onChange) onChange(e, components)
    }
    const handleOptionChange = (e: string[]): void => {
        if (onChange) onChange(claim, e)
        setSelectedOptions(e)
    }
    useEffect(() => {
        setClaims(getClaimsData(stateClaims))
    }, [stateClaims])
    useEffect(() => {
        if (claim) setOptions(getOptions(claim, claims))
    }, [claims])
    useEffect(() => {
        console.log('OPTIONS', options)
    }, [options])
    return (
        <div style={{ marginTop: '.5rem' }}>
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a claim"
                optionFilterProp="children"
                value={claim}
                onChange={handleClaimChange}
            >
                {claims.map(({ id, number, name }) => (
                    <Option value={id}>
                        <Tooltip
                            placement="left"
                            title={generateTooltipText(id, number, stateClaims)}
                        >{`${name} ${number}`}</Tooltip>
                    </Option>
                ))}
            </Select>
            <br />
            {!!claim && (
                <>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select components"
                        optionFilterProp="children"
                        mode="tags"
                        onChange={handleOptionChange}
                        value={components}
                    >
                        {options.map(({ id, number, name }) => (
                            <Option value={id}>
                                <Tooltip
                                    placement="left"
                                    title={generateTooltipText(
                                        id,
                                        number,
                                        stateClaims
                                    )}
                                >{`${name} ${number}`}</Tooltip>
                            </Option>
                        ))}
                    </Select>
                    <br />
                    {selectedOptions.length < options.length
                        ? `You've chosen ${selectedOptions.length} out of ${
                              options.length
                          } options - that's ${
                              options.length - selectedOptions.length
                          } left.`
                        : `You've chosen all options.`}
                </>
            )}
        </div>
    )
}

export default ClaimSelect
