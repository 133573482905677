import { FC, ReactNode, useState } from 'react'
import { Typography, Row, Col } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { ValidQuestionKeys } from '../../hooks/useGerateQuestions'
import { mapQuestionNames } from '../../const'

const { Title, Text } = Typography

type Props = {
    onItemClick: (name: ValidQuestionKeys) => void
    activeQuestions: ValidQuestionKeys[]
}

type NavigationList = {
    text: string | ((index: number) => ReactNode)
    onClick?: () => void
    children?: ReactNode
}

const TableOfContent: FC<Props> = ({ onItemClick, activeQuestions }) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(false)

    const navigationList: NavigationList[] = [
        {
            text: (index: number) => (
                <Text
                    onClick={() => setIsCollapseOpen((prev) => !prev)}
                    style={{ cursor: 'pointer' }}
                >
                    {index + 1}.{' '}
                    <CollapseIcon rotate={isCollapseOpen ? 0 : 90} />
                    <Text underline>Questions list</Text>
                </Text>
            ),
            children: (
                <Collapse isOpen={isCollapseOpen}>
                    <div style={{ marginTop: '10px' }}>
                        {activeQuestions.length > 1 ? (
                            activeQuestions.map((item, index) => (
                                <Col span={24}>
                                    <Text>{index + 1}.</Text>
                                    <StyledText
                                        underline
                                        onClick={() => onItemClick(item)}
                                    >
                                        {mapQuestionNames[item]}
                                    </StyledText>
                                </Col>
                            ))
                        ) : (
                            <Text>Theres no questions yet</Text>
                        )}
                    </div>
                </Collapse>
            ),
        },
        {
            text: 'Claims',
        },
        {
            text: 'Drawings',
        },
        {
            text: 'Various Sections of the Specification',
        },
        {
            text: 'Finalize Draft Application for Filling',
        },
    ]

    return (
        <StyledContainer>
            <Title level={4}>Table of contents</Title>
            <Row gutter={[0, 8]}>
                {navigationList.map(({ text, children }, index) => (
                    <Col span={24}>
                        {typeof text === 'string' ? (
                            <Text>
                                {index + 1}.
                                <StyledText
                                    underline
                                    style={{ marginLeft: '10px' }}
                                >
                                    {text}
                                </StyledText>
                            </Text>
                        ) : (
                            text(index)
                        )}
                        {children}
                    </Col>
                ))}
            </Row>
        </StyledContainer>
    )
}

export default TableOfContent

const StyledContainer = styled.div`
    min-height: 300px;
`

const Collapse = styled.div<{ isOpen: boolean }>`
    max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    overflow: hidden;
    transition: all 0.3s ease-out;
`
const CollapseIcon = styled(RightOutlined)`
    transition: all 0.3s ease-out;
    margin-right: 5px;
`
const StyledText = styled(Text)`
    cursor: pointer;
    margin-left: 10px;
`
