import { Form, Input, Button, Spin, Divider } from 'antd/lib'
import { useForm } from 'antd/lib/form/Form'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { api } from '../../api/api'
import { useAppDispatch } from '../../store/hooks'
import { signIn } from '../../store/slices/isAuth/isAuth'
import { emailRules, STORAGE_KEYS } from '../../utils/consts'
import { getRegularRules, isLoading } from '../../utils/functions'
import { REQUEST_STATUS } from '../../utils/types'
import { CustomForm, CustomRow } from './SignIn.styles'
import { ISignInForm, ISignInRes } from './SignIn.types'

const SignIn: React.FC = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [form] = useForm()
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(
        REQUEST_STATUS.NONE
    )
    const onSubmit = (formData: unknown): void => {
        setRequestStatus(REQUEST_STATUS.PENDING)
        const data = formData as ISignInForm
        api.post<ISignInRes>('/api/auth/login', { ...data })
            .then((res) => {
                localStorage.setItem(STORAGE_KEYS.ACCESS, res.data.token)
                localStorage.setItem(
                    STORAGE_KEYS.REFRESH,
                    res.data.refreshToken
                )
                localStorage.setItem(STORAGE_KEYS.ID, res.data.id)
                dispatch(signIn())
            })
            .catch(() => {
                form.setFields([
                    {
                        name: 'email',
                        errors: [''],
                    },
                    {
                        name: 'password',
                        errors: ['Email or password is incorrect.'],
                    },
                ])
                setRequestStatus(REQUEST_STATUS.FAILED)
            })
    }
    const onSubmitFailed = (): void => {
        setRequestStatus(REQUEST_STATUS.FAILED)
    }
    return (
        <CustomRow justify="center" align="middle">
            <Helmet title="Sign In" />
            <Spin spinning={isLoading(requestStatus)}>
                <CustomForm
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    layout="vertical"
                    requiredMark="optional"
                    onFinish={onSubmit}
                    onFinishFailed={onSubmitFailed}
                    form={form}
                >
                    {history.location.search.includes('registerRedirect') && (
                        <Divider plain>Your account was created.</Divider>
                    )}
                    <Form.Item
                        label="E-mail"
                        name="email"
                        rules={emailRules}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={getRegularRules('Password')}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 2 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                    <Link to="/sign-up">I don&apos;t have an account</Link>
                </CustomForm>
            </Spin>
        </CustomRow>
    )
}

export default SignIn
