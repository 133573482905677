export const indefiniteArticlesOptions = [
    {
        value: 'a',
        label: 'a',
    },
    {
        value: 'an',
        label: 'an',
    },
    {
        value: ' ',
        label: 'none',
    },
]
