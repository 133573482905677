import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClaimsState, IClaim } from './claims.types'

const dummyData: IClaim = {
    id: '0200',
    number: '0200',
    name: 'Wagon',
    components: [
        {
            uid: '0202',
            id: '0202',
            number: '0202',
            name: 'Carriage body',
        },
    ],
}

const initialState = dummyData as ClaimsState

export const claims = createSlice({
    name: 'claims',
    initialState,
    reducers: {
        setClaims: (_state, action: PayloadAction<ClaimsState>) =>
            action.payload,
    },
})

export const { setClaims } = claims.actions
export default claims.reducer
