import { FormItemProps } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Paragraph } from 'docx'
import { ReactElement, useState } from 'react'
import { CustomFormInstance } from '../types'
import {
    getMachineElements,
    getCompositionElements,
    getManufactureElements,
    getMethodElements,
} from '../utils'
import {
    CompositionForm,
    FormValues,
    MachineForm,
    MachineValues,
    ManufactureForm,
    MethodsForm,
    MethodsValues,
} from '../utils/forms/methods/types'

type Question<T = any> = {
    name: keyof T
    formInputProps?: FormItemProps
    component: ReactElement
    onNext?: () => void
    onPrev?: (fieldValue: string | undefined | any) => void
    isLastQuestion?: boolean
}
export type getQuestions = {
    method: Questions
    manufacture: Questions
    machine: Questions
    composition: Questions
}

// export type Questions<T = any> = Record<keyof T, Question<T>>
export type Questions<T = any> = {
    [key in keyof T]: Question<T>
}

export type ValidQuestionKeys =
    | keyof MethodsForm
    | keyof MachineForm
    | keyof CompositionForm
    | keyof ManufactureForm

const useGerateQuestions = (category: keyof typeof getQuestions) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [form] = useForm()
    const [activeQuestions, setActiveQuestions] = useState<ValidQuestionKeys[]>(
        ['titleComponent']
    )

    const options = {
        form,
        setActiveQuestions,
        activeQuestions,
        currentQuestionIndex,
        setCurrentQuestionIndex,
    }
    const getQuestions: getQuestions = {
        method: getMethodElements(options as any),
        manufacture: getManufactureElements(options as any),
        machine: getMachineElements(options as any),
        composition: getCompositionElements(options as any),
    }
    const questionsList = getQuestions[category]

    const onFormChange = (newValue: Record<string, string | undefined>) => {
        // const [fieldKey, fieldValue] = Object.entries(newValue)[0]
        // questionsList[fieldKey].onChange(fieldValue)
    }
    const setInitialQuestions = (questions: (keyof FormValues)[]) => {
        setActiveQuestions(questions)
    }
    const selectedQuestions = [...activeQuestions].map((fieldName) => {
        return questionsList[fieldName as string]
    })

    return {
        activeQuestions,
        onFormChange,
        setInitialQuestions,
        selectedQuestions,
        formRef: form as CustomFormInstance<FormValues>,
        currentQuestionIndex,
        setCurrentQuestionIndex,
    }
}

export default useGerateQuestions
