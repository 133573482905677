import { FormInstance } from 'antd'
import {
    CompositionForm,
    CompositionNames,
    FormValues,
    MachineForm,
    MachineNames,
    ManufactureForm,
    ManufactureNames,
    MethodsForm,
    MethodsNames,
} from '../utils/forms/methods/types'

export type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P]
}

export type ValueOf<T> = T[keyof T]

type Cons<H, T> = T extends readonly any[]
    ? ((h: H, ...t: T) => void) extends (...r: infer R) => void
        ? R
        : never
    : never

type Prev = [
    never,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    ...0[]
]

type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : // eslint-disable-next-line @typescript-eslint/ban-types
    T extends object
    ? {
          [K in keyof T]-?:
              | [K]
              | (Paths<T[K], Prev[D]> extends infer P
                    ? P extends []
                        ? never
                        : Cons<K, P>
                    : never)
      }[keyof T]
    : []

export type CustomFormInstance<T = FormValues> = Omit<
    FormInstance<T>,
    'getFieldValue'
> & {
    getFieldValue: <U>(val: Paths<T, 3> | keyof T) => U
}

type PossibleCustomFormInstances =
    | CustomFormInstance<MachineForm>
    | CustomFormInstance<MethodsForm>
    | CustomFormInstance<ManufactureForm>
    | CustomFormInstance<CompositionForm>
    | CustomFormInstance

export function isMachineForm(
    arg: PossibleCustomFormInstances,
    c: string
): arg is CustomFormInstance<MachineForm> {
    return c in MachineNames
}
export function isMethodsForm(
    arg: PossibleCustomFormInstances,
    c: string
): arg is CustomFormInstance<MethodsForm> {
    return c in MethodsNames
}

export function isManufactureForm(
    arg: PossibleCustomFormInstances,
    c: string
): arg is CustomFormInstance<ManufactureForm> {
    return c in ManufactureNames
}

export function isCompositionForm(
    arg: PossibleCustomFormInstances,
    c: string
): arg is CustomFormInstance<CompositionForm> {
    return c in CompositionNames
}
