import { Form, Input, Select, Checkbox } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm } from '../types'

type Props = {
    form:
        | CustomFormInstance<ManufactureForm>
        | CustomFormInstance<CompositionForm>
}

type Values = ManufactureForm | CompositionForm

const ComponentWeights: FC<Props> = ({ form }) => {
    const primaryComponents =
        form.getFieldValue<Values['primaryComponents']>('primaryComponents') ??
        []

    const [component, setComponent] = useState<string>(
        primaryComponents[0].component ?? ''
    )

    const handleChange = (value: SelectValue) => {
        setComponent((value ?? '').toString())
    }

    const basePath = ['componentWeights', component]
    return (
        <>
            <Form.Item label="Choose component:">
                <Select
                    options={primaryComponents.map((item) => ({
                        value: item.component ?? '',
                        label: item.component,
                    }))}
                    onChange={handleChange}
                    value={component}
                />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'max-acceptable-weight']}
                label="Maximum acceptable weight [%]"
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'min-acceptable-weight']}
                label="Minimum acceptable weight [%]"
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'max-preferable-weight']}
                label="Maximum preferable weight [%]"
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'min-preferable-weight']}
                label="Minimum preferable weight [%]"
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={['componentWeights', 'weightImportance']}
                valuePropName="checked"
            >
                <Checkbox>
                    Are any of the weight percentages always required for the
                    composition to achieve its objectives?
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default ComponentWeights
