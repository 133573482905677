import { Row } from 'antd'
import Form from 'antd/lib/form/Form'
import styled from 'styled-components'

export const CustomRow = styled(Row)`
    height: 100vh;
`

export const CustomForm = styled(Form)`
    padding: 2rem 4rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: 0.3s;
    position: relative;
    width: 30rem;
`
