import { ValidQuestionKeys } from '../../../hooks/useGerateQuestions'

export enum SharedNames {
    titleComponent = 'titleComponent',
    backgroundInvention = 'backgroundInvention',
    commonName = 'commonName',
    summaryOfSelectedEmbodimentsOfTheInvention = 'summaryOfSelectedEmbodimentsOfTheInvention',
    doneDescribing = 'doneDescribing',
    drawingsDescription = 'drawingsDescription',
}

type PrimaryComponent = {
    id?: string
    indefiniteArticle?: string
    component?: string
}
type CharacteristicCustom = Record<
    string,
    {
        unitSI: string | undefined
        value: string | undefined
        'max-acceptable': string | undefined
        'max-preferable': string | undefined
        'min-acceptable': string | undefined
        'min-preferable': string | undefined
    }
>

type IndefiniteArticle = {
    key: string
    label: string
    value: string
}

export type Characteristics = Partial<{
    density: Range | boolean
    weight: Range | boolean
    length: Range | boolean
    thickness: Range | boolean
    another: boolean
    characteristic: boolean
    defined: {
        description: string
    }
}>
export type AdditionalCharacteristics = Partial<{
    thermalConductivity: Range | boolean
    meltingPoint: Range | boolean
    specificHeat: Range | boolean
    characteristic: boolean
    defined: {
        description: string
    }
}>

type DoneDescribing = {
    indefiniteArticle: {
        key: string
        label: string
        value: string
    }
}

type BasicConnectionInfo = {
    '1stComponent': string
    '2ndComponent': string
    '1stPart': string
    '2ndPart': string
    '3rdPart': string
    additionalConnectionInfo?: string
    notSet: boolean
}
type ConnectionInfo = BasicConnectionInfo & {
    connectionType: string
}

export type SharedValues = {
    titleComponent: {
        patentName: string
        description: string
        title: string
        author: { author: string }[]
        footerNumber: string
    }
    // backgroundInvention: Partial<{
    //     background: string
    //     notSet: boolean
    //     nonprovisionalPatent: string
    //     failToProvide: string
    //     dateNonprovisionalPatent: string
    //     provisionalApplication: string
    //     dateProvisionalApplication: string
    //     custom: boolean
    //     nonprovisionalNumbers: {
    //         number: string
    //         date: string
    //     }[]
    //     provisionalNumbers: {
    //         number: string
    //         date: string
    //     }[]
    // customCharacteristic: string[]
    // }>
    backgroundInvention: {
        custom: boolean
        nonprovisionalNumbers: {
            date: string
            number: string
        }[]
        provisionalNumbers: {
            date: string
            number: string
        }[]
        customCharacteristic: string[]
        notSet: boolean
    }
    summaryOfSelectedEmbodimentsOfTheInvention: string[]
    commonName:
        | {
              notSet: true
          }
        | {
              notSet?: false
              indefiniteArticle: string
              commonName: string
          }
    doneDescribing: DoneDescribing
    drawingsDescription: {
        figure: any
    }
    // characteristicComponents: any
    // additionalCharacteristic: any
    // formatUserDefined: any
}

export enum MachineNames {
    primaryComponents = 'primaryComponents',
    mainSubcomponents = 'mainSubcomponents',
    characteristic = 'characteristic',
    additionalCharacteristic = 'additionalCharacteristic',
    formatUserDefined = 'formatUserDefined',
    primaryComponentSubComponentsList = 'primaryComponentSubComponentsList',
    drawingsDescription = 'drawingsDescription',
    componentsForCharacteristics = 'componentsForCharacteristics',
    subcomponentsForCharacteristics = 'subcomponentsForCharacteristics',
    subCharacteristic = 'subCharacteristic',
    subAdditionalCharacteristic = 'subAdditionalCharacteristic',
    subFormatUserDefined = 'subFormatUserDefined',
    partsOfIndependentClaim = 'partsOfIndependentClaim',
    abstractOfTheDisclosure = 'abstractOfTheDisclosure',
    subSummaryOfSelectedEmbodimentsOfTheInvention = 'subSummaryOfSelectedEmbodimentsOfTheInvention',
    doneDescribing = 'doneDescribing',
    extraOptions = 'extraOptions',
    lastPane = 'lastPane',
    detailedDescription = 'detailedDescription',
    functionalConnections = 'functionalConnections',
    importantComponentsConnections = 'importantComponentsConnections',
    dependantComponentsConnections = 'dependantComponentsConnections',
    customBackgroundOfInvention = 'customBackgroundOfInvention',
}

export type Range = {
    from: string
    to: string
}

export type UserDefinedFormat = {
    maximum: string
    minimum: string
    name: string
    units: string
}

export type MachineValues = {
    primaryComponents: PrimaryComponent[]
    mainSubcomponents: string[]
    primaryComponentSubComponentsList: Record<
        string,
        {
            id?: string
            component: string
            indefiniteArticle: string
        }[]
    >
    drawingsDescription: any
    componentsForCharacteristics: string[]
    subcomponentsForCharacteristics: Record<string, string[]>
    characteristic: Record<string, Characteristics>
    additionalCharacteristic: Record<string, AdditionalCharacteristics>
    formatUserDefined: Record<
        string,
        Partial<{
            equal: UserDefinedFormat | boolean
            from: UserDefinedFormat | boolean
            defined: {
                description: string
            }
        }>
    >
    subCharacteristic: any
    subAdditionalCharacteristic: any
    subFormatUserDefined: any
    detailedDescription: {
        'describe-disclosure': string
    }
    partsOfIndependentClaim: any
    partsOfDependentClaim?: any
    abstractOfTheDisclosure: {
        checked: boolean
        defined: string
    }
    subSummaryOfSelectedEmbodimentsOfTheInvention: any
    importantComponentsConnections: ConnectionInfo[]
    functionalConnections: BasicConnectionInfo[]
    dependantComponentsConnections: ConnectionInfo[]
    customBackgroundOfInvention: any
    extraOptions: {
        'patent-proffesional': IndefiniteArticle
    }
    lastPane: null
    // figure: any
}

export enum MethodsNames {
    methodOutline = 'methodOutline',
    methodOutlineDescription = 'methodOutlineDescription',
    methodOutlineInputs = 'methodOutlineInputs',
    characteristicMethod = 'characteristicMethod',
    characteristicMethodOutline = 'characteristicMethodOutline',
    measurableProperties = 'measurableProperties',
    subSteps = 'subSteps',
    embodimentNecessaryStep = 'embodimentNecessaryStep',
    stepPracticalLimit = 'stepPracticalLimit',
    stepPracticalPercentageLimit = 'stepPracticalPercentageLimit',
    componentStepPracticalLimit = 'componentStepPracticalLimit',
    componentStepPracticalPercentageLimit = 'componentStepPracticalPercentageLimit',
    inputComponentStepPracticalPercentageLimit = 'inputComponentStepPracticalPercentageLimit',
    characteristicCustom = 'characteristicCustom',
    characteristicExplanation = 'characteristicExplanation',
    importantCharacteristic = 'importantCharacteristic',
    newProperty = 'newProperty',
    characteristicCompositon = 'characteristicCompositon',
}

export type MethodsValues = {
    methodOutline: {
        methodStep: string
    }[]
    subSteps: any
    methodOutlineDescription: Record<
        string,
        {
            part0: string
            part1: string
            part2: string
            choosenDescriptionIndex: number
            finalSentence?: string
        }
    >
    methodOutlineInputs: any
    stepPracticalLimit: any
    componentStepPracticalLimit: any
    stepPracticalPercentageLimit: any
    characteristicCustom: CharacteristicCustom
    newProperty: any
    characteristicMethodOutline: any
    measurableProperties: any
    embodimentNecessaryStep: any
    componentStepPracticalPercentageLimit: any
    characteristicMethod: any
    characteristicExplanation: any
    importantCharacteristic: any
    characteristicCompositon?: any
}

export enum ManufactureNames {
    primaryComponents = 'primaryComponents',
    mainSubcomponents = 'mainSubcomponents',
    characteristic = 'characteristic',
    additionalCharacteristic = 'additionalCharacteristic',
    describeMethod = 'describeMethod',
    reviewPatent = 'reviewPatent',
    patentProfessionalReview = 'patentProfessionalReview',
    formatUserDefined = 'formatUserDefined',
    primaryComponentSubComponentsList = 'primaryComponentSubComponentsList',
    componentsForCharacteristics = 'componentsForCharacteristics',
    importantComponents = 'importantComponents',
    importantComponentsConnections = 'importantComponentsConnections',
    functionalConnections = 'functionalConnections',
    componentWeights = 'componentWeights',
    importantWeights = 'importantWeights',
    componentsQuantity = 'componentsQuantity',
    measurableProperties = 'measurableProperties',
    characteristicExplanation = 'characteristicExplanation',
    characteristicCustom = 'characteristicCustom',
    characteristicCompositon = 'characteristicCompositon',
    importantCharacteristic = 'importantCharacteristic',
    newProperty = 'newProperty',
}

export type ManufactureValues = {
    primaryComponents: PrimaryComponent[]
    mainSubcomponents: any
    primaryComponentSubComponentsList: any
    importantComponents: any
    importantComponentsConnections: any
    functionalConnections: any
    componentWeights: any
    importantWeights: any
    componentsQuantity: any
    characteristicCompositon: any
    characteristicCustom: CharacteristicCustom
    newProperty?: any
    characteristicExplanation: any
    importantCharacteristic: any
    componentsForCharacteristics: any
    characteristic: any
    describeMethod: any
    reviewPatent: any
    patentProfessionalReview: any
    formatUserDefined: any
    measurableProperties: {
        checkbox: boolean
    }
    additionalCharacteristic: any
    doneDescribing: DoneDescribing
}

export enum CompositionNames {
    primaryComponents = 'primaryComponents',
    partsOfIndependentClaim = 'partsOfIndependentClaim',
    functionalConnections = 'functionalConnections',
    importantComponentsConnections = 'importantComponentsConnections',
    dependantComponentsConnections = 'dependantComponentsConnections',
    componentWeights = 'componentWeights',
    characteristicCompositon = 'characteristicCompositon',
    characteristicCustom = 'characteristicCustom',
    characteristicExplanation = 'characteristicExplanation',
    importantCharacteristic = 'importantCharacteristic',
    describeMethod = 'describeMethod',
    reviewPatent = 'reviewPatent',
    patentProfessionalReview = 'patentProfessionalReview',
    formatUserDefined = 'formatUserDefined',
    importantWeights = 'importantWeights',
    measurableProperties = 'measurableProperties',
    newProperty = 'newProperty',
    abstractOfTheDisclosure = 'abstractOfTheDisclosure',
    subSummaryOfSelectedEmbodimentsOfTheInvention = 'subSummaryOfSelectedEmbodimentsOfTheInvention',
    extraOptions = 'extraOptions',
    lastPane = 'lastPane',
    detailedDescription = 'detailedDescription',
    primaryComponentSubComponentsList = 'primaryComponentSubComponentsList',
    customBackgroundOfInvention = 'customBackgroundOfInvention',
}

export type CompositionValues = {
    measurableProperties?: any
    newProperty?: any
    primaryComponents: PrimaryComponent[]
    componentWeights: any
    characteristicCustom: CharacteristicCustom
    characteristicCompositon: any
    partsOfIndependentClaim: any
    partsOfDependentClaim?: any
    importantComponentsConnections: ConnectionInfo[]
    functionalConnections: BasicConnectionInfo
    dependantComponentsConnections: ConnectionInfo[]
    importantWeights: any
    characteristicExplanation: any
    importantCharacteristic: any
    abstractOfTheDisclosure: any
    subSummaryOfSelectedEmbodimentsOfTheInvention: any
    lastPane: any
    detailedDescription: any
    doneDescribing: DoneDescribing
    extraOptions: any
    primaryComponentSubComponentsList?: any
    customBackgroundOfInvention: any
}

export type MethodsForm = SharedValues & MethodsValues
export type MachineForm = SharedValues & MachineValues
export type ManufactureForm = SharedValues & ManufactureValues
export type CompositionForm = SharedValues & CompositionValues

export type FormValues =
    | (SharedValues & MachineValues)
    | (SharedValues & MethodsValues)
    | (SharedValues & ManufactureValues)
    | (SharedValues & CompositionValues)

export type SharedQuestionProps = {
    setContentBasedItemsToReview?: React.Dispatch<
        React.SetStateAction<ValidQuestionKeys[]>
    >
}
