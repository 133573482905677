import { Form, Select, Input } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, useState } from 'react'
import { Common, CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm, MethodsForm } from '../types'

type Values = Common<Common<CompositionForm, MethodsForm>, ManufactureForm>

type Props = {
    form: CustomFormInstance<Values>
    name: string
}

const CharacteristicExplanation: FC<Props> = ({ form, name }) => {
    let properties: string[] = []
    const characteristicCompositon =
        form.getFieldValue<Values['characteristicCompositon']>(
            'characteristicCompositon'
        ) ?? undefined

    if (characteristicCompositon != null) {
        const characteristicKeys = Object.keys(characteristicCompositon)
        properties = properties.concat(characteristicKeys)
    }

    const characteristicCustom =
        form.getFieldValue<Values['characteristicCustom']>(
            'characteristicCustom'
        ) ?? undefined

    if (characteristicCustom != null) {
        const characteristicCustomKeys = Object.keys(characteristicCustom)
        properties = properties.concat(characteristicCustomKeys)
    }
    const [property, setProperty] = useState<string>(properties[0])

    const handleChange = (value: SelectValue) => {
        setProperty((value ?? '').toString())
    }

    return (
        <Form.Item>
            <Form.Item label="Property you want to describe:">
                <Select
                    options={properties.map((item: any) => ({
                        value: item,
                        label: item,
                    }))}
                    value={property}
                    onChange={handleChange}
                />
            </Form.Item>
            <Form.Item
                name={[name, property]}
                label="Description"
                rules={[
                    {
                        validator: async () => {
                            let characteristicsNames: Array<string> = []
                            const characteristics = form.getFieldsValue([
                                'characteristicCompositon',
                            ])
                            characteristicsNames = characteristicsNames.concat(
                                Object.keys(
                                    characteristics.characteristicCompositon
                                )
                            )
                            const customCharacteristics = form.getFieldsValue([
                                'characteristicCustom',
                            ])
                            characteristicsNames = characteristicsNames.concat(
                                Object.keys(
                                    customCharacteristics.characteristicCustom
                                )
                            )
                            const characteristicExplanation =
                                form.getFieldsValue([
                                    'characteristicExplanation',
                                ])

                            const characteristicExplanationNames = Object.keys(
                                characteristicExplanation.characteristicExplanation
                            )
                            if (
                                characteristicExplanationNames.length !==
                                characteristicsNames.length
                            ) {
                                throw new Error(
                                    'Not all characteristic were explained!'
                                )
                            }
                        },
                    },
                ]}
                hasFeedback
            >
                <Input value="" />
            </Form.Item>
        </Form.Item>
    )
}

export default CharacteristicExplanation
