import { Form, Checkbox, Tooltip } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import { MachineForm, MachineNames, SharedQuestionProps } from '../types'
import { useFormItemWatcher } from '../../../../hooks'

type Props = {
    form: CustomFormInstance<MachineForm>
    characteristicComponentIndex: number
} & SharedQuestionProps

const Characteristic: FC<Props> = ({
    form,
    characteristicComponentIndex,
    setContentBasedItemsToReview,
}) => {
    const componentsForCharacteristics =
        form.getFieldValue<MachineForm['componentsForCharacteristics']>(
            MachineNames.componentsForCharacteristics
        ) ?? []

    const primaryComponents =
        form.getFieldValue<MachineForm['primaryComponents']>(
            MachineNames.primaryComponents
        ) ?? []

    const primaryComponentId =
        componentsForCharacteristics[characteristicComponentIndex]

    const primaryComponent = primaryComponents.find(
        (comp) => comp?.id === primaryComponentId
    )
    const componentId = primaryComponent?.id ?? ''
    const componentName = primaryComponent?.component ?? ''
    const baseFormPath = [MachineNames.characteristic, componentId] as [
        MachineNames.characteristic,
        string
    ]

    useFormItemWatcher<MachineForm, boolean>({
        form,
        path: [...baseFormPath, 'defined'],
        callback: (isDefined) => {
            if (setContentBasedItemsToReview) {
                if (isDefined) {
                    setContentBasedItemsToReview((prev) => [
                        ...prev,
                        MachineNames.characteristic,
                    ])
                } else {
                    setContentBasedItemsToReview((prev) => [
                        ...prev.filter(
                            (item) => item !== MachineNames.characteristic
                        ),
                    ])
                }
            }
        },
    })

    return (
        <>
            <label>
                Select characteristics that apply to the {componentName} fill in
                any blanks for the selected characteristics:
            </label>
            <Form.Item
                name={[...baseFormPath, 'density']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having a density of
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'density', 'from']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'density', 'to']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>kg/m3</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'weight']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>the {componentName} weight from</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'weight', 'from']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'weight', 'to']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>kg</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'length']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having a length from
                    </StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'length', 'from']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'length', 'to']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>m</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'thickness']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        the {componentName} having a thickness from
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'thickness', 'from']}
                    >
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'thickness', 'to']}>
                        <StyledInput type="text" />
                    </StyledFormItem>
                    <StyledSpan>m</StyledSpan>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'defined']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>
                        a{' '}
                        <Tooltip
                            title="The user defined characteristic is used to add characteristics that are not otherwise already provided in the form.  
                            For ease of reference, the claims show how language typed in the user defined characteristic will appear in the claims."
                        >
                            <span style={{ textDecoration: 'underline' }}>
                                user defined
                            </span>
                        </Tooltip>{' '}
                        characteristic
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'defined', 'description']}
                    >
                        <textarea
                            style={{
                                border: 'none',
                                borderBottom: '2px solid black',
                                width: '150px',
                                transform: 'translateY(-8px)',
                                backgroundColor: 'transparent',
                                outline: 'none',
                                padding: '0px 5px',
                            }}
                            rows={1}
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        (a patent professional will be notified to review this)
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default Characteristic
