import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, MachineNames } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
    name: MachineNames.partsOfIndependentClaim
}

const PartsOfIndependentClaim: FC<Props> = ({ form, name }) => {
    let steps: {
        id?: string
        component?: string
        indefiniteArticle?: string
        mainComponent?: string
    }[] = []
    const primaryComponents =
        form.getFieldValue<MachineForm['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    steps = steps.concat(primaryComponents)

    const subComponents = form.getFieldValue<
        MachineForm['primaryComponentSubComponentsList']
    >('primaryComponentSubComponentsList')

    if (subComponents) {
        primaryComponents.forEach((primaryComponent) => {
            let subComponent = subComponents[primaryComponent.id ?? '']
            if (subComponent) {
                subComponent = subComponent.map((subComp) => ({
                    ...subComp,
                    mainComponent: primaryComponent.component,
                }))
                steps = steps.concat(subComponent)
            }
        })
    }
    const stepsArray = steps.map((item: any) => {
        if (Object.prototype.hasOwnProperty.call(item, 'mainComponent')) {
            return `${item.component}+=>${item.mainComponent}`
        }
        return item?.component
    })
    const handleChange = (event: any) => {
        const test = stepsArray.filter((item) => !event.includes(item))
        form.setFieldsValue({ partsOfDependentClaim: test })
    }
    const normalizedSteps = steps.filter((step) => !!step)
    return (
        <>
            <Form.Item
                name={name}
                rules={[
                    {
                        validator: async (_, components) => {
                            if (
                                components === undefined ||
                                components?.length === 0
                            )
                                throw new Error('At least one component!')
                        },
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    onChange={handleChange}
                    options={normalizedSteps.map(
                        ({ component, mainComponent }) => {
                            return {
                                value: mainComponent
                                    ? `${component}+=>${mainComponent}`
                                    : component ?? '',
                                label: mainComponent
                                    ? `${component} from the ${mainComponent} component`
                                    : component ?? '',
                                key: mainComponent
                                    ? `${component}+=>${mainComponent}`
                                    : component ?? '',
                            }
                        }
                    )}
                />
            </Form.Item>
        </>
    )
}

export default PartsOfIndependentClaim
