import { Form, Checkbox, Col, Input, Row, Select } from 'antd'
import { FC } from 'react'

const Characteristic: FC = () => {
    return (
        <>
            <Form.Item
                name={['commonName', 'notSet']}
                valuePropName="checked"
                style={{ marginBottom: '20px' }}
            >
                <Checkbox>No, there is no common name for the machine</Checkbox>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.commonName?.notSet !==
                    curValues.commonName?.notSet
                }
            >
                {({ getFieldValue }) => {
                    const bool = getFieldValue(['commonName', 'notSet'])
                    return bool ? null : (
                        <Row align="middle" gutter={[12, 0]}>
                            <Col span={10}>
                                <Form.Item
                                    label="Indefinite Article"
                                    name={['commonName', 'indefiniteArticle']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: !bool,
                                            message:
                                                'Indefinite Article is required',
                                        },
                                    ]}
                                    initialValue=" "
                                >
                                    <Select
                                        options={[
                                            {
                                                value: 'a',
                                                label: 'a',
                                            },
                                            {
                                                value: 'an',
                                                label: 'an',
                                            },
                                            {
                                                value: ' ',
                                                label: 'none',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    label="Common name"
                                    name={['commonName', 'commonName']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: !bool,
                                            message: 'Common name is required',
                                        },
                                    ]}
                                >
                                    <Input placeholder="my common name" />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }}
            </Form.Item>
        </>
    )
}

export default Characteristic
