import styled from 'styled-components'

export const StyledCone = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
        content: '';
        width: 100%;
        height: calc(100% - 25px);
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
    &::after {
        content: '';
        width: calc(100% - 8px);
        height: calc(100% - 31px);
        background: #f2f2f2;
        position: absolute;
        top: 4px;
        left: 4px;
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
    div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        border: 3px solid black;
        border-radius: 50%;
        z-index: 2;
        background: #f2f2f2;
    }
`
