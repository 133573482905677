import { PositionableContainer } from 're-position'
import { useCallback, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { IElementHandler } from './ElementHandler.types'

const ElementHandler: React.FC<IElementHandler> = ({
    id,
    children,
    data,
    setElementData,
    removeElement,
    resizable = true,
    resizeHeight = false,
}): JSX.Element => {
    const hideControls = useCallback(() => {
        const node = document.querySelector(`.node-${id}`)!
        const resize = node.nextElementSibling! as HTMLElement
        resize.style.opacity = '0%'
    }, [id])
    const handleOutsideClick = (e: any): void => {
        if (
            e.target.id === 'diagramsLibContainer' ||
            e.path[2].id === 'diagramsLibContainer' ||
            e.path[3].id === 'diagramsLibContainer'
        ) {
            const node = document.querySelector(`.node-${id}`)!
            const resize = node.nextElementSibling! as HTMLElement
            hideControls()
            resize.addEventListener('pointerleave', hideControls)
        }
    }
    const handleSetElement = (index: string, e: any) => {
        setElementData(index, {
            ...e,
        })
    }
    useEffect(() => {
        const node = document.querySelector(`.node-${id}`)!
        const resize = node.nextElementSibling! as HTMLElement
        const showControls = (): void => {
            resize.style.opacity = '100%'
        }
        resize.addEventListener('pointerenter', showControls)
        resize.addEventListener('pointerleave', hideControls)
        if (resizable) {
            for (let i = 0; i < 8; i++) {
                const item = resize.children[i] as HTMLElement
                item.style.opacity = '1'
                item.style.background = 'red'
                if (i === 0) {
                    item.style.left = 'calc(.7rem - 1px)'
                    item.style.top = '0'
                    item.style.width = 'calc(100% - 1.1rem + 2px)'
                    item.style.height = '.3rem'
                    item.style.border = 'none'
                    item.style.borderRadius = '0%'
                    if (!resizeHeight) {
                        item.style.pointerEvents = 'none'
                        item.style.background = '#f2f2f2'
                        item.style.left = '0'
                        item.style.width = '100%'
                    }
                }
                if (i === 1) {
                    item.style.left = 'calc(100% - .4rem)'
                    item.style.top = '-.3rem'
                    item.style.borderRadius = '50%'
                    item.style.width = '1rem'
                    item.style.height = '1rem'
                    item.style.border = 'none'
                    if (!resizeHeight) {
                        item.style.display = 'none'
                    }
                }
                if (i === 2) {
                    item.style.right = '2px'
                    item.style.top = 'calc(.7rem - 1px)'
                    item.style.width = '.3rem'
                    item.style.height = 'calc(100% - 1.1rem + 2px)'
                    item.style.border = 'none'
                    item.style.borderRadius = '0%'
                    if (!resizeHeight) {
                        item.style.top = '0'
                        item.style.height = 'calc(100% + 4px)'
                    }
                }
                if (i === 3) {
                    item.style.left = 'calc(100% - .4rem)'
                    item.style.top = 'calc(100% - .4rem)'
                    item.style.borderRadius = '50%'
                    item.style.width = '1rem'
                    item.style.height = '1rem'
                    item.style.border = 'none'
                    if (!resizeHeight) {
                        item.style.display = 'none'
                    }
                }
                if (i === 4) {
                    item.style.left = 'calc(.7rem - 1px)'
                    item.style.bottom = '0'
                    item.style.width = 'calc(100% - 1.1rem + 2px)'
                    item.style.height = '.3rem'
                    item.style.border = 'none'
                    item.style.borderRadius = '0%'
                    if (!resizeHeight) {
                        item.style.pointerEvents = 'none'
                        item.style.background = '#f2f2f2'
                        item.style.left = '0'
                        item.style.width = '100%'
                    }
                }
                if (i === 5) {
                    item.style.left = '-.3rem'
                    item.style.top = 'calc(100% - .4rem)'
                    item.style.borderRadius = '50%'
                    item.style.width = '1rem'
                    item.style.height = '1rem'
                    item.style.border = 'none'
                    if (!resizeHeight) {
                        item.style.display = 'none'
                    }
                }
                if (i === 6) {
                    item.style.left = '0'
                    item.style.top = 'calc(.7rem - 1px)'
                    item.style.width = '.3rem'
                    item.style.height = 'calc(100% - 1.1rem + 2px)'
                    item.style.border = 'none'
                    item.style.borderRadius = '0%'
                    if (!resizeHeight) {
                        item.style.top = '0'
                        item.style.height = 'calc(100% + 4px)'
                    }
                }
                if (i === 7) {
                    item.style.left = '-.3rem'
                    item.style.top = '-.3rem'
                    item.style.borderRadius = '50%'
                    item.style.width = '1rem'
                    item.style.height = '1rem'
                    item.style.border = 'none'
                    if (!resizeHeight) {
                        item.style.display = 'none'
                    }
                }
            }
        }
        return () => {
            resize.removeEventListener('pointerenter', showControls)
            resize.removeEventListener('pointerleave', hideControls)
        }
    }, [id, hideControls])
    useEffect(() => {
        hideControls()
        const node = document.querySelector(`.node-${id}`)! as HTMLElement
        const resize = node.nextElementSibling! as HTMLElement
        node.style.transition = 'filter .1s'
        resize.style.transition = 'filter .1s'
        const pointermove = (e: PointerEvent) => {
            const deleteElement = document.getElementById(
                '#deleteElement'
            )! as HTMLElement
            const {
                offsetLeft: left,
                offsetTop: top,
                offsetHeight: height,
                offsetWidth: width,
            } = deleteElement
            const minX = left
            const maxX = left + width
            const minY = top
            const maxY = top + height
            let { x, y } = e
            x -= 50
            y -= 138
            if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
                resize.style.filter = 'grayscale(100%)'
            } else {
                resize.style.filter = 'grayscale(0%)'
            }
        }
        resize.addEventListener('pointerdown', (e) => {
            resize.addEventListener('pointermove', pointermove)
        })
        resize.addEventListener('pointerup', (e) => {
            resize.removeEventListener('pointermove', pointermove)
            const deleteElement = document.getElementById(
                '#deleteElement'
            )! as HTMLElement
            const {
                offsetLeft: left,
                offsetTop: top,
                offsetHeight: height,
                offsetWidth: width,
            } = deleteElement
            const minX = left
            const maxX = left + width
            const minY = top
            const maxY = top + height
            let { x, y } = e
            x -= 50
            y -= 138
            if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
                removeElement(id)
                resize.style.filter = 'grayscale(100%)'
            }
        })
    }, [])
    return (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <PositionableContainer
                className={`nodeHandler node-${id}`}
                rotatable
                movable
                resizable={resizable}
                position={data}
                onUpdate={(e) => handleSetElement(id, e)}
            >
                {children}
            </PositionableContainer>
        </OutsideClickHandler>
    )
}

export default ElementHandler
