import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { CompositionForm, CompositionNames } from '../types'

type Props = {
    form: CustomFormInstance<CompositionForm>
    name: CompositionNames.partsOfIndependentClaim
}

const PartsOfIndependentClaim: FC<Props> = ({ form, name }) => {
    let steps: {
        id?: string
        component?: string
        indefiniteArticle?: string
        mainComponent?: string
    }[] = []
    const primaryComponents =
        form.getFieldValue<CompositionForm['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    steps = steps.concat(primaryComponents)
    const stepsArray = steps.map((item: any) => {
        return item?.component
    })
    const handleChange = (event: any) => {
        const partsOfDependentClaim = stepsArray.filter(
            (item) => !event.includes(item)
        )
        form.setFieldsValue({ partsOfDependentClaim })
    }
    const normalizedSteps = steps.filter((step) => !!step)
    return (
        <>
            <Form.Item
                name={name}
                rules={[
                    {
                        validator: async (_, components) => {
                            if (
                                components === undefined ||
                                components?.length === 0
                            )
                                throw new Error('At least one component!')
                        },
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    onChange={handleChange}
                    options={normalizedSteps.map(({ component }) => {
                        return {
                            value: component ?? '',
                            label: component ?? '',
                            key: component ?? '',
                        }
                    })}
                />
            </Form.Item>
        </>
    )
}

export default PartsOfIndependentClaim
