import { MinusCircleOutlined } from '@ant-design/icons'
import {
    FormInstance,
    Form,
    Checkbox,
    Button,
    Col,
    Input,
    Row,
    Select,
} from 'antd'
import { FC } from 'react'

type Props = {
    name: string
}

const Characteristic: FC<Props> = ({ name }) => {
    return (
        <Form.List
            name={name}
            rules={[
                {
                    validator: async (_, components) => {
                        if (
                            components === undefined ||
                            components?.length === 0
                        )
                            throw new Error('At least one component!')
                    },
                },
            ]}
        >
            {(fields, { add, remove }, errors) => (
                <>
                    {fields.map((field) => (
                        <Row key={field.key} align="middle" gutter={[12, 0]}>
                            <Col span={10}>
                                <Form.Item noStyle shouldUpdate>
                                    {() => (
                                        <Form.Item
                                            {...field}
                                            label="Indefinite Article"
                                            name={[
                                                field.name,
                                                'indefiniteArticle',
                                            ]}
                                            fieldKey={[
                                                field.fieldKey,
                                                'indefiniteArticle',
                                            ]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Indefinite article is required',
                                                },
                                            ]}
                                            initialValue=" "
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'a',
                                                        label: 'a',
                                                    },
                                                    {
                                                        value: 'an',
                                                        label: 'an',
                                                    },
                                                    {
                                                        value: ' ',
                                                        label: 'none',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    {...field}
                                    label="Component"
                                    name={[field.name, 'component']}
                                    fieldKey={[field.fieldKey, 'component']}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Component name is required',
                                        },
                                    ]}
                                >
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    textAlign: 'center',
                                    paddingTop: '0.6rem',
                                }}
                                span={2}
                            >
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                        style={{
                                            color: '#f00',
                                            fontSize: '1.2rem',
                                        }}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    ))}
                    <Form.ErrorList errors={errors.errors} />

                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block>
                            Add primary component
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

export default Characteristic
