import { Input, Button, Select, Form, Checkbox, Row, Col, Tooltip } from 'antd'
import { Questions } from '../../../hooks/useGerateQuestions'
import MainSubcomponents from './components/MainSubcomponents'
import ComponentSubComponents from './components/ComponentSubComponents'
import AdditionalCharacteristic from './components/AdditionalCharacteristic'
import ImportantComponents from './components/ImportantComponents'
import ComponentsWeights from './components/ComponentsWeights'
import ImportantWeights from './components/ImportantWeights'
import ComponentsForCharacteristics from './components/ComponentsForCharacteristics'
import FormatUserDefined from './components/FormatUserDefined'
import CharacteristicCustom from './components/CharacteristicCompositionCustom'
import CharacteristicsComposition from './components/CharacteristicComposition'
import CharacteristicExplanation from './components/CharacteristicExplanation'
import ImportantCharacteristic from './components/ImportantCharacteristic'
import { getCurrentListItemIndex } from '../../getCurrentListItemIndex'
import ComponentsQuantity from './components/ComponentsQuantity'
import CharacteristicManufacture from './components/CharacteristicManufacture'
import Title from './components/Title'
import { Common, CustomFormInstance } from '../../../types'
import {
    ManufactureForm,
    ManufactureNames,
    ManufactureValues,
    SharedNames,
} from './types'
import DrawingsDescription from './components/DrawingsDescription'

type GetMethodsElementsArgs = {
    form: CustomFormInstance<ManufactureForm>
    setActiveQuestions: React.Dispatch<React.SetStateAction<string[]>>
    activeQuestions: string[]
    currentQuestionIndex: number
}
const prepareCharacteristicQuestionsForComp = (
    questionsArray: string[],
    startIndex: number
) => {
    const questionsCopy = [...questionsArray]
    questionsCopy[startIndex + 1] = ManufactureNames.characteristic
    questionsCopy[startIndex + 2] = ManufactureNames.additionalCharacteristic
    questionsCopy[startIndex + 3] = ManufactureNames.formatUserDefined
    return questionsCopy
}

export const getManufactureElements = ({
    form,
    setActiveQuestions,
    activeQuestions,
    currentQuestionIndex,
}: GetMethodsElementsArgs): Questions<ManufactureForm> => ({
    titleComponent: {
        component: <Title initialValue="ARTICLE OF MANUFACTURE FOR" />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.backgroundInvention
                return newValues
            })
        },
        name: 'titleComponent',
        formInputProps: {
            label: 'Please select the title of the invention and list all the inventors',
        },
    },
    backgroundInvention: {
        component: (
            <>
                <Form.Item
                    name={['backgroundInvention', 'cross-reference']}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        type="text"
                        placeholder="Cross-Reference and Related Applications"
                    />
                </Form.Item>
                <Form.Item label="Technical">
                    <Form.List name={['backgroundInvention', 'technical']}>
                        {(fields, { add }) => (
                            <>
                                {fields.map((field) => (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'technical']}
                                        fieldKey={[field.fieldKey, 'technical']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'This field is required!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                    >
                                        Add technical field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="Background">
                    <Form.List name={['backgroundInvention', 'background']}>
                        {(fields, { add }) => (
                            <>
                                {fields.map((field) => (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'background']}
                                        fieldKey={[
                                            field.fieldKey,
                                            'background',
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'This field is required!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                    >
                                        Add background
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                return newValues
            })
        },
        name: SharedNames.backgroundInvention,
        formInputProps: {
            label: 'Please write backround of the invention ',
        },
    },
    summaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <Form.List
                    name={[
                        'summaryOfSelectedEmbodimentsOfTheInvention',
                        'summary',
                    ]}
                >
                    {(fields, { add }) => (
                        <>
                            {fields.map((field) => (
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'summary']}
                                    fieldKey={[field.fieldKey, 'summary']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add summary
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.commonName
                return newValues
            })
        },
        name: SharedNames.summaryOfSelectedEmbodimentsOfTheInvention,
        formInputProps: {
            label: 'Please write summary of selected embodiments of the invention',
        },
    },
    primaryComponents: {
        component: (
            <Form.List
                name="primaryComponents"
                rules={[
                    {
                        validator: async (_, components) => {
                            if (
                                components === undefined ||
                                components?.length === 0
                            )
                                throw new Error('At least one component!')
                        },
                    },
                ]}
            >
                {(fields, { add }, errors) => (
                    <>
                        {fields.map((field) => (
                            <Row gutter={[12, 0]}>
                                <Col span={10}>
                                    <Form.Item noStyle shouldUpdate>
                                        {() => (
                                            <Form.Item
                                                {...field}
                                                label="Indefinite Article"
                                                name={[
                                                    field.name,
                                                    'indefiniteArticle',
                                                ]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'indefiniteArticle',
                                                ]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Indefinite article is required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={[
                                                        {
                                                            value: 'a',
                                                            label: 'a',
                                                        },
                                                        {
                                                            value: 'an',
                                                            label: 'an',
                                                        },
                                                        {
                                                            value: '',
                                                            label: 'none',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        {...field}
                                        label="Component"
                                        name={[field.name, 'component']}
                                        fieldKey={[field.fieldKey, 'component']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Component name is required',
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                        <Form.ErrorList errors={errors.errors} />

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add primary component
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.mainSubcomponents
                return newValues
            })
        },
        name: ManufactureNames.primaryComponents,
        formInputProps: {
            label: (
                <label>
                    Please list the{' '}
                    <Tooltip
                        title="Examples of primary components include components that you believe are necessary
                     for the invention to work, necessary to describe how the invention is novel, or necessary to explain how any other primary components are connected."
                    >
                        <span style={{ textDecoration: 'underline' }}>
                            primary components
                        </span>
                    </Tooltip>{' '}
                    of the machine and select a corresponding indefinite article
                </label>
            ),
        },
    },
    commonName: {
        component: (
            <>
                <Form.Item
                    name={['commonName', 'notSet']}
                    valuePropName="checked"
                    style={{ marginBottom: '20px' }}
                >
                    <Checkbox>
                        No, there is no common name for the machine
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                        prevValues.commonName?.notSet !==
                        curValues.commonName?.notSet
                    }
                >
                    {({ getFieldValue }) => {
                        const bool = getFieldValue(['commonName', 'notSet'])
                        return bool ? null : (
                            <Row gutter={[12, 0]}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Indefinite Article"
                                        name={[
                                            'commonName',
                                            'indefiniteArticle',
                                        ]}
                                        hasFeedback
                                        rules={[
                                            {
                                                required:
                                                    !form.getFieldValue([
                                                        'commonName',
                                                        'notSet',
                                                    ]) ?? true,
                                                message:
                                                    'Indefinite Article is required',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={[
                                                {
                                                    value: 'a',
                                                    label: 'a',
                                                },
                                                {
                                                    value: 'an',
                                                    label: 'an',
                                                },
                                                {
                                                    value: '',
                                                    label: 'none',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        label="Common name"
                                        name={['commonName', 'commonName']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required:
                                                    !form.getFieldValue([
                                                        'commonName',
                                                        'notSet',
                                                    ]) ?? true,
                                                message:
                                                    'Common name is required',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="my common name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )
                    }}
                </Form.Item>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.primaryComponents
                return newValues
            })
        },
        name: SharedNames.commonName,

        formInputProps: {
            label: 'If the manufacture has a common name, what is it?',
        },
    },
    mainSubcomponents: {
        component: <MainSubcomponents form={form} />,
        name: ManufactureNames.mainSubcomponents,
        onNext: () => {
            const mainSubcomponents =
                form.getFieldValue<any>('mainSubcomponents')
            const nextComponent =
                mainSubcomponents && mainSubcomponents.length
                    ? ManufactureNames.primaryComponentSubComponentsList
                    : ManufactureNames.importantComponents
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },

        formInputProps: {
            label: 'Please select the primary components that have subcomponents you would like to describe:',
        },
    },
    primaryComponentSubComponentsList: {
        component: (
            <ComponentSubComponents
                form={form}
                subComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    ManufactureNames.primaryComponentSubComponentsList
                )}
            />
        ),
        name: ManufactureNames.primaryComponentSubComponentsList,

        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                ManufactureNames.primaryComponentSubComponentsList
            )
            const mainSubcomponents =
                form.getFieldValue<ManufactureValues['mainSubcomponents']>(
                    'mainSubcomponents'
                ) ?? []
            const nextComponent =
                currentListItemIndex < mainSubcomponents.length
                    ? ManufactureNames.primaryComponentSubComponentsList
                    : ManufactureNames.importantComponents

            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
    },
    importantComponents: {
        name: ManufactureNames.importantComponents,
        component: <ImportantComponents form={form} />,
        formInputProps: {
            label: 'Select the following components that are always required in an embodiment of the composition for the composition to accomplish its intended purpose :',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    form.getFieldValue<ManufactureValues['primaryComponents']>(
                        'primaryComponents'
                    ).length > 1
                        ? ManufactureNames.importantComponentsConnections
                        : ManufactureNames.componentWeights
                return newValues
            })
        },
    },
    importantComponentsConnections: {
        component: (
            <Form.List name="importantComponentsConnections">
                {(fields, { add }) => {
                    const primaryComponents =
                        form.getFieldValue<
                            ManufactureValues['primaryComponents']
                        >('primaryComponents')
                    return (
                        <>
                            {fields.map((field) => (
                                <Row gutter={[12, 0]}>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="1st component"
                                                    name={[
                                                        field.name,
                                                        '1stComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '1stComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '1st component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryComponents.map(
                                                            (item: any) => ({
                                                                value: item.component,
                                                                label: item.component,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="2nd component"
                                                    name={[
                                                        field.name,
                                                        '2ndComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '2ndComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '2nd component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryComponents.map(
                                                            (item: any) => ({
                                                                value: item.component,
                                                                label: item.component,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="Connection type"
                                                    name={[
                                                        field.name,
                                                        'connectionType',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        'connectionType',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Connection type is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={[
                                                            {
                                                                value: 'Rotatably connected to',
                                                                label: 'Rotatably connected to',
                                                            },
                                                            {
                                                                value: 'Slidably engaged with',
                                                                label: 'Slidably engaged with',
                                                            },
                                                            {
                                                                value: 'Coupled to',
                                                                label: 'Coupled to',
                                                            },
                                                            {
                                                                value: 'Integral with',
                                                                label: 'Integral with',
                                                            },
                                                            {
                                                                value: 'Coextruded with',
                                                                label: 'Coextruded with',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Form.Item
                                        name={[field.name, 'notSet']}
                                        valuePropName="checked"
                                        style={{ marginBottom: '20px' }}
                                    >
                                        <Checkbox>
                                            I want to describe the connection
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        name={[
                                            field.name,
                                            'additionalConnectionInfo',
                                        ]}
                                        shouldUpdate={(
                                            prevValues,
                                            curValues
                                        ) => {
                                            const prev =
                                                prevValues
                                                    .importantComponentsConnections[
                                                    field.name
                                                ]?.notSet
                                            const curr =
                                                curValues
                                                    .importantComponentsConnections[
                                                    field.name
                                                ]?.notSet
                                            return prev !== curr
                                        }}
                                    >
                                        {form.getFieldValue<
                                            ManufactureValues['importantComponentsConnections']
                                        >('importantComponentsConnections')[
                                            field.name
                                        ]?.notSet && <Input />}
                                    </Form.Item>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add connection
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.functionalConnections
                return newValues
            })
        },
        name: ManufactureNames.importantComponentsConnections,

        formInputProps: {
            label: <label>Select connections between components</label>,
        },
    },
    functionalConnections: {
        component: (
            <Form.List name="functionalConnections">
                {(fields, { add }) => {
                    const primaryComponents =
                        form.getFieldValue<
                            ManufactureValues['primaryComponents']
                        >('primaryComponents')
                    return (
                        <>
                            {fields.map((field) => (
                                <Row gutter={[12, 0]}>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="1st component"
                                                    name={[
                                                        field.name,
                                                        '1stComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '1stComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '1st component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryComponents.map(
                                                            (item) => ({
                                                                value:
                                                                    item.component ??
                                                                    '',
                                                                label: item.component,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="2nd component"
                                                    name={[
                                                        field.name,
                                                        '2ndComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '2ndComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '2nd component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryComponents.map(
                                                            (item) => ({
                                                                value:
                                                                    item.component ??
                                                                    '',
                                                                label: item.component,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Form.Item
                                        name={[
                                            field.name,
                                            'additionalConnectionInfo',
                                        ]}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    '1st component is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add relationship
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.componentWeights
                return newValues
            })
        },
        name: ManufactureNames.functionalConnections,

        formInputProps: {
            label: (
                <label>
                    Are any components related via a functional relationship
                    rather than physical contact? If yes, add the relationships
                </label>
            ),
        },
    },
    componentWeights: {
        name: ManufactureNames.componentWeights,
        formInputProps: {
            label: 'For each component, list a maximum acceptable weight percentage and a minimum acceptable weight percentage of the component as a percentage of the total weight of the composition.  Also, for each component, list a maximum preferred weight percentage and a minimum preferred weight percentage.',
        },
        component: <ComponentsWeights form={form} />,

        onNext: () => {
            const weightImportance =
                form.getFieldValue(['componentWeights', 'weightImportance']) ??
                false
            let nextQuestion = ManufactureNames.measurableProperties

            if (weightImportance) {
                nextQuestion = ManufactureNames.importantWeights
            }
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextQuestion
                return newValues
            })
        },
    },
    importantWeights: {
        name: ManufactureNames.importantWeights,
        component: <ImportantWeights form={form} />,
        formInputProps: {
            label: 'Select the following components that are always required in an embodiment of the composition for the composition to accomplish its intended purpose :',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.measurableProperties
                return newValues
            })
        },
    },
    measurableProperties: {
        name: ManufactureNames.measurableProperties,
        component: (
            <>
                <Form.Item
                    name={['measurableProperties', 'checkbox']}
                    valuePropName="checked"
                    style={{ marginBottom: '20px' }}
                >
                    <Checkbox defaultChecked={false}>Yes</Checkbox>
                </Form.Item>
            </>
        ),
        formInputProps: {
            label: 'Does the whole object have measurable properties?',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.componentsQuantity
                return newValues
            })
        },
    },
    componentsQuantity: {
        component: <ComponentsQuantity form={form} />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.characteristicCompositon
                return newValues
            })
        },
        name: ManufactureNames.componentsQuantity,

        formInputProps: {
            label: <label>Quantity</label>,
        },
    },
    characteristicCompositon: {
        name: ManufactureNames.characteristicCompositon,
        formInputProps: {
            label: undefined,
        },
        component: (
            <CharacteristicsComposition
                name={ManufactureNames.characteristicCompositon}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.characteristicCustom
                return newValues
            })
        },
    },
    characteristicCustom: {
        name: ManufactureNames.characteristicCustom,
        formInputProps: {
            label: 'Would you like to add a property that has not already been included?',
        },
        component: (
            <CharacteristicCustom
                form={form}
                name={ManufactureNames.characteristicCustom}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.characteristicExplanation
                return newValues
            })
        },
    },
    characteristicExplanation: {
        component: (
            <CharacteristicExplanation
                form={form as any}
                name={ManufactureNames.characteristicExplanation}
            />
        ),
        name: ManufactureNames.characteristicExplanation,
        formInputProps: {
            label: 'Please explain why each property is advantageous',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.importantCharacteristic
                return newValues
            })
        },
    },
    importantCharacteristic: {
        component: <ImportantCharacteristic form={form as any} />,
        name: ManufactureNames.importantCharacteristic,
        formInputProps: {
            label: 'Are any of the properties always required for the composition to achieve its objectives?',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    ManufactureNames.componentsForCharacteristics
                return newValues
            })
        },
    },
    componentsForCharacteristics: {
        name: ManufactureNames.componentsForCharacteristics,
        component: <ComponentsForCharacteristics form={form} />,
        formInputProps: {
            label: 'Select the following components that have characteristics that you would like to further describe:',
        },
        onNext: () => {
            const componentsForCharacteristics = form.getFieldValue<
                ManufactureForm['componentsForCharacteristics']
            >('componentsForCharacteristics')
            if (
                componentsForCharacteristics &&
                componentsForCharacteristics.length
            ) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.drawingsDescription
                    return newValues
                })
            }
        },
    },
    characteristic: {
        component: (
            <CharacteristicManufacture
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    ManufactureNames.characteristic
                )}
            />
        ),
        name: ManufactureNames.characteristic,
        formInputProps: {
            label: undefined,
        },
    },
    additionalCharacteristic: {
        component: (
            <AdditionalCharacteristic
                form={form as any}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    ManufactureNames.additionalCharacteristic
                )}
            />
        ),
        name: ManufactureNames.additionalCharacteristic,
        formInputProps: {
            label: 'Select the additional characteristics that you would like to add and fill in any applicable blanks.',
        },
    },
    [SharedNames.drawingsDescription]: {
        component: (
            <DrawingsDescription
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    ManufactureNames.characteristic
                )}
            />
        ),
        name: SharedNames.drawingsDescription,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.doneDescribing
                return newValues
            })
        },
    },
    doneDescribing: {
        component: (
            <Form.Item
                label="Indefinite Article"
                name={['doneDescribing', 'indefiniteArticle']}
                rules={[
                    {
                        required: true,
                        message: 'Indefinite article is required',
                    },
                ]}
                hasFeedback
            >
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        isLastQuestion: true,
        name: SharedNames.doneDescribing,
        formInputProps: {
            label: 'Are you done describing the manutacture?',
        },
    },
    describeMethod: {
        component: (
            <Form.Item name={['describeMethod']}>
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        name: ManufactureNames.describeMethod,
        formInputProps: {
            label: 'Would you like to describe the method at this time?',
        },
    },
    reviewPatent: {
        component: (
            <Form.Item name={['reviewPatent']}>
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        name: ManufactureNames.reviewPatent,
        formInputProps: {
            label: 'After this patent application is filed, would you like to allow one or more third parties to review your patent application so they can determine if they would be interested in obtaining an assignment or license of all or some of the rights and interests associated with the patent application?',
        },
    },
    patentProfessionalReview: {
        component: (
            <Form.Item name={['patentProfessionalReview']}>
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        name: ManufactureNames.patentProfessionalReview,
        formInputProps: {
            label: 'Would you like a patent professional to review your patent application?',
        },
    },
    formatUserDefined: {
        component: (
            <FormatUserDefined
                form={form as any}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    ManufactureNames.formatUserDefined
                )}
            />
        ),
        name: ManufactureNames.formatUserDefined,
        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                ManufactureNames.formatUserDefined
            )
            const componentsForCharacteristics = form.getFieldValue<
                ManufactureForm['componentsForCharacteristics']
            >('componentsForCharacteristics')
            if (currentListItemIndex < componentsForCharacteristics.length) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.doneDescribing
                    return newValues
                })
            }
        },
        formInputProps: {
            label: 'Select a format for the user defined variable and fill in the applicable blanks.',
        },
    },
})
