import { Menu } from 'antd'
import styled from 'styled-components'

export const Brand = styled.div`
    display: inline-block;
    margin-right: 1rem;
`

export const MenuItem = styled(Menu.Item)`
    .active {
        background: red;
    }
    &::after {
        border-bottom-style: none !important;
    }
`

export const DropdownWrapper = styled.div`
    margin-left: auto;
    cursor: pointer;
`
