import { MinusCircleOutlined } from '@ant-design/icons'
import { Form, Input, DatePicker, Col, Row, Button, Checkbox } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, CompositionForm, SharedValues } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
}

const CrossReferenceAndReletedApplication: FC<Props> = ({ form }) => {
    return (
        <>
            <Form.Item
                name={['backgroundInvention', 'notSet']}
                valuePropName="checked"
            >
                <Checkbox>Not applicable</Checkbox>
            </Form.Item>
            {!form.getFieldValue(['backgroundInvention', 'notSet']) ? (
                <>
                    <Form.Item label="Please provide U.S. Nonprovisional Patent Application number and date of submission. If you want, you can let lawyer complete this field">
                        <Form.List
                            name={[
                                'backgroundInvention',
                                'nonprovisionalNumbers',
                            ]}
                            rules={[
                                {
                                    validator: async (_, components) => {
                                        const provisionalNumbers =
                                            form.getFieldValue<
                                                SharedValues['backgroundInvention']['provisionalNumbers']
                                            >([
                                                'backgroundInvention',
                                                'provisionalNumbers',
                                            ])
                                        if (
                                            (components === undefined ||
                                                components?.length === 0) &&
                                            (provisionalNumbers === undefined ||
                                                provisionalNumbers.length === 0)
                                        )
                                            throw new Error(
                                                'At least one number!'
                                            )
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, errors) => (
                                <>
                                    {fields.map((field) => (
                                        <Form.Item
                                            shouldUpdate={(
                                                prevValues,
                                                curValues
                                            ) =>
                                                prevValues.commonName
                                                    ?.notSet !==
                                                curValues.commonName?.notSet
                                            }
                                            key={field.key}
                                        >
                                            <Row gutter={[12, 0]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            'number',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'number',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required:
                                                                    !form.getFieldValue(
                                                                        [
                                                                            'backgroundInvention',
                                                                            'notSet',
                                                                        ]
                                                                    ) ?? true,
                                                                message:
                                                                    'Number is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Input type="text" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            'date',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'date',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required:
                                                                    !form.getFieldValue(
                                                                        [
                                                                            'backgroundInvention',
                                                                            'notSet',
                                                                        ]
                                                                    ) ?? true,
                                                                message:
                                                                    'Date of submission is required',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: 'center',
                                                        paddingTop: '.4rem',
                                                    }}
                                                    span={2}
                                                >
                                                    {fields.length > 0 ? (
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                            style={{
                                                                color: '#f00',
                                                                fontSize:
                                                                    '1.2rem',
                                                            }}
                                                        />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}

                                    <Form.ErrorList errors={errors.errors} />
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                        >
                                            Add nonprovisional number
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item label="Please provide U.S. Provisional Patent Application number and date of submission. If you want, you can let lawyer complete this field">
                        <Form.List
                            name={['backgroundInvention', 'provisionalNumbers']}
                            rules={[
                                {
                                    validator: async (_, components) => {
                                        const nonprovisionalNumbers =
                                            form.getFieldValue<
                                                SharedValues['backgroundInvention']['nonprovisionalNumbers']
                                            >([
                                                'backgroundInvention',
                                                'nonprovisionalNumbers',
                                            ])
                                        if (
                                            (components === undefined ||
                                                components?.length === 0) &&
                                            (nonprovisionalNumbers ===
                                                undefined ||
                                                nonprovisionalNumbers.length ===
                                                    0)
                                        )
                                            throw new Error(
                                                'At least one number!'
                                            )
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, errors) => (
                                <>
                                    {fields.map((field) => (
                                        <Form.Item
                                            shouldUpdate={(
                                                prevValues,
                                                curValues
                                            ) =>
                                                prevValues.commonName
                                                    ?.notSet !==
                                                curValues.commonName?.notSet
                                            }
                                        >
                                            <Row gutter={[12, 0]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            'number',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'number',
                                                        ]}
                                                        rules={[
                                                            {
                                                                required:
                                                                    !form.getFieldValue(
                                                                        [
                                                                            'backgroundInvention',
                                                                            'notSet',
                                                                        ]
                                                                    ) ?? true,
                                                                message:
                                                                    'Common name is required',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input type="text" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        {...field}
                                                        name={[
                                                            field.name,
                                                            'date',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'date',
                                                        ]}
                                                        rules={[
                                                            {
                                                                required:
                                                                    !form.getFieldValue(
                                                                        [
                                                                            'backgroundInvention',
                                                                            'notSet',
                                                                        ]
                                                                    ) ?? true,
                                                                message:
                                                                    'Common name is required',
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <DatePicker
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    style={{
                                                        textAlign: 'center',
                                                        paddingTop: '.4rem',
                                                    }}
                                                    span={2}
                                                >
                                                    {fields.length > 0 ? (
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                            style={{
                                                                color: '#f00',
                                                                fontSize:
                                                                    '1.2rem',
                                                            }}
                                                        />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}

                                    <Form.ErrorList errors={errors.errors} />
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                        >
                                            Add provisional number
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </>
            ) : (
                <></>
            )}
            <Form.Item
                name={['backgroundInvention', 'custom']}
                valuePropName="checked"
            >
                <Checkbox>
                    Are there any advantages or desirable characteristics that
                    your invention provides but existing embodiment fail to
                    provide?
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default CrossReferenceAndReletedApplication
