import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm } from '../types'

type Props = {
    form:
        | CustomFormInstance<ManufactureForm>
        | CustomFormInstance<CompositionForm>
}

type Values = ManufactureForm | CompositionForm

const ImportantComponents: FC<Props> = ({ form }) => {
    const primaryComponents =
        form.getFieldValue<Values['primaryComponents']>('primaryComponents') ??
        []
    return (
        <Form.Item name="importantComponents">
            <Select
                mode="multiple"
                options={primaryComponents.map((item: any) => ({
                    value: item.component,
                    label: item.component,
                }))}
            />
        </Form.Item>
    )
}

export default ImportantComponents
