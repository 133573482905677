import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { StyledDraggable } from './DraggableHandler.styles'
import { IDraggableHandler } from './DraggableHandler.types'

const DraggableHandler: React.FC<IDraggableHandler> = ({
    id,
    data,
    setElementData,
    removeElement,
    child,
}): JSX.Element => {
    const [prevX, setPrevX] = useState<number>(parseFloat(data.left))
    const [prevY, setPrevY] = useState<number>(parseFloat(data.top))
    const handleDragStart = (x: number, y: number) => {
        setPrevX(x)
        setPrevY(y)
    }
    const handleDragEnd = (x: number, y: number) => {
        const xDiff = -(prevX - x)
        const yDiff = -(prevY - y)
        setElementData(id, {
            ...data,
            left: (parseFloat(data.left) + xDiff).toString(),
            top: (parseFloat(data.top) + yDiff).toString(),
        })
    }
    const handleSetText = (e: string) => {
        setElementData(id, {
            ...data,
            text: e,
        })
    }
    useEffect(() => {
        const node = document.getElementById(`node-${id}`)! as HTMLElement
        const pointermove = (e: PointerEvent) => {
            const deleteElement = document.getElementById(
                '#deleteElement'
            )! as HTMLElement
            const {
                offsetLeft: left,
                offsetTop: top,
                offsetHeight: height,
                offsetWidth: width,
            } = deleteElement
            const minX = left
            const maxX = left + width
            const minY = top
            const maxY = top + height
            let { x, y } = e
            x -= 50
            y -= 138
            if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
                node.style.filter = 'grayscale(100%)'
            } else {
                node.style.filter = 'grayscale(0%)'
            }
        }
        node.addEventListener('pointerdown', (e) => {
            node.addEventListener('pointermove', pointermove)
        })
        node.addEventListener('pointerup', (e) => {
            node.removeEventListener('pointermove', pointermove)
            const deleteElement = document.getElementById(
                '#deleteElement'
            )! as HTMLElement
            const {
                offsetLeft: left,
                offsetTop: top,
                offsetHeight: height,
                offsetWidth: width,
            } = deleteElement
            const minX = left
            const maxX = left + width
            const minY = top
            const maxY = top + height
            let { x, y } = e
            x -= 50
            y -= 138
            if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
                removeElement(id)
                node.style.filter = 'grayscale(100%)'
            }
        })
    }, [])
    return (
        <Draggable
            position={{ x: parseFloat(data.left), y: parseFloat(data.top) }}
            onStart={(e: any) => handleDragStart(e.clientX, e.clientY)}
            onStop={(e: any) => handleDragEnd(e.clientX, e.clientY)}
        >
            <StyledDraggable id={`node-${id}`}>
                {React.cloneElement(child, {
                    text: data.text,
                    setText: (e: string) => handleSetText(e),
                })}
            </StyledDraggable>
        </Draggable>
    )
}

export default DraggableHandler
