import { StyledCube } from './Cube.styles'

const Cube: React.FC = (): JSX.Element => {
    return (
        <StyledCube>
            <div />
        </StyledCube>
    )
}

export default Cube
