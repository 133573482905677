import { Form, Select, Input, Col, Row, Button } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm, MethodsForm } from '../types'

type Props = {
    form:
        | CustomFormInstance<MethodsForm>
        | CustomFormInstance<ManufactureForm>
        | CustomFormInstance<CompositionForm>
    name: string
}

type Values = MethodsForm | ManufactureForm | CompositionForm

const CharacteristicExplanation: FC<Props> = ({ form, name }) => {
    const typedFormRef = form as CustomFormInstance<ManufactureForm>
    let properties: string[] = []

    let characteristicCustom =
        typedFormRef.getFieldValue<Values['characteristicCustom']>([
            'characteristicCustom',
        ]) ?? undefined

    if (characteristicCustom != null) {
        const characteristicCustomKeys = Object.keys(characteristicCustom)
        properties = properties.concat(characteristicCustomKeys)
    }

    const [property, setProperty] = useState<string>(properties[0])

    const handleChange = (value: SelectValue) => {
        setProperty((value ?? '').toString())
    }

    const addProperty = () => {
        if (characteristicCustom == null) {
            form.setFieldsValue({ characteristicCustom: {} })
            characteristicCustom = typedFormRef.getFieldValue<
                Values['characteristicCustom']
            >('characteristicCustom')
        }
        const newProperty =
            typedFormRef.getFieldValue<Values['newProperty']>('newProperty')
        characteristicCustom[newProperty] = {
            value: undefined,
            unitSI: undefined,
            'max-acceptable': undefined,
            'max-preferable': undefined,
            'min-acceptable': undefined,
            'min-preferable': undefined,
        }
        form.setFieldsValue({ characteristicCustom, newProperty: '' })
        setProperty(newProperty)
    }

    const removeProperty = (propertyName: string) => {
        const newProperty = typedFormRef.getFieldValue<
            ManufactureForm['characteristicCustom']
        >('characteristicCustom')
        if (newProperty[propertyName]) {
            delete newProperty[propertyName]
        }
        typedFormRef.setFieldsValue({ characteristicCustom: newProperty })
        setProperty(Object.keys(newProperty)[0])
    }

    const basePath = ['characteristicCustom', property] as [
        'characteristicCustom',
        string
    ]
    const unitSI = typedFormRef.getFieldValue<any>([...basePath, 'unitSI'])
    let showUnitSI = ''
    if (unitSI != null) {
        showUnitSI = `[${unitSI}]`
    }

    return (
        <>
            <Form.Item
                name={name}
                label="Set name of the new property and click add"
            >
                <Row gutter={[12, 0]}>
                    <Col span={18}>
                        <Form.Item name="newProperty">
                            <Input value="" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="newProperty">
                            <Button onClick={addProperty} type="dashed" block>
                                Add
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            {characteristicCustom != null &&
            Object.keys(characteristicCustom).length > 0 ? (
                <>
                    <Row gutter={[4, 0]} align="middle">
                        <Col span={18}>
                            <Form.Item label="Property you want to describe:">
                                <Select
                                    options={properties.map((item: any) => ({
                                        value: item,
                                        label: item,
                                    }))}
                                    value={property}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Col span={6} style={{ paddingTop: '0.4rem' }}>
                                <Button
                                    onClick={() => removeProperty(property)}
                                    danger
                                >
                                    Remove
                                </Button>
                            </Col>
                        </Col>
                    </Row>

                    <Form.Item
                        name={[...basePath, 'unitSI']}
                        label="What SI units are used to report the property?"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input value="" />
                    </Form.Item>

                    <Form.Item
                        name={[...basePath, 'max-acceptable']}
                        label={`Maximum acceptable value ${showUnitSI}`}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input value="" />
                    </Form.Item>

                    <Form.Item
                        name={[...basePath, 'min-acceptable']}
                        label={`Minimum acceptable value ${showUnitSI}`}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input value="" />
                    </Form.Item>

                    <Form.Item
                        name={[...basePath, 'max-preferable']}
                        label={`Maximum preferable value ${showUnitSI}`}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input value="" />
                    </Form.Item>

                    <Form.Item
                        name={[...basePath, 'min-preferable']}
                        label={`Minimum preferable value ${showUnitSI}`}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'This field is required',
                            },
                        ]}
                    >
                        <Input value="" />
                    </Form.Item>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default CharacteristicExplanation
