import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authApi } from '../../../api/api'
import { STORAGE_KEYS } from '../../../utils/consts'
import { ISaveUser } from '../../../utils/types'
import { isProfileData } from './user.functions'
import { IUser, IUserDetailsRes, UserState } from './user.types'

const initialState = null as UserState

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    return authApi
        .get<IUserDetailsRes>(
            `/api/users/detail/${localStorage.getItem(STORAGE_KEYS.ID)}`
        )
        .then((res) => {
            const { email, fullName, form } = res.data
            return {
                email,
                fullName,
                projects: form,
                lastId: form.length ? form[form.length - 1].projectId : 0,
            }
        })
        .catch((err) => {
            console.log('err', err)
            return null
        })
})

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (_state, action: PayloadAction<IUser>) => action.payload,
        updateUser: (state, action: PayloadAction<ISaveUser>) => {
            const data = action.payload
            if (isProfileData(data))
                return {
                    ...state,
                    email: state?.email,
                    fullName: data.fullName,
                } as IUser
            return state
        },
        clearUser: () => null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (_state, action) => action.payload)
    },
})

export const { setUser, updateUser, clearUser } = user.actions
export default user.reducer
