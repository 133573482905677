import { BrowserRouter } from 'react-router-dom'
import { Switch } from 'react-router'
import Layout from 'antd/lib/layout'
import {
    Profile,
    Page404,
    SignIn,
    SignUp,
    Projects,
    Project,
} from '../../pages'
import Route from '../Route'

const Router: React.FC = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/sign-in" title="Sign In" exact>
                    <SignIn />
                </Route>
                <Route path="/sign-up" title="Sign Up" exact>
                    <SignUp />
                </Route>
                <Layout>
                    <Route path="/profile" title="Profile" exact authenticated>
                        <Profile />
                    </Route>
                    <Switch>
                        <Route
                            path="/projects"
                            title="Projects"
                            exact
                            authenticated
                        >
                            <Projects />
                        </Route>
                        <Route
                            path="/projects/new"
                            title="New Project"
                            exact
                            authenticated
                        >
                            <Project />
                        </Route>
                        <Route
                            path="/projects/:id"
                            title="Missing title"
                            exact
                            authenticated
                        >
                            <Project />
                        </Route>
                        <Route title="Page 404" authenticated>
                            <Page404 />
                        </Route>
                    </Switch>
                </Layout>
                <Route title="Page 404">
                    <Page404 />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Router
