import { ValidQuestionKeys } from '../hooks/useGerateQuestions'

const mapQuestionNames: Record<ValidQuestionKeys, string> = {
    titleComponent: 'name',
    backgroundInvention: 'name',
    commonName: 'name',
    methodOutline: 'name',
    subSteps: 'name',
    summaryOfSelectedEmbodimentsOfTheInvention: 'name',
    doneDescribing: 'name',
    subcomponentsForCharacteristics: 'name',
    subFormatUserDefined: 'name',
    subCharacteristic: 'name',
    subAdditionalCharacteristic: 'name',
    stepPracticalPercentageLimit: 'name',
    stepPracticalLimit: 'name',
    reviewPatent: 'name',
    primaryComponents: 'name',
    primaryComponentSubComponentsList: 'name',
    patentProfessionalReview: 'name',
    newProperty: 'name',
    methodOutlineInputs: 'name',
    additionalCharacteristic: 'name',
    methodOutlineDescription: 'name',
    measurableProperties: 'name',
    mainSubcomponents: 'name',
    importantWeights: 'name',
    importantComponentsConnections: 'name',
    importantComponents: 'name',
    importantCharacteristic: 'name',
    functionalConnections: 'name',
    formatUserDefined: 'name',
    embodimentNecessaryStep: 'name',
    detailedDescription: 'name',
    describeMethod: 'name',
    componentsQuantity: 'name',
    componentsForCharacteristics: 'name',
    componentWeights: 'name',
    characteristicExplanation: 'name',
    componentStepPracticalPercentageLimit: 'name',
    componentStepPracticalLimit: 'name',
    characteristicMethodOutline: 'name',
    characteristicMethod: 'name',
    characteristicCustom: 'name',
    characteristicCompositon: 'name',
    characteristic: 'name',
    abstractOfTheDisclosure: 'none',
    customBackgroundOfInvention: 'none',
    dependantComponentsConnections: 'none',
    extraOptions: 'none',
    lastPane: 'none',
    partsOfDependentClaim: 'none',
    partsOfIndependentClaim: 'none',
    subSummaryOfSelectedEmbodimentsOfTheInvention: 'none',
    drawingsDescription: 'name',
}

export default mapQuestionNames
