import { PointerArrow } from './PointerArrow.styles'
import Img from '../../../img/next.png'

const Arrow: React.FC = (): JSX.Element => {
    return (
        <PointerArrow>
            <img src={Img} alt="Next" />
        </PointerArrow>
    )
}

export default Arrow
