import { Input, Button, Select, Form, Tooltip, Checkbox, Col, Row } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { Questions } from '../../../hooks/useGerateQuestions'
import ImportantCharacteristic from './components/ImportantCharacteristic'
import ComponentsWeights from './components/ComponentsWeights'
import CharacteristicsComposition from './components/CharacteristicComposition'
import CharacteristicExplanation from './components/CharacteristicExplanation'
import CharacteristicCustom from './components/CharacteristicCompositionCustom'
import CrossReferenceAndReletedApplication from './components/CrossReferenceAndRelatedApplication'
import Title from './components/Title'
import PrimaryComponents from './components/PrimaryComponents'
import { getCurrentListItemIndex } from '../../getCurrentListItemIndex'
import AbstractOfTheDisclosure from './components/MachineAbstractOfTheDisclosure'
import SubSummaryOfSelectedEmbodimentsOfTheInvention from './components/MachineSubSummaryOfSelectedEmbodimentsOfTheInvention'
import CommonName from './components/CommonName'
import CustomBackgroundOfInnovation from './components/CustomBackgroundOfInnovation'
import { CustomFormInstance } from '../../../types'
import {
    CompositionForm,
    CompositionNames,
    CompositionValues,
    SharedNames,
} from './types'
import DrawingsDescription from './components/DrawingsDescription'
import CompositionPartsOfIndependentClaim from './components/CompositionPartsOfIndependentClaim'
import ImportantWeights from './components/ImportantWeights'

type GetMethodsElementsArgs = {
    form: CustomFormInstance<CompositionForm>
    setActiveQuestions: React.Dispatch<React.SetStateAction<string[]>>
    activeQuestions: string[]
    currentQuestionIndex: number
    setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>
}

export const getCompositionElements = ({
    form,
    setActiveQuestions,
    activeQuestions,
    currentQuestionIndex,
    setCurrentQuestionIndex,
}: GetMethodsElementsArgs): Questions<CompositionForm> => ({
    titleComponent: {
        component: <Title initialValue="COMPOSITON FOR" />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.commonName
                return newValues
            })
        },
        name: SharedNames.titleComponent,
        formInputProps: {
            label: 'Please select the title of the invention and list all the inventors',
        },
    },
    commonName: {
        component: <CommonName />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.primaryComponents
                return newValues
            })
        },
        name: SharedNames.commonName,
        formInputProps: {
            label: 'If the composition has a common name, what is it?',
        },
    },
    primaryComponents: {
        component: (
            <PrimaryComponents name={CompositionNames.primaryComponents} />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.partsOfIndependentClaim
                return newValues
            })
        },
        name: CompositionNames.primaryComponents,
        formInputProps: {
            label: (
                <label>
                    Please list the{' '}
                    <Tooltip
                        title="Examples of primary components include components that you believe are necessary
                         for the invention to work, necessary to describe how the invention is novel, or necessary to explain how any other primary components are connected."
                    >
                        <span style={{ textDecoration: 'underline' }}>
                            primary components
                        </span>
                    </Tooltip>{' '}
                    of the machine and select a corresponding indefinite article
                </label>
            ),
        },
    },
    partsOfIndependentClaim: {
        name: CompositionNames.partsOfIndependentClaim,
        component: (
            <CompositionPartsOfIndependentClaim
                form={form}
                name={CompositionNames.partsOfIndependentClaim}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.importantComponentsConnections
                return newValues
            })
        },
        formInputProps: {
            label: (
                <label>
                    Select all components that should end up in Independant
                    Claim. All other components will end up in Dependant Claim.
                </label>
            ),
        },
    },
    importantComponentsConnections: {
        component: (
            <Form.List name="importantComponentsConnections">
                {(fields, { add, remove }) => {
                    const primaryOptions = form.getFieldValue<
                        CompositionForm['partsOfIndependentClaim']
                    >('partsOfIndependentClaim')
                    return (
                        <>
                            {fields.map((field) => (
                                <Form.Item style={{ marginBottom: '24px' }}>
                                    <Row align="middle" gutter={[12, 0]}>
                                        <Col span={10}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="1st component"
                                                        name={[
                                                            field.name,
                                                            '1stComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '1stComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '1st component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={primaryOptions.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="2nd component"
                                                        name={[
                                                            field.name,
                                                            '2ndComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '2ndComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '2nd component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={primaryOptions.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={2.5}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{
                                                    color: '#f00',
                                                    fontSize: '1.6rem',
                                                    marginTop: '0.6rem',
                                                }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Connection type"
                                                        name={[
                                                            field.name,
                                                            'connectionType',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'connectionType',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Connection type is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={[
                                                                {
                                                                    value: 'Rotatably connected to',
                                                                    label: 'Rotatably connected to',
                                                                },
                                                                {
                                                                    value: 'Slidably engaged with',
                                                                    label: 'Slidably engaged with',
                                                                },
                                                                {
                                                                    value: 'Coupled to',
                                                                    label: 'Coupled to',
                                                                },
                                                                {
                                                                    value: 'Integral with',
                                                                    label: 'Integral with',
                                                                },
                                                                {
                                                                    value: 'Coextruded with',
                                                                    label: 'Coextruded with',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={[field.name, 'notSet']}
                                                valuePropName="checked"
                                                style={{ marginBottom: '20px' }}
                                            >
                                                <Checkbox>
                                                    I want to describe the
                                                    connection
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    field.name,
                                                    'additionalConnectionInfo',
                                                ]}
                                                shouldUpdate={(
                                                    prevValues,
                                                    curValues
                                                ) => {
                                                    const prev =
                                                        prevValues
                                                            .importantComponentsConnections[
                                                            field.name
                                                        ]?.notSet
                                                    const curr =
                                                        curValues
                                                            .importantComponentsConnections[
                                                            field.name
                                                        ]?.notSet
                                                    return prev !== curr
                                                }}
                                                rules={[
                                                    {
                                                        validator: async (
                                                            _,
                                                            item
                                                        ) => {
                                                            const importantComponentsConnections =
                                                                form.getFieldValue<
                                                                    CompositionForm['importantComponentsConnections']
                                                                >(
                                                                    'importantComponentsConnections'
                                                                )[field.key]
                                                            if (
                                                                importantComponentsConnections &&
                                                                importantComponentsConnections.notSet &&
                                                                (item == null ||
                                                                    item === '')
                                                            ) {
                                                                throw new Error(
                                                                    'Description about connectivity is required'
                                                                )
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                {form.getFieldValue<
                                                    CompositionForm['importantComponentsConnections']
                                                >(
                                                    'importantComponentsConnections'
                                                )[field.name]?.notSet && (
                                                    <Input placeholder="Description" />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add connection
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.functionalConnections
                return newValues
            })
        },
        name: CompositionNames.importantComponentsConnections,
        formInputProps: {
            label: (
                <label>
                    Select connections between components from Independant Claim
                </label>
            ),
        },
    },
    functionalConnections: {
        component: (
            <Form.List name="functionalConnections">
                {(fields, { add, remove }) => {
                    const primaryOptions = form.getFieldValue<
                        CompositionForm['partsOfIndependentClaim']
                    >('partsOfIndependentClaim')
                    return (
                        <>
                            {fields.map((field) => (
                                <Row align="middle" gutter={[12, 0]}>
                                    <Col span={11}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="1st component"
                                                    name={[
                                                        field.name,
                                                        '1stComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '1stComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '1st component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="2nd component"
                                                    name={[
                                                        field.name,
                                                        '2ndComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '2ndComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '2nd component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={primaryOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2.5}>
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            style={{
                                                color: '#f00',
                                                fontSize: '1.6rem',
                                                marginTop: '0.6rem',
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                field.name,
                                                'additionalConnectionInfo',
                                            ]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Description about connectivity is required',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Description" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add relationship
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            const partsOfDependentClaim = form.getFieldValue<
                CompositionForm['partsOfDependentClaim']
            >('partsOfDependentClaim')
            if (partsOfDependentClaim && partsOfDependentClaim.length > 0) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.dependantComponentsConnections
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.componentWeights
                    return newValues
                })
            }
        },
        name: CompositionNames.functionalConnections,
        formInputProps: {
            label: (
                <label>
                    Are any components related via a functional relationship
                    rather than physical contact? If yes, add the relationships
                </label>
            ),
        },
    },
    dependantComponentsConnections: {
        component: (
            <Form.List name="dependantComponentsConnections">
                {(fields, { add, remove }) => {
                    const partsOfIndependentClaim: Array<string> =
                        form.getFieldValue('partsOfIndependentClaim')

                    const partsOfDependentClaim = form.getFieldValue<
                        CompositionValues['partsOfDependentClaim']
                    >('partsOfDependentClaim')

                    return (
                        <>
                            {fields.map((field) => (
                                <Form.Item style={{ marginBottom: '24px' }}>
                                    <Row align="middle" gutter={[12, 0]}>
                                        <Col span={11}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="1st component"
                                                        name={[
                                                            field.name,
                                                            '1stComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '1stComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '1st component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={partsOfDependentClaim.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="2nd component"
                                                        name={[
                                                            field.name,
                                                            '2ndComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '2ndComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '2nd component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={partsOfIndependentClaim.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{
                                                    color: '#f00',
                                                    fontSize: '1.6rem',
                                                    marginTop: '0.6rem',
                                                }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Connection type"
                                                        name={[
                                                            field.name,
                                                            'connectionType',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'connectionType',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Connection type is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={[
                                                                {
                                                                    value: 'Rotatably connected to',
                                                                    label: 'Rotatably connected to',
                                                                },
                                                                {
                                                                    value: 'Slidably engaged with',
                                                                    label: 'Slidably engaged with',
                                                                },
                                                                {
                                                                    value: 'Coupled to',
                                                                    label: 'Coupled to',
                                                                },
                                                                {
                                                                    value: 'Integral with',
                                                                    label: 'Integral with',
                                                                },
                                                                {
                                                                    value: 'Coextruded with',
                                                                    label: 'Coextruded with',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={[field.name, 'notSet']}
                                                valuePropName="checked"
                                                style={{ marginBottom: '20px' }}
                                            >
                                                <Checkbox>
                                                    I want to describe the
                                                    connection
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    field.name,
                                                    'additionalConnectionInfo',
                                                ]}
                                                shouldUpdate={(
                                                    prevValues,
                                                    curValues
                                                ) => {
                                                    const prev =
                                                        prevValues
                                                            .dependantComponentsConnections[
                                                            field.name
                                                        ]?.notSet
                                                    const curr =
                                                        curValues
                                                            .dependantComponentsConnections[
                                                            field.name
                                                        ]?.notSet
                                                    return prev !== curr
                                                }}
                                                rules={[
                                                    {
                                                        validator: async (
                                                            _,
                                                            item
                                                        ) => {
                                                            const dependantComponentsConnections =
                                                                form.getFieldValue<
                                                                    CompositionForm['dependantComponentsConnections']
                                                                >(
                                                                    'dependantComponentsConnections'
                                                                )[field.key]
                                                            if (
                                                                dependantComponentsConnections &&
                                                                dependantComponentsConnections.notSet &&
                                                                (item == null ||
                                                                    item === '')
                                                            ) {
                                                                throw new Error(
                                                                    'Description about connectivity is required'
                                                                )
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                {form.getFieldValue<
                                                    CompositionForm['dependantComponentsConnections']
                                                >(
                                                    'dependantComponentsConnections'
                                                )[field.name]?.notSet && (
                                                    <Input placeholder="Description" />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add connection
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.backgroundInvention
                return newValues
            })
        },
        name: CompositionNames.dependantComponentsConnections,
        formInputProps: {
            label: (
                <label>
                    Add connections between components from Independant Claim
                    and Dependant Claim. Remember that all components from
                    Dependant Claim should have connections with minimum one
                    component from Independent Claim
                </label>
            ),
        },
    },
    componentWeights: {
        component: <ComponentsWeights form={form} />,
        onNext: () => {
            const weightImportance =
                form.getFieldValue(['componentWeights', 'weightImportance']) ??
                false
            let nextQuestion = CompositionNames.characteristicCompositon

            if (weightImportance) {
                nextQuestion = CompositionNames.importantWeights
            }
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextQuestion
                return newValues
            })
        },
        name: CompositionNames.componentWeights,
        formInputProps: {
            // eslint-disable-next-line max-len
            label: 'For each component, list a maximum acceptable weight percentage and a minimum acceptable weight percentage of the component as a percentage of the total weight of the composition.  Also, for each component, list a maximum preferred weight percentage and a minimum preferred weight percentage.',
        },
    },
    importantWeights: {
        name: CompositionNames.importantWeights,
        component: <ImportantWeights form={form} />,
        formInputProps: {
            label: 'List the weight percentages that are always required for the composition to achieve its objectives:',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.characteristicCompositon
                return newValues
            })
        },
    },
    characteristicCompositon: {
        name: CompositionNames.characteristicCompositon,
        formInputProps: {
            label: 'Does the composition include important measurable or detectable properties?',
        },
        component: (
            <CharacteristicsComposition
                name={CompositionNames.characteristicCompositon}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.characteristicCustom
                return newValues
            })
        },
    },
    characteristicCustom: {
        name: CompositionNames.characteristicCustom,
        formInputProps: {
            label: 'Would you like to add a property that has not already been included?',
        },
        component: (
            <CharacteristicCustom
                form={form}
                name={CompositionNames.characteristicCustom}
            />
        ),
        onNext: () => {
            let properties: string[] = []
            const characteristicCompositon =
                form.getFieldValue<any>('characteristicCompositon') ?? undefined

            if (characteristicCompositon != null) {
                const characteristicKeys = Object.keys(characteristicCompositon)
                properties = properties.concat(characteristicKeys)
            }

            const characteristicCustom =
                form.getFieldValue<any>('characteristicCustom') ?? undefined

            if (characteristicCustom != null) {
                const characteristicCustomKeys =
                    Object.keys(characteristicCustom)
                properties = properties.concat(characteristicCustomKeys)
            }
            if (properties.length > 0) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.characteristicExplanation
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.backgroundInvention
                    return newValues
                })
            }
        },
    },
    characteristicExplanation: {
        component: (
            <CharacteristicExplanation
                form={form as any}
                name={CompositionNames.characteristicExplanation}
            />
        ),
        name: CompositionNames.characteristicExplanation,
        formInputProps: {
            label: 'Please explain why each property is advantageous',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.importantCharacteristic
                return newValues
            })
        },
    },
    importantCharacteristic: {
        component: <ImportantCharacteristic form={form as any} />,
        name: CompositionNames.importantCharacteristic,
        formInputProps: {
            label: 'Are any of the properties always required for the composition to achieve its objectives?',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.doneDescribing
                return newValues
            })
        },
    },
    backgroundInvention: {
        component: <CrossReferenceAndReletedApplication form={form} />,
        onNext: () => {
            const customSet = form.getFieldValue([
                'backgroundInvention',
                'custom',
            ])
            if (customSet) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.customBackgroundOfInvention
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                    return newValues
                })
            }
        },
        name: SharedNames.backgroundInvention,
        formInputProps: {
            label: 'Please write Cross-Reference and Related Applications',
        },
    },
    customBackgroundOfInvention: {
        component: <CustomBackgroundOfInnovation form={form} />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                return newValues
            })
        },
        name: SharedNames.backgroundInvention,
        formInputProps: {
            label: 'List the advantage or desirable characteristic that your invention provides but existing embodiment fail to provide',
        },
    },
    summaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <Form.List
                    name={[
                        'summaryOfSelectedEmbodimentsOfTheInvention',
                        'summary',
                    ]}
                >
                    {(fields, { add }) => (
                        <>
                            {fields.map((field) => (
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'summary']}
                                    fieldKey={[field.fieldKey, 'summary']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add summary
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.doneDescribing
                return newValues
            })
        },
        name: SharedNames.summaryOfSelectedEmbodimentsOfTheInvention,
        formInputProps: {
            label: 'Please write summary of selected embodiments of the invention',
        },
    },
    subSummaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <SubSummaryOfSelectedEmbodimentsOfTheInvention
                    form={form as any}
                    subComponentIndex={getCurrentListItemIndex(
                        activeQuestions,
                        currentQuestionIndex,
                        CompositionNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                    )}
                />
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const currentListItemIndex = getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex + 1,
                    CompositionNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                )
                let nextComponent
                const summaryOfSelectedEmbodimentsOfTheInvention =
                    form.getFieldValue<any>(
                        'summaryOfSelectedEmbodimentsOfTheInvention'
                    ) ?? []
                const isExistSubcomponent = currentListItemIndex
                if (
                    isExistSubcomponent <=
                    summaryOfSelectedEmbodimentsOfTheInvention.length
                ) {
                    if (
                        form.getFieldValue<
                            CompositionForm['primaryComponentSubComponentsList']
                        >('primaryComponentSubComponentsList')[
                            summaryOfSelectedEmbodimentsOfTheInvention[
                                isExistSubcomponent
                            ]
                        ] === undefined
                    ) {
                        const newValues = [...prev]
                        newValues[currentQuestionIndex + 1] =
                            CompositionNames.abstractOfTheDisclosure
                        return newValues
                    }
                    nextComponent =
                        currentListItemIndex <
                        summaryOfSelectedEmbodimentsOfTheInvention.length
                            ? CompositionNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                            : CompositionNames.abstractOfTheDisclosure
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] = nextComponent
                    return newValues
                }
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.abstractOfTheDisclosure
                return newValues
            })
        },
        name: CompositionNames.subSummaryOfSelectedEmbodimentsOfTheInvention,
        formInputProps: {
            label: undefined,
        },
    },
    abstractOfTheDisclosure: {
        component: <AbstractOfTheDisclosure form={form as any} />,
        name: CompositionNames.abstractOfTheDisclosure,
        formInputProps: {
            label: 'Abstract of the disclosure',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    CompositionNames.detailedDescription
                return newValues
            })
        },
    },
    detailedDescription: {
        component: (
            <>
                <p>Detailed Description</p>
                <Form.Item
                    name={['detailedDescription', 'describe-disclosure']}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                    hasFeedback
                >
                    <Input type="text" />
                </Form.Item>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.drawingsDescription
                return newValues
            })
        },
        name: CompositionNames.detailedDescription,
        formInputProps: {
            label: 'Please write detailed  description ',
        },
    },
    [SharedNames.drawingsDescription]: {
        component: (
            <DrawingsDescription
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    CompositionNames.characteristicCompositon
                )}
            />
        ),
        name: SharedNames.drawingsDescription,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.doneDescribing
                return newValues
            })
        },
    },
    doneDescribing: {
        component: (
            <Form.Item name={['doneDescribing', 'indefiniteArticle']}>
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        onNext: () => {
            const done = form.getFieldValue<
                CompositionForm['doneDescribing']['indefiniteArticle']
            >(['doneDescribing', 'indefiniteArticle'])
            if (done.value === 'yes') {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.lastPane
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        CompositionNames.extraOptions
                    return newValues
                })
            }
        },
        name: SharedNames.doneDescribing,
        formInputProps: {
            label: 'Are you done describing the composition?',
        },
    },
    extraOptions: {
        component: (
            <>
                <Form.Item label="Do you want to add another component?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    CompositionNames.primaryComponents
                                )
                            )
                        }}
                        block
                    >
                        Go to components
                    </Button>
                </Form.Item>
                <Form.Item label="Do you want to change weights of components?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    CompositionNames.componentWeights
                                )
                            )
                        }}
                        block
                    >
                        Go to weights
                    </Button>
                </Form.Item>
                <Form.Item label="Do you want to change characteristics?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    CompositionNames.characteristicCompositon
                                )
                            )
                        }}
                        block
                    >
                        Go to characteristics
                    </Button>
                </Form.Item>
                <Form.Item
                    label="Do you need to talk to patent professional?"
                    name={[
                        CompositionNames.extraOptions,
                        'patent-proffesional',
                    ]}
                    initialValue="no"
                >
                    <Select
                        labelInValue
                        options={[
                            {
                                value: 'yes',
                                label: 'yes',
                            },
                            {
                                value: 'no',
                                label: 'no',
                            },
                        ]}
                    />
                </Form.Item>
            </>
        ),
        name: CompositionNames.extraOptions,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = CompositionNames.lastPane
                return newValues
            })
        },
    },
    lastPane: {
        component: <></>,
        isLastQuestion: true,
        name: CompositionNames.lastPane,
        formInputProps: {
            label: 'Thank you for completing this form!',
        },
    },
})
