import { Form, Row, Col, InputNumber } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    characteristicComponentIndex: number
}

const MethodComponentStepPracticalPercentageLimit: FC<Props> = ({
    form,
    characteristicComponentIndex,
}) => {
    const stepComponent =
        form.getFieldValue<any>('stepPracticalPercentageLimit') ?? []
    const allOutlineSteps = form.getFieldValue<any>('methodOutlineInputs') ?? []
    const baseName = [
        'componentStepPracticalPercentageLimit',
        stepComponent[characteristicComponentIndex],
    ]
    return (
        <>
            {allOutlineSteps[
                `${stepComponent[characteristicComponentIndex]}`
            ].map((item: any, index: number) => (
                <Row gutter={[12, 0]} align="middle">
                    <Col span={7}>
                        <p>Constituent {index + 1}</p>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            name={[...baseName, item.inputComponent, 'min']}
                        >
                            <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <p>makes up</p>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name={[...baseName, item.inputComponent, 'max']}
                        >
                            <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <p>wt. % of {item.inputComponent}</p>
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default MethodComponentStepPracticalPercentageLimit
