// based on the docx documentation: https://docx.js.org/#/usage/styling-with-js

import { IStylesOptions, UnderlineType } from 'docx'

export const getDocxStyles = (): IStylesOptions => ({
    default: {
        heading1: {
            run: {
                size: 24,
                bold: false,
                color: 'FFFFFF',
                font: 'Times New Roman',
            },
            paragraph: {
                spacing: {
                    line: 360,
                },
            },
        },
        heading2: {
            run: {
                size: 24,
                bold: true,
                color: 'FFFFFF',
                font: 'Times New Roman',
                underline: {
                    type: UnderlineType.DOUBLE,
                    color: 'FFFFFF',
                },
            },
            paragraph: {
                spacing: {
                    before: 240,
                    after: 120,
                    line: 360,
                },
            },
        },
        heading3: {
            run: {
                size: 24,
                color: 'FFFFFF',
                font: 'Times New Roman',
            },
            paragraph: {
                spacing: {
                    line: 360,
                },
            },
        },
        heading4: {
            run: {
                size: 24,
                color: 'FFFFFF',
                font: 'Times New Roman',
            },
            paragraph: {
                spacing: {
                    line: 360,
                },
            },
        },
        heading5: {
            run: {
                size: 24,
                color: 'FFFFFF',
                font: 'Times New Roman',
            },
            paragraph: {
                spacing: {
                    line: 360,
                },
            },
        },
        heading6: {
            run: {
                size: 24,
                color: 'FFFFFF',
                font: 'Times New Roman',
            },
            paragraph: {
                spacing: {
                    line: 360,
                },
            },
        },
        listParagraph: {
            run: {
                color: 'FFFFFF',
            },
        },
    },
})
