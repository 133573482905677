import { Form, Select } from 'antd'
import { FC } from 'react'
import { Common, CustomFormInstance } from '../../../../types'
import { CompositionForm, MachineForm, MachineValues } from '../types'

type Values = Common<CompositionForm, MachineForm>

type Props = {
    form: CustomFormInstance<Values>
    subComponentIndex: number
}

const SubSummaryOfSelectedEmbodimentsOfTheInvention: FC<Props> = ({
    form,
    subComponentIndex,
}) => {
    const summaryOfSelectedEmbodimentsOfTheInvention =
        form.getFieldValue<
            Values['summaryOfSelectedEmbodimentsOfTheInvention']
        >('summaryOfSelectedEmbodimentsOfTheInvention') ?? []
    const primaryComponents =
        form.getFieldValue<MachineValues['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    const primaryComponentId =
        summaryOfSelectedEmbodimentsOfTheInvention[subComponentIndex] ?? ''

    const subcomponents = form.getFieldValue<
        MachineValues['primaryComponentSubComponentsList']
    >('primaryComponentSubComponentsList')[primaryComponentId]

    const primaryComponentName =
        primaryComponents.find((comp) => comp.id === primaryComponentId)
            ?.component ?? ''
    return (
        <>
            <Form.Item
                name={[
                    'subSummaryOfSelectedEmbodimentsOfTheInvention',
                    primaryComponentName,
                ]}
                label={`Please select the combination of ${primaryComponentName} that you belive are novel over the prior art.`}
                rules={[
                    {
                        required: true,
                        message: 'Connection type is required',
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    options={subcomponents.map((item) => {
                        return {
                            value: item.id ?? '',
                            label: item.component,
                        }
                    })}
                />
            </Form.Item>
        </>
    )
}

export default SubSummaryOfSelectedEmbodimentsOfTheInvention
