import { Layout, Menu, Avatar, Dropdown, Typography } from 'antd'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Brand, MenuItem, DropdownWrapper } from './Navbar.styles'
import { getPathnameId } from './Navbar.functions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { signOut } from '../../store/slices/isAuth/isAuth'
import Logo from '../../img/logo.svg'
import { clearUser } from '../../store/slices/user/user'

const { Text } = Typography

const Navbar: React.FC = (): JSX.Element => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user!)
    return (
        <Layout.Header
            style={{
                display: 'flex',
                background: 'white',
            }}
        >
            <Brand>
                <img src={Logo} alt="logo" />
            </Brand>
            <Menu
                theme="light"
                defaultSelectedKeys={[getPathnameId(history.location.pathname)]}
                mode="horizontal"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: 'none',
                }}
            >
                <MenuItem key="1">
                    <Link to="/projects">Projects</Link>
                </MenuItem>
            </Menu>
            <DropdownWrapper>
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item key="3">
                                <Link to="/profile">Profile</Link>
                            </Menu.Item>
                            <Menu.Item
                                key="4"
                                onClick={() => {
                                    dispatch(clearUser())
                                    dispatch(signOut())
                                }}
                            >
                                Sign Out
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <div style={{ height: '100%' }}>
                        <Avatar style={{ marginRight: '.3rem' }}>
                            {user.fullName[0]}
                        </Avatar>
                        <Text>{user.fullName}</Text>
                    </div>
                </Dropdown>
            </DropdownWrapper>
        </Layout.Header>
    )
}

export default Navbar
