import { Button, Col, Divider, Form, Input, Row, Spin, Switch } from 'antd/lib'
import Layout from 'antd/lib/layout/layout'
import { useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { emailRules } from '../../utils/consts'
import { fetchSaveUser, isLoading } from '../../utils/functions'
import { REQUEST_STATUS } from '../../utils/types'
import { IProfileData } from './ProfileDataForm.types'
import { CustomForm } from './ProfleDetailsForm.styles'

const ProfileDetailsForm: React.FC = (): JSX.Element => {
    const user = useAppSelector((state) => state.user!)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(
        REQUEST_STATUS.NONE
    )
    const onSubmit = (formData: unknown): void => {
        setRequestStatus(REQUEST_STATUS.PENDING)
        const data = formData as IProfileData
        fetchSaveUser(data)
            .then(() => {
                setRequestStatus(REQUEST_STATUS.SUCCESS)
            })
            .catch(() => {
                setRequestStatus(REQUEST_STATUS.FAILED)
            })
    }
    const onSubmitFailed = (): void => {
        setRequestStatus(REQUEST_STATUS.FAILED)
    }
    const handleEditMode = (val: boolean): void => {
        setEditMode(val)
    }
    return (
        <Spin spinning={isLoading(requestStatus)}>
            <CustomForm
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                requiredMark="optional"
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
            >
                <Divider plain>Profile details</Divider>
                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={emailRules}
                    hasFeedback
                    initialValue={user.email}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Fullname"
                    name="fullName"
                    hasFeedback
                    initialValue={user.fullName}
                >
                    <Input disabled={!editMode} />
                </Form.Item>
                <Layout style={{ background: 'white' }}>
                    <Row justify="space-between">
                        <Col>
                            <Form.Item wrapperCol={{ span: 2 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!editMode}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Switch
                                    onChange={handleEditMode}
                                    checkedChildren="Edit mode"
                                    unCheckedChildren="Edit mode"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Layout>
            </CustomForm>
        </Spin>
    )
}

export default ProfileDetailsForm
