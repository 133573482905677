import {
    Button,
    Col,
    message,
    Radio,
    RadioChangeEvent,
    Row,
    Space,
    Tabs,
    Switch,
} from 'antd'
import Form from 'antd/lib/form'
import { Helmet } from 'react-helmet'
import { DownloadOutlined, QuestionOutlined } from '@ant-design/icons'
import { Document, Packer } from 'docx'
import React, { useEffect, useRef, useState } from 'react'
import mammoth from 'mammoth/mammoth.browser'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { StyledLayout, CustomRow, CustomForm, DOCX } from './Project.styles'
import { useGerateQuestions } from '../../hooks'
import { TopBar, RequestForHelp, TableOfContent } from '../../components'
import DiagramsLib from '../../DiagramsLib'
import { ClaimsState } from '../../store/slices/claims/claims.types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setClaims } from '../../store/slices/claims/claims'
import { api, authApi } from '../../api/api'
import { STORAGE_KEYS } from '../../utils/consts'
import { IFigure } from '../../DiagramsLib/DiagramsLib.types'
import { REQUEST_STATUS } from '../../utils/types'
import { getCurrentListItemIndex } from '../../utils/getCurrentListItemIndex'
import { StyledSpin } from '../../utils/styles'
import { getQuestions, ValidQuestionKeys } from '../../hooks/useGerateQuestions'
import { generateId, getClaimNum } from '../../utils'
import { generateDOCX } from './Project.utils'
import {
    decrementFigureIndex,
    incrementFigureIndex,
} from '../../store/slices/figures/figures'
import {
    CustomFormInstance,
    isMachineForm,
    isManufactureForm,
    ValueOf,
} from '../../types'
import {
    FormValues,
    MachineForm,
    MachineNames,
    MachineValues,
    SharedNames,
} from '../../utils/forms/methods/types'
import { FormTabNames } from '../../const'

const noHelpRequiredQuestions: ValidQuestionKeys[] = [
    'lastPane',
    'doneDescribing',
]
const { TabPane } = Tabs

const NewProject: React.FC = () => {
    const dispatch = useAppDispatch()
    const { figures, claims } = useAppSelector((state) => state)
    const history = useHistory()
    const diagramsLibRef = useRef<React.ElementRef<typeof DiagramsLib>>(null)
    const pathnameId = history.location.pathname.split('/').splice(1)[1]
    const [diagramScreen, setDiagramScreen] = useState<boolean>(false)
    const [category, setCategory] = useState<{
        name: keyof getQuestions
        selected: boolean
    }>({ name: 'method', selected: false })

    const [itemsToReview, setItemsToReview] = useState<ValidQuestionKeys[]>([])
    const [contentBasedItemsToReview, setContentBasedItemsToReview] = useState<
        ValidQuestionKeys[]
    >([])
    const [activeTab, setActiveTab] = useState(FormTabNames.MAIN_FORM)

    const {
        setInitialQuestions,
        activeQuestions,
        selectedQuestions,
        formRef,
        currentQuestionIndex,
        setCurrentQuestionIndex,
    } = useGerateQuestions(category.name)

    const [generatedHtml, setGeneratedHtml] = useState<string | null>(null)
    const [generatedDocx, setGeneratedDocx] = useState<Document | null>(null)
    const [projectId, setProjectId] = useState<null | number>(
        pathnameId === 'new' ? null : parseFloat(pathnameId)
    )
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(
        pathnameId === 'new' ? REQUEST_STATUS.SUCCESS : REQUEST_STATUS.PENDING
    )
    const [initialCharts, setInitialCharts] = useState<null | IFigure[]>(null)
    const handleSave = (): void => {
        const savedChart = diagramsLibRef.current?.save()
        const categoryName = category.name
        const savedForm: any = {
            activeQuestions,
            formValues: {},
            html: generatedHtml,
            categoryName,
        }
        const patentName = formRef.getFieldValue([
            'titleComponent',
            'patentName',
        ])
        const serviceCall = formRef.getFieldValue([
            'titleComponent',
            'footerNumber',
        ])
        const description = formRef.getFieldValue([
            'titleComponent',
            'description',
        ])
        const progress = 25
        const aToken = localStorage.getItem('aToken')
        activeQuestions.forEach((question, i) => {
            if (question === 'drawingsDescription') {
                const xd = formRef.getFieldValue([
                    'drawingsDescription',
                    'figure',
                ])
                savedForm.formValues.figure = xd
            }
            if (question === 'primaryComponentSubComponentsList') {
                if (
                    isMachineForm(formRef, question) &&
                    isManufactureForm(formRef, question)
                ) {
                    const mainComponents =
                        formRef.getFieldValue<MachineForm['mainSubcomponents']>(
                            'mainSubcomponents'
                        )
                    const currentSubcomponentIndex = getCurrentListItemIndex(
                        activeQuestions,
                        i,
                        question
                    )
                    const name = [
                        'primaryComponentSubComponentsList',
                        mainComponents[currentSubcomponentIndex],
                    ] as ['primaryComponentSubComponentsList', string]
                    savedForm.formValues[name.join('.')] =
                        formRef.getFieldValue(name)
                }
            } else if (question === 'titleComponent') {
                savedForm.formValues.titleComponent =
                    formRef.getFieldValue('titleComponent')
            } else if (question === 'additionalCharacteristic') {
                if (
                    isMachineForm(formRef, categoryName) &&
                    isManufactureForm(formRef, categoryName)
                ) {
                    savedForm.formValues.additionalCharacteristic =
                        formRef.getFieldValue('additionalCharacteristic')
                }
            } else if (question === 'partsOfIndependentClaim') {
                if (isMachineForm(formRef, question)) {
                    savedForm.formValues[question] =
                        formRef.getFieldValue(question)
                    savedForm.formValues.partsOfDependentClaim =
                        formRef.getFieldValue('partsOfDependentClaim')
                }
            } else {
                savedForm.formValues[question] = formRef.getFieldValue(
                    question as any
                )
            }
        })

        const nextQuestion = selectedQuestions[currentQuestionIndex].onNext
        if (typeof nextQuestion === 'function') {
            nextQuestion()
        }
        setCurrentQuestionIndex((prev) =>
            prev >= selectedQuestions.length ? prev : prev + 1
        )
        setCurrentQuestionIndex((prev) => (prev > 0 ? prev - 1 : 0))

        const config = {
            headers: { Authorization: `Bearer ${aToken}` },
        }

        if (!projectId) {
            authApi
                .post(
                    `/api/form/create`,
                    {
                        userId: localStorage.getItem(STORAGE_KEYS.ID),
                        question: JSON.stringify(savedForm),
                        chart: JSON.stringify(savedChart),
                        activeQuestion: currentQuestionIndex,
                        patentName,
                        serviceCall,
                        description,
                        progress,
                    },
                    config
                )
                .then((res) => {
                    setProjectId(res.data)
                })
                .catch((e) => console.log(e))
        } else {
            authApi
                .put(
                    `/api/form/update/${projectId}`,
                    {
                        userId: localStorage.getItem(STORAGE_KEYS.ID),
                        question: JSON.stringify(savedForm),
                        chart: JSON.stringify(savedChart),
                        activeQuestion: currentQuestionIndex,
                        patentName,
                        serviceCall,
                        description,
                        progress,
                    },
                    config
                )
                .catch((e) => console.log(e))
        }
    }

    const handleGenerateDOCX = () => {
        setGeneratedDocx(
            generateDOCX(
                formRef,
                activeQuestions,
                category.name,
                diagramsLibRef.current?.save(),
                claims
            )
        )
    }

    useEffect(() => {
        handleGenerateDOCX()
    }, [JSON.stringify(claims)])

    const onCategoryChange = (e: RadioChangeEvent) => {
        setCategory({ ...category, name: e?.target?.value })
    }

    const onTabContentItemClick = (itemName: ValidQuestionKeys) => {
        const questionIndex = activeQuestions.findIndex(
            (question) => question === itemName
        )
        setCurrentQuestionIndex(questionIndex)
        setActiveTab(FormTabNames.MAIN_FORM)
    }

    useEffect(() => {
        if (projectId) {
            const aToken = localStorage.getItem('aToken')

            const config = {
                headers: { Authorization: `Bearer ${aToken}` },
            }
            authApi
                .get(`/api/form/${projectId}`, config)
                .then((res) => {
                    const charts = JSON.parse(res.data.chart)
                    const questions = JSON.parse(res.data.question)
                    const activeIndex = res.data.activeQuestion
                    const { html, categoryName: x } = questions
                    if (
                        questions.formValues.backgroundInvention
                            .nonprovisionalNumbers
                    ) {
                        Object.entries(
                            questions.formValues.backgroundInvention
                                .nonprovisionalNumbers
                        ).forEach(([key, val]: any) => {
                            questions.formValues.backgroundInvention.nonprovisionalNumbers[
                                key
                            ].date = moment(val.date)
                        })
                    }
                    if (
                        questions.formValues.backgroundInvention
                            .provisionalNumbers
                    ) {
                        Object.entries(
                            questions.formValues.backgroundInvention
                                .provisionalNumbers
                        ).forEach(([key, val]: any) => {
                            questions.formValues.backgroundInvention.provisionalNumbers[
                                key
                            ].date = moment(val.date)
                        })
                    }
                    Object.entries(questions.formValues).forEach(
                        ([key, val]) => {
                            if (key.includes('.')) {
                                const name = key.split('.')
                                formRef.setFieldsValue({
                                    primaryComponentSubComponentsList: {
                                        [name[1]]: val,
                                    },
                                })
                            } else {
                                formRef.setFieldsValue({
                                    [key]: val,
                                })
                            }
                        }
                    )
                    setInitialCharts(charts)
                    setGeneratedHtml(html)
                    setCategory({ name: x, selected: true })
                    setInitialQuestions(questions.activeQuestions)
                    setCurrentQuestionIndex(activeIndex ?? 0)
                    setRequestStatus(REQUEST_STATUS.SUCCESS)
                })
                .catch(() => {
                    history.push('/projects')
                })
        } else {
            setRequestStatus(REQUEST_STATUS.SUCCESS)
        }
    }, [])

    useEffect(() => {
        if (diagramScreen) {
            const newClaims: ClaimsState = {
                id: '000',
                name: 'noname',
                number: '000',
                components: [],
            }

            const typedFormRef = formRef as CustomFormInstance<MachineForm>
            const primaryComponents =
                typedFormRef.getFieldValue<MachineForm['primaryComponents']>(
                    MachineNames.primaryComponents
                ) ?? []

            const mainSubcomponents =
                typedFormRef.getFieldValue<MachineValues['mainSubcomponents']>(
                    MachineNames.mainSubcomponents
                ) ?? []

            const commonName = formRef.getFieldValue<FormValues['commonName']>(
                SharedNames.commonName
            )

            let subCompBefore = 0
            if (
                !commonName?.notSet &&
                commonName?.indefiniteArticle &&
                commonName?.commonName
            ) {
                newClaims.number = '000'
                newClaims.name = commonName.commonName
            }
            newClaims.components = primaryComponents.map(
                (component, index: number) => ({
                    uid: component.id,
                    id: getClaimNum(index + 1),
                    number: getClaimNum(index + 1),
                    name: component.component ?? '',
                })
            )
            mainSubcomponents.forEach((subCompId) => {
                const subComponents =
                    formRef.getFieldValue<
                        ValueOf<
                            MachineValues['primaryComponentSubComponentsList']
                        >
                    >([
                        MachineNames.primaryComponentSubComponentsList,
                        subCompId,
                    ]) ?? []
                newClaims.components = newClaims.components.map((component) => {
                    if (component.uid === subCompId) {
                        return {
                            ...component,
                            components: subComponents.map((subComponent) => {
                                subCompBefore += 1
                                return {
                                    uid: subComponent.id,
                                    id: getClaimNum(
                                        primaryComponents.length + subCompBefore
                                    ),
                                    number: getClaimNum(
                                        primaryComponents.length + subCompBefore
                                    ),
                                    name: subComponent.component ?? '',
                                }
                            }),
                        }
                    }
                    return component
                })
            })

            dispatch(setClaims(newClaims))
        }
    }, [diagramScreen, dispatch])
    useEffect(() => {
        if (generatedDocx) {
            Packer.toBuffer(generatedDocx).then((buffer) => {
                mammoth
                    .convertToHtml(
                        { arrayBuffer: buffer },
                        { styleMap: ['p.ListParagraph => ul > li:fresh'] }
                    )
                    .then(({ value }) => {
                        setGeneratedHtml(value)
                    })
            })
        }
    }, [JSON.stringify(generatedDocx)])

    const { component, name, formInputProps, isLastQuestion } =
        selectedQuestions[currentQuestionIndex]

    let customFormChild = (
        <Form.Item label="Check one or more boxes below to indicate which type of invention you would like to protect:">
            <Radio.Group onChange={onCategoryChange}>
                <Space direction="vertical">
                    <Radio value="composition">Composition</Radio>
                    <Radio value="manufacture">Article of manufacture</Radio>
                    <Radio value="machine">Machine</Radio>
                    <Radio value="method">Method</Radio>
                </Space>
            </Radio.Group>
        </Form.Item>
    )
    if (category.selected) {
        const isChecked = itemsToReview.includes(name as ValidQuestionKeys)
        customFormChild = (
            <>
                {!noHelpRequiredQuestions.includes(
                    name as ValidQuestionKeys
                ) && (
                    <Switch
                        checkedChildren={<QuestionOutlined />}
                        unCheckedChildren={<QuestionOutlined />}
                        style={{ marginBottom: '40px' }}
                        checked={isChecked}
                        onChange={() => {
                            if (isChecked) {
                                setItemsToReview((prev) => [
                                    ...prev.filter((item) => item !== name),
                                ])
                            } else {
                                setItemsToReview((prev) => [
                                    ...prev,
                                    name as ValidQuestionKeys,
                                ])
                            }
                        }}
                    />
                )}
                <Form.Item name={name as string} {...formInputProps}>
                    {React.cloneElement(component, {
                        setContentBasedItemsToReview,
                    })}
                </Form.Item>
            </>
        )
    }
    return (
        <StyledSpin spinning={requestStatus === REQUEST_STATUS.PENDING}>
            <StyledLayout>
                <Helmet title="New project" />
                <Content style={{ padding: '0 50px', position: 'relative' }}>
                    <TopBar
                        breadcrumb={[
                            'Projects',
                            projectId
                                ? `Project ${projectId} - ${
                                      category.name[0].toUpperCase() +
                                      category.name.slice(1)
                                  }`
                                : `New project - ${
                                      category.name[0].toUpperCase() +
                                      category.name.slice(1)
                                  }`,
                        ]}
                    >
                        <div>
                            <Button
                                type="dashed"
                                onClick={() =>
                                    setDiagramScreen((prev) => !prev)
                                }
                                style={{
                                    marginRight: '.5rem',
                                }}
                            >
                                {diagramScreen
                                    ? 'Switch to form screen'
                                    : 'Switch to diagram screen'}
                            </Button>
                            <Button type="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </TopBar>
                    <Col
                        span={24}
                        style={{
                            visibility: diagramScreen ? 'visible' : 'hidden',
                            opacity: diagramScreen ? 1 : 0,
                            position: 'absolute',
                            top: '4.5rem',
                            left: '50px',
                            width: 'calc(100% - 120px)',
                            height: 'calc(100% - 11.5rem)',
                            zIndex: 20,
                        }}
                    >
                        {requestStatus !== REQUEST_STATUS.PENDING && (
                            <DiagramsLib
                                ref={diagramsLibRef}
                                initialState={initialCharts}
                            />
                        )}
                    </Col>
                    <CustomRow visible={!diagramScreen}>
                        <Col
                            span={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <CustomForm
                                form={formRef as any}
                                layout="vertical"
                                onFinish={(values) => {
                                    console.log('values', values)
                                }}
                                onValuesChange={() => {
                                    // TODO: if needed add there a debounce logic
                                    handleGenerateDOCX()
                                    if (
                                        isMachineForm(
                                            formRef,
                                            MachineNames.primaryComponents
                                        )
                                    ) {
                                        const primaryComponents =
                                            formRef.getFieldValue<
                                                MachineValues['primaryComponents']
                                            >(MachineNames.primaryComponents)

                                        const mainSubcomponents =
                                            formRef.getFieldValue<
                                                MachineValues['mainSubcomponents']
                                            >(MachineNames.mainSubcomponents) ??
                                            []

                                        const componentsForCharacteristics =
                                            formRef.getFieldValue<
                                                MachineValues['componentsForCharacteristics']
                                            >(
                                                MachineNames.componentsForCharacteristics
                                            ) ?? []
                                        const subcomponentsForCharacteristics =
                                            formRef.getFieldValue<
                                                MachineValues['subcomponentsForCharacteristics']
                                            >(
                                                MachineNames.subcomponentsForCharacteristics
                                            ) ?? []

                                        const subComponents =
                                            formRef.getFieldValue<
                                                MachineValues['primaryComponentSubComponentsList']
                                            >(
                                                MachineNames.primaryComponentSubComponentsList
                                            ) ?? {}

                                        const newPrimaryComponents =
                                            primaryComponents?.map((item) => {
                                                const subComponent =
                                                    subComponents[
                                                        item?.id ?? ''
                                                    ]

                                                if (subComponent) {
                                                    const newSubComponents =
                                                        subComponent.map(
                                                            (subItem) => {
                                                                return {
                                                                    ...subItem,
                                                                    id:
                                                                        subItem?.id ??
                                                                        generateId(),
                                                                }
                                                            }
                                                        )
                                                    formRef.setFieldsValue({
                                                        primaryComponentSubComponentsList:
                                                            {
                                                                [item?.id ??
                                                                '']: newSubComponents,
                                                            },
                                                    })
                                                }
                                                return {
                                                    ...item,
                                                    id:
                                                        item?.id ??
                                                        generateId(),
                                                }
                                            })

                                        const newMainComponents =
                                            mainSubcomponents.filter(
                                                (compId) => {
                                                    return !!primaryComponents.find(
                                                        (comp) =>
                                                            comp.id === compId
                                                    )
                                                }
                                            )
                                        const newComponentsForCharacteristics =
                                            componentsForCharacteristics.filter(
                                                (compId) => {
                                                    return !!primaryComponents.find(
                                                        (comp) =>
                                                            comp.id === compId
                                                    )
                                                }
                                            )
                                        const newSubcomponentsForCharacteristics: MachineValues['subcomponentsForCharacteristics'] =
                                            {}

                                        Object.entries(
                                            subcomponentsForCharacteristics
                                        ).forEach(([key, val]) => {
                                            const componentSubComponentsList =
                                                subComponents[key] ?? []
                                            const newVal = val.filter(
                                                (charSubItemId) =>
                                                    componentSubComponentsList?.find(
                                                        (subCompItem) =>
                                                            subCompItem.id ===
                                                            charSubItemId
                                                    )
                                            )
                                            newSubcomponentsForCharacteristics[
                                                key as string
                                            ] = newVal
                                        })

                                        formRef.setFieldsValue({
                                            primaryComponents:
                                                newPrimaryComponents,
                                        })

                                        formRef.setFieldsValue({
                                            componentsForCharacteristics:
                                                newComponentsForCharacteristics,
                                        })
                                        formRef.setFieldsValue({
                                            subcomponentsForCharacteristics:
                                                newSubcomponentsForCharacteristics,
                                        })

                                        formRef.setFieldsValue({
                                            mainSubcomponents:
                                                newMainComponents,
                                        })
                                    }
                                }}
                            >
                                <Tabs
                                    activeKey={activeTab}
                                    onChange={(tabKey) => {
                                        setActiveTab(tabKey as FormTabNames)
                                    }}
                                >
                                    <TabPane
                                        tab="Form"
                                        key={FormTabNames.MAIN_FORM}
                                    >
                                        <>
                                            {customFormChild}

                                            <Row justify="space-between">
                                                <Col span={5}>
                                                    {currentQuestionIndex >
                                                        0 && (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                const question =
                                                                    selectedQuestions[
                                                                        currentQuestionIndex
                                                                    ]
                                                                if (
                                                                    question.name !==
                                                                        'drawingsDescription' ||
                                                                    figures.figureIndex ===
                                                                        0
                                                                ) {
                                                                    setCurrentQuestionIndex(
                                                                        (
                                                                            prev
                                                                        ) =>
                                                                            prev >
                                                                            0
                                                                                ? prev -
                                                                                  1
                                                                                : 0
                                                                    )
                                                                }
                                                                if (
                                                                    question.name ===
                                                                        'drawingsDescription' &&
                                                                    figures.figureIndex >
                                                                        0
                                                                ) {
                                                                    dispatch(
                                                                        decrementFigureIndex()
                                                                    )
                                                                }
                                                            }}
                                                        >
                                                            Previous
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col span={5}>
                                                    <Button
                                                        type="primary"
                                                        onClick={async () => {
                                                            if (
                                                                category.selected ===
                                                                false
                                                            ) {
                                                                setCategory({
                                                                    ...category,
                                                                    selected:
                                                                        true,
                                                                })
                                                            } else {
                                                                try {
                                                                    await formRef.validateFields()
                                                                    if (
                                                                        isLastQuestion
                                                                    ) {
                                                                        formRef.submit()
                                                                        message.success(
                                                                            'Thanks for completing the form!'
                                                                        )
                                                                        // history.push(
                                                                        //     '/projects'
                                                                        // )
                                                                        return
                                                                    }
                                                                    const question =
                                                                        selectedQuestions[
                                                                            currentQuestionIndex
                                                                        ]
                                                                    if (
                                                                        typeof question.onNext ===
                                                                        'function'
                                                                    ) {
                                                                        if (
                                                                            question.name ===
                                                                            'drawingsDescription'
                                                                        ) {
                                                                            if (
                                                                                figures.figureIndex <
                                                                                figures
                                                                                    .figures
                                                                                    .length -
                                                                                    1
                                                                            ) {
                                                                                dispatch(
                                                                                    incrementFigureIndex()
                                                                                )
                                                                            } else {
                                                                                question.onNext()
                                                                            }
                                                                        } else {
                                                                            question.onNext()
                                                                        }
                                                                    }
                                                                    if (
                                                                        question.name !==
                                                                            'drawingsDescription' ||
                                                                        figures.figureIndex ===
                                                                            figures
                                                                                .figures
                                                                                .length -
                                                                                1
                                                                    ) {
                                                                        setCurrentQuestionIndex(
                                                                            (
                                                                                prev
                                                                            ) =>
                                                                                prev >=
                                                                                selectedQuestions.length
                                                                                    ? prev
                                                                                    : prev +
                                                                                      1
                                                                        )
                                                                    }
                                                                } catch (e) {
                                                                    console.log(
                                                                        'Form errors list',
                                                                        e
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Col
                                                span={4}
                                                style={{ marginTop: '20px' }}
                                            >
                                                <Form.Item>
                                                    <Button
                                                        icon={
                                                            <DownloadOutlined />
                                                        }
                                                        type="primary"
                                                        //  TODO: Add this for debugging form question value
                                                        // htmlType="submit"
                                                        onClick={() => {
                                                            if (generatedDocx) {
                                                                Packer.toBlob(
                                                                    generatedDocx
                                                                ).then(
                                                                    (blob) => {
                                                                        saveAs(
                                                                            blob,
                                                                            'example.docx'
                                                                        )
                                                                    }
                                                                )
                                                            } else {
                                                                message.info(
                                                                    'Before download you need to fill the form first'
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    </TabPane>
                                    <TabPane
                                        tab="List of requests for help"
                                        key={FormTabNames.REQUEST_FOR_HELP}
                                    >
                                        <RequestForHelp
                                            onItemClick={onTabContentItemClick}
                                            contentBasedMarkedQuestions={
                                                contentBasedItemsToReview
                                            }
                                            formRef={formRef}
                                            markedQuestions={itemsToReview}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab="Table of contents"
                                        key={FormTabNames.TABLE_OF_CONTENT}
                                    >
                                        <TableOfContent
                                            onItemClick={onTabContentItemClick}
                                            activeQuestions={activeQuestions}
                                        />
                                    </TabPane>
                                </Tabs>
                            </CustomForm>
                        </Col>
                        <Col span={12}>
                            <DOCX>
                                {generatedHtml && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: generatedHtml,
                                        }}
                                    />
                                )}
                            </DOCX>
                        </Col>
                    </CustomRow>
                </Content>
            </StyledLayout>
        </StyledSpin>
    )
}

export default NewProject
