import { Helmet } from 'react-helmet'
import { Progress, Table, Button } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { Link, useHistory } from 'react-router-dom'
import { StyledLayout } from './Projects.styles'
import { TopBar } from '../../components'
import { IRow } from './Projects.types'
import { useAppSelector } from '../../store/hooks'

const { Column } = Table

const Projects: React.FC = (): JSX.Element => {
    const history = useHistory()
    const user = useAppSelector((state) => state.user!)
    const { projects } = user
    const data: IRow[] = projects.map((project, index) => {
        const date = new Date(parseInt(project.updatedAt ?? 0, 10) * 1000)
        const formattedDate = `
        ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${
            date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`
        return {
            id: index + 1,
            patent_name: project.patentName,
            description: project.description,
            service_calls: project.serviceCall,
            progress: project.progress ?? 0,
            last_update: formattedDate,
        }
    })
    return (
        <StyledLayout>
            <Helmet title="Projects" />
            <Content style={{ padding: '0 50px' }}>
                <TopBar breadcrumb={['Projects']}>
                    <Button type="primary">
                        <Link to="/projects/new">New project</Link>
                    </Button>
                </TopBar>
                <Table
                    dataSource={data}
                    onRow={(r) => ({
                        onClick: () => history.push(`/projects/${r.id}`),
                    })}
                >
                    <Column
                        title="#"
                        dataIndex="id"
                        key="id"
                        sorter={(a: any, b: any) => {
                            if (a && b) {
                                return a.index - b.index
                            }
                            return 0
                        }}
                    />
                    <Column
                        title="Patent name"
                        dataIndex="patent_name"
                        key="patent_name"
                        sorter={(a: any, b: any) => {
                            if (a && b) {
                                return a.patent_name.localeCompare(
                                    b.patent_name
                                )
                            }
                            return ''
                        }}
                    />
                    <Column
                        title="Description"
                        dataIndex="description"
                        key="description"
                        sorter={(a: any, b: any) => {
                            if (a && b) {
                                return a.description.localeCompare(
                                    b.description
                                )
                            }
                            return ''
                        }}
                    />
                    <Column
                        title="Service calls"
                        dataIndex="service_calls"
                        key="service_calls"
                        sorter={(a: any, b: any) => {
                            if (a && b) {
                                return a.service_calls - b.service_calls
                            }
                            return 0
                        }}
                    />
                    <Column
                        title="Progress"
                        dataIndex="progress"
                        key="progress"
                        render={(progress) => <Progress percent={progress} />}
                        sorter={(a: IRow, b: IRow) => {
                            if (a && b) {
                                return a.progress - b.progress
                            }
                            return 0
                        }}
                        sortDirections={['descend', 'ascend']}
                    />
                    <Column
                        title="Last update"
                        dataIndex="last_update"
                        key="last_update"
                    />
                </Table>
            </Content>
        </StyledLayout>
    )
}

export default Projects
