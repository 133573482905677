import { Breadcrumb } from 'antd'
import { StyledTopBar } from './TopBar.styles'
import { ITopBar } from './TopBar.types'

const { Item } = Breadcrumb

export const TopBar: React.FC<ITopBar> = ({
    breadcrumb,
    children,
}): JSX.Element => {
    return (
        <StyledTopBar>
            <Breadcrumb style={{ margin: '25px 0' }}>
                {breadcrumb.map((item) => (
                    <Item>{item}</Item>
                ))}
            </Breadcrumb>
            {children}
        </StyledTopBar>
    )
}

export default TopBar
