import styled from 'styled-components'

export const StyledTriangle = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
    &::after {
        content: '';
        width: calc(100% - 8px);
        height: calc(100% - 6px);
        background: #f2f2f2;
        position: absolute;
        top: 4px;
        left: 4px;
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
`
