import styled from 'styled-components'

export const StyledCylinder = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    padding: 25px 0;
    opacity: 1;
    &::before {
        content: '';
        width: 100%;
        height: 50px;
        border-radius: 50%;
        border: 2px solid black;
        position: absolute;
        top: 0;
        left: 0px;
        background: #cccccc;
        z-index: 2;
    }
    &::after {
        content: '';
        width: 100%;
        height: 50px;
        border-radius: 50%;
        border: 2px solid black;
        position: absolute;
        bottom: 0;
        left: 0px;
        clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
        background: #f2f2f2;
    }
    div {
        height: calc(100% - 50px);
        width: 100%;
        position: absolute;
        border: 2px solid black;
        border-bottom: none;
        border-top: none;
        background: #f2f2f2;
    }
`
