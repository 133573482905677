import { Card } from 'antd'
import styled from 'styled-components'

const StyledCard = styled(Card)`
    height: 100%;
    width: 100%;
    &:hover {
        transform: scale(0.95);
    }
    transition: 0.3s;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
    background: #f0f2f5;
`

export const DiagramCard = styled(StyledCard)``

export const NewDiagramCard = styled(StyledCard)`
    display: flex;
    justify-content: center;
    align-items: center;
`
