import { MinusCircleOutlined } from '@ant-design/icons'
import { Form, Button, Col, Input, Row } from 'antd'
import { FC } from 'react'
import { useAppSelector } from '../../../../store/hooks'

type Props = {
    initialValue: string
}

let debounceClick = 2

const handleFocus = (
    event: any,
    initialLength: number,
    initialValue: string
) => {
    if (
        debounceClick === 2 &&
        event.target.value.substr(0, initialLength) === initialValue
    ) {
        event.target.setSelectionRange(
            initialLength + 1,
            event.target.value.length
        )
        debounceClick--
    } else if (
        debounceClick === 1 ||
        event.target.value.substr(0, initialLength) !== initialValue
    ) {
        event.target.select()
        debounceClick--
    } else {
        debounceClick = 2
    }
}

const Title: FC<Props> = ({ initialValue }) => {
    const initialLength = initialValue.length

    const user = useAppSelector((state) => state.user!)

    const splittedFullName = user.fullName.split(' ')
    const projectId = (user.lastId + 1).toString().padStart(4, '0')
    let initialFooterNumber = ''

    if (splittedFullName.length > 1) {
        initialFooterNumber = `${splittedFullName[0]
            .slice(0, 2)
            .toLocaleUpperCase()}${splittedFullName[1]
            .slice(0, 2)
            .toLocaleUpperCase()}.${projectId}`
    } else {
        initialFooterNumber = `${splittedFullName[0]
            .slice(0, 4)
            .toLocaleUpperCase()}.${projectId}`
    }
    return (
        <>
            <Form.Item
                label="Name of the project"
                name={['titleComponent', 'patentName']}
                rules={[
                    {
                        required: true,
                        message: 'Project name is required',
                    },
                ]}
                hasFeedback
            >
                <Input
                    type="text"
                    placeholder="Name project"
                    onClick={(e) => handleFocus(e, initialLength, initialValue)}
                />
            </Form.Item>
            <Form.Item
                label="Desciption of the project"
                name={['titleComponent', 'description']}
                hasFeedback
            >
                <Input
                    type="text"
                    placeholder="Describe project"
                    onClick={(e) => handleFocus(e, initialLength, initialValue)}
                />
            </Form.Item>
            <Form.Item
                name={['titleComponent', 'title']}
                rules={[
                    {
                        required: true,
                        message: 'Title is required',
                        validator: async (_, test) => {
                            if (test === `${initialValue} ...`) {
                                throw new Error()
                            }
                        },
                    },
                ]}
                hasFeedback
                initialValue={`${initialValue} ...`}
            >
                <Input
                    type="text"
                    placeholder="Name document"
                    onClick={(e) => handleFocus(e, initialLength, initialValue)}
                />
            </Form.Item>
            <Form.Item label="By">
                <Form.List
                    name={['titleComponent', 'author']}
                    rules={[
                        {
                            validator: async (_, components) => {
                                if (
                                    components === undefined ||
                                    components?.length === 0
                                )
                                    throw new Error('At least one author!')
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field) => (
                                <Row key={field.key} gutter={[12, 0]}>
                                    <Col
                                        span={22}
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'author']}
                                            fieldKey={[
                                                field.fieldKey,
                                                'author',
                                            ]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'This field is required!',
                                                },
                                            ]}
                                        >
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: '0.4rem',
                                        }}
                                        span={2}
                                    >
                                        {fields.length > 0 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{
                                                    color: '#f00',
                                                    fontSize: '1.2rem',
                                                }}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                            <Form.ErrorList errors={errors} />
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add inventor
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
            <Form.Item
                name={['titleComponent', 'footerNumber']}
                rules={[
                    {
                        required: true,
                        message: 'Footer number is required',
                        validator: async (_, value) => {
                            return value.length
                        },
                    },
                ]}
                label="Footer number of the document"
                hasFeedback
                initialValue={`${initialFooterNumber}`}
            >
                <Input type="text" placeholder="Footer number" />
            </Form.Item>
        </>
    )
}

export default Title
