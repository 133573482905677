import {
    AlignmentType,
    Document,
    Footer,
    HeadingLevel,
    LevelFormat,
    PageNumber,
    Paragraph,
    TextRun,
    UnderlineType,
} from 'docx'
import { isClaim } from '../../components/ClaimSelect/ClaimSelect'
import { IFigure } from '../../DiagramsLib/DiagramsLib.types'
import { ClaimsState } from '../../store/slices/claims/claims.types'
import { getQuestions, ValidQuestionKeys } from '../../hooks/useGerateQuestions'
import {
    CustomFormInstance,
    isCompositionForm,
    isMachineForm,
    isManufactureForm,
    isMethodsForm,
    ValueOf,
} from '../../types'
import {
    AdditionalCharacteristics,
    FormValues,
    MachineForm,
    MachineNames,
    MachineValues,
    MethodsForm,
    MethodsNames,
    SharedNames,
    Range,
    UserDefinedFormat,
    ManufactureValues,
} from '../../utils/forms/methods/types'
import { getDocxStyles } from '../../utils/getDocxStyles'

const docxStyles = getDocxStyles()

const UNITS = {
    density: 'kg/m3',
    weight: 'kg',
    length: 'm',
    thickness: 'm',
    viscosity: 'Pa*s',
    ph: '',
    brinell: 'kg_f/mm^2',
    thermalConductivity: 'W/(cm °C)',
    meltingPoint: '°C',
    specificHeat: '°C',
    from: '',
    equal: '',
    defined: '',
}

const ILLUSTRATION_TYPE = {
    schematic: 'schematic',
    scale: 'to scale',
}

const FIGURE_TYPE = {
    flow: 'flow chart',
    illustration: 'illustration of an embodiment',
}

export const generateDOCX = (
    formRef: CustomFormInstance,
    activeQuestions: ValidQuestionKeys[],
    category: keyof getQuestions,
    figures: undefined | IFigure[],
    claims: ClaimsState
) => {
    let claimsCounter = 0
    let pushSubcomponents = true
    let pushCharacteristics = true
    let pushAdditionalCharacteristic = true
    let pushFormatUser = true
    let pushSubcharacteristic = true
    let pushSubAdditionalCharacteristic = true
    let pushSubFormatUser = true
    let pushFigures = true
    let authorsCount = 0
    let arr: any[] = []

    arr.push(
        new Paragraph({
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
            spacing: {
                after: 250,
            },
            children: [
                new TextRun({
                    text: 'APPLICATION FOR UNITED STATES LETTERS PATENT',
                    color: '000000',
                    size: 16,
                }),
            ],
        })
    )

    arr.push(
        new Paragraph({
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
            spacing: {
                after: 250,
            },
            children: [
                new TextRun({
                    text: 'FOR',
                    color: '000000',
                    size: 16,
                }),
            ],
        })
    )
    let counter = 0
    let briefDrawingsCounter = 0
    activeQuestions.forEach((question) => {
        if (question === SharedNames.titleComponent) {
            const data = formRef.getFieldValue<FormValues['titleComponent']>([
                'titleComponent',
            ])
            if (data?.title) {
                arr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            after: 250,
                        },
                        children: [
                            new TextRun({
                                text: data.title.toUpperCase(),
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                        ],
                    })
                )
            }
            if (data?.author) {
                const filteredAuthors = data.author.filter(
                    (authorItem) => authorItem?.author !== undefined
                )
                if (filteredAuthors.length > 0) {
                    arr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'BY:',
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                    filteredAuthors.forEach((authorItem) => {
                        authorsCount += 1
                        arr.push(
                            new Paragraph({
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: authorItem.author,
                                        color: '000000',
                                        size: 16,
                                    }),
                                ],
                            })
                        )
                    })
                }
            }
        }
        if (question === SharedNames.drawingsDescription) {
            const newArr = []
            for (let i = 0; i <= 13; i++) {
                newArr.push(arr[i])
            }
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                    children: [
                        new TextRun({
                            text: 'BRIEF DESCRIPTION OF THE DRAWINGS',
                            color: '000000',
                            size: 16,
                            // bold: true,
                            underline: {
                                type: UnderlineType.SINGLE,
                                color: '000000',
                            },
                        }),
                    ],
                })
            )
            const figure = formRef.getFieldValue<any>([
                'drawingsDescription',
                'figure',
            ])
            if (figure) {
                interface IValue {
                    illustrationType?: keyof typeof ILLUSTRATION_TYPE
                    figureType?: keyof typeof FIGURE_TYPE
                    figureElements?: string[]
                    figureDescription: string
                }
                Object.entries(figure).forEach((entity, i: number) => {
                    const [, entityVal] = entity
                    const val = entityVal as IValue
                    const { illustrationType, figureType, figureDescription } =
                        val
                    briefDrawingsCounter++
                    const text = `depicts a ${
                        illustrationType
                            ? ILLUSTRATION_TYPE[illustrationType]
                            : '__'
                    } of ${figureType ? FIGURE_TYPE[figureType] : '__'} of ${
                        figureDescription || '__'
                    }.`
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${briefDrawingsCounter}]___ Figure ${
                                        i + 1
                                    } `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                })
            }
            for (let i = 14; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }

        if (question === SharedNames.backgroundInvention) {
            const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            let numbersText = `This application claims priority to `
            const newArr = []
            for (let i = 0; i <= 3 + authorsCount; i++) {
                newArr.push(arr[i])
            }

            const title = formRef.getFieldValue<
                FormValues['titleComponent']['title']
            >(['titleComponent', 'title'])
            const {
                notSet,
                nonprovisionalNumbers,
                provisionalNumbers,
                customCharacteristic: customFailToProvide,
            } = formRef.getFieldValue<FormValues['backgroundInvention']>([
                'backgroundInvention',
            ]) ?? {}

            if (nonprovisionalNumbers && nonprovisionalNumbers.length > 0) {
                numbersText += 'U.S. Nonprovisional Patent Application '
                nonprovisionalNumbers.forEach((item) => {
                    if (item) {
                        const nonprovisional = item.number

                        let formatedNonProvisionalDate = ''

                        const nonprovisionalDate = item.date
                        if (nonprovisionalDate) {
                            const tempDate = new Date(nonprovisionalDate)
                            formatedNonProvisionalDate = `${
                                months[tempDate.getMonth()]
                            } ${tempDate.getDate()} ${tempDate.getFullYear()}`
                        }
                        numbersText += `No.${nonprovisional}, filed on ${formatedNonProvisionalDate}, `
                    }
                })
                numbersText += `entitled "${title}" `
            }
            if (provisionalNumbers && provisionalNumbers.length > 0) {
                if (nonprovisionalNumbers) {
                    numbersText += `which claims priority to `
                }
                numbersText += 'U.S. Provisional Patent Application '
                provisionalNumbers.forEach((item) => {
                    if (item) {
                        const provisional = item.number

                        let formatedProvisionalDate = ''

                        const provisionalDate = item.date
                        if (provisionalDate) {
                            const tempDate = new Date(provisionalDate)
                            formatedProvisionalDate = `${
                                months[tempDate.getMonth()]
                            } ${tempDate.getDate()} ${tempDate.getFullYear()}`
                        }
                        numbersText += `No.${provisional}, filed on ${formatedProvisionalDate}, `
                    }
                })
                numbersText += `entitled "${title}"`
            }

            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                    children: [
                        new TextRun({
                            text: 'BACKGROUND OF THE INVENTION',
                            color: '000000',
                            size: 16,
                            // bold: true,
                            underline: {
                                type: UnderlineType.SINGLE,
                                color: '000000',
                            },
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Cross-Reference and Related Applications',
                            color: '000000',
                            size: 16,
                            underline: {
                                type: UnderlineType.SINGLE,
                                color: '000000',
                            },
                        }),
                    ],
                })
            )
            if (!notSet) {
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `[${counter}]___ `,
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                            new TextRun({
                                text: numbersText,
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
            } else {
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: 'Not applicable.',
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
            }
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Technical Field',
                            color: '000000',
                            size: 16,
                            underline: {
                                type: UnderlineType.SINGLE,
                                color: '000000',
                            },
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `This disclosure relates to ${title.toLowerCase()}`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `NESTED ${title.toLowerCase()}`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `This disclosure relates to ${title.toLowerCase()}`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `NESTED ${title.toLowerCase()}`,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                    ],
                })
            )
            if (customFailToProvide) {
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Background`,
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                        ],
                    })
                )
                customFailToProvide.forEach((item) => {
                    counter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Existing ${category} fail to provide ${item}`,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                            ],
                        })
                    )
                })
            }
            for (let i = 4 + authorsCount; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }

        if (
            question === MachineNames.detailedDescription &&
            isMachineForm(formRef, question)
        ) {
            const newArr = []
            for (let i = 0; i <= 25; i++) {
                newArr.push(arr[i])
            }
            const { customCharacteristic: failToProvide } =
                (formRef.getFieldValue([
                    SharedNames.backgroundInvention,
                ]) as FormValues['backgroundInvention']) ?? {}

            const describeDisclosure = formRef.getFieldValue<
                MachineForm['detailedDescription']
            >([MachineNames.detailedDescription, 'describe-disclosure'])
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,
                    spacing: {
                        before: 250,
                        after: 250,
                    },
                    children: [
                        new TextRun({
                            text: `DETAILED DESCRIPTION`,
                            color: '000000',
                            size: 16,
                            // bold: true,
                            underline: {
                                type: UnderlineType.SINGLE,
                                color: '000000',
                            },
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `Many examples of ${category} of the present disclosure are provided throughout this disclosure.  Modifications, including, for example, structural changes, substitutions, and rearrangements, can be made to the examples provided in this disclosure without departing from the scope of this disclosure.  Some aspects of ${category} or any combination thereof according to this disclosure will now be further described with reference being made to accompanying figure or figures which form a part hereof and which provide illustrative examples.  Unless otherwise indicated, each identical or substantially similar element in the figures uses the same reference number.  However, to avoid unnecessarily cluttering the figures and causing distraction, the reference numbers for the elements are not repeated on every figure.`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `As will be seen in greater detail throughout this disclosure, some embodiments of the ${category} described in this disclosure can be provided with one or any combination of the following, non-limiting advantages.`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `Some of the ${category} are capable of providing ${failToProvide}`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `Some of the ${category} described in this disclosure ${describeDisclosure}`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            counter++
            newArr.push(
                new Paragraph({
                    heading: HeadingLevel.HEADING_6,
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `[${counter}]___ `,
                            color: '000000',
                            size: 16,
                            // bold: true,
                        }),
                        new TextRun({
                            text: `Of course, other advantages of the ${category} described in this disclosure will be evident to a skilled person reading this disclosure.  Thus, the listing of advantages provided in this disclosure is illustrative and not limiting.  Accordingly, while some embodiments of the ${category} described herein can be provided with one of the advantages described herein or any combination of the advantages described herein, additional embodiments of the ${category} described herein are not required to provide all or any of the advantages described herein.`,
                            color: '000000',
                            size: 16,
                        }),
                    ],
                })
            )
            if (figures && pushFigures) {
                const figuresNumber = `${figures.map(
                    (element, i) => `${i + 1}`
                )}`
                pushFigures = false
                const normalizedClaims = [
                    {
                        uid: claims.id as string | undefined,
                        id: claims.id,
                        number: claims.number,
                        name: claims.name,
                    },
                ]
                claims.components.forEach((claim) => {
                    normalizedClaims.push({
                        uid: claim.uid,
                        id: claim.id,
                        number: claim.number,
                        name: claim.name,
                    })
                    if (isClaim(claim)) {
                        claim.components.forEach((component) => {
                            normalizedClaims.push({
                                uid: component.uid,
                                id: component.id,
                                number: component.number,
                                name: component.name,
                            })
                        })
                    }
                })
                interface INormalizedFigure {
                    uid: string | undefined
                    number: string
                    name: string
                }
                const normalizedFigures: INormalizedFigure[][] = []

                figures.forEach((figure) => {
                    const claimsToSearch = [...figure.claims.components]
                    if (figure.claims.claim) {
                        claimsToSearch.push(figure.claims.claim)
                    }
                    const normalizedFigure: INormalizedFigure[] = []
                    claimsToSearch.forEach((claimToSearch) => {
                        const foundClaim = normalizedClaims.find(
                            (normalizedClaim) =>
                                normalizedClaim.id === claimToSearch
                        )
                        if (foundClaim) {
                            normalizedFigure.push({
                                uid: foundClaim.uid,
                                number: foundClaim.number,
                                name: foundClaim.name,
                            })
                        }
                    })
                    if (normalizedFigure.length > 0) {
                        normalizedFigures.push(normalizedFigure)
                    }
                })
                if (normalizedFigures.length !== 0) {
                    counter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `For ease of comprehending how the ${category} can be employed, an example of a  ${category} ${normalizedClaims[0].number}   will now be described with reference to FIGURES ${figuresNumber}`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
                // WORK ON NORMALIZED FIGURES. IT'S AN ARR OF OBJECTS WITH NAME AND NUMBER OF CLAIM
                normalizedFigures.forEach((normalizedFigure, i) => {
                    counter++
                    newArr.push(
                        new Paragraph({
                            text: `[${counter}]____  `,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                        })
                    )
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: ` With reference to Figure ${
                                        i + 1
                                    }, a ${category} for obtaining patent-related user input and taking at least one step to obtain a bid for an assignment or a license is illustrated.  The ${category} can comprise several steps.`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                    normalizedFigure.forEach((element, a) => {
                        // newArr.push(
                        //     new Paragraph({
                        //         text: `[${counter}]____  Figure ${
                        //             i + 1
                        //         } contains ${normalizedFigure.map(
                        //             ({ number, name }) => ` [${name} ${number}]`
                        //         )}`,
                        //         heading: HeadingLevel.HEADING_6,
                        //         alignment: AlignmentType.LEFT,
                        //     })
                        // )
                        const characteristicComponents = element.uid
                            ? formRef.getFieldValue<any>([
                                  'characteristic',
                                  element.uid as string,
                              ])
                            : undefined
                        if (characteristicComponents) {
                            if (characteristicComponents.density) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a density of ${characteristicComponents.density.from} to ${characteristicComponents.density.to} kg/m^3`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (characteristicComponents.weight) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a weight of ${characteristicComponents.weight.from} to ${characteristicComponents.weight.to} kg.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (characteristicComponents.length) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a weight of ${characteristicComponents.length.from} to ${characteristicComponents.length.to} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (characteristicComponents.thickness) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a thickness of ${characteristicComponents.thickness.from} to ${characteristicComponents.thickness.to} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (characteristicComponents.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} wherein the ${characteristicComponents.defined.description} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }

                        const subcharacteristicComponent =
                            formRef.getFieldValue<
                                ValueOf<MachineValues['subCharacteristic']>
                            >(['subCharacteristic', element.uid as string])
                        if (subcharacteristicComponent) {
                            if (subcharacteristicComponent.density) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a density of ${subcharacteristicComponent.density.from} to ${subcharacteristicComponent.density.to} kg/m^3.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subcharacteristicComponent.weight) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a weight of ${subcharacteristicComponent.weight.from} to ${subcharacteristicComponent.weight.to} kg.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subcharacteristicComponent.length) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a weight of ${subcharacteristicComponent.length.from} to ${subcharacteristicComponent.length.to} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subcharacteristicComponent.thickness) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} having a thickness of ${subcharacteristicComponent.thickness.from} to ${subcharacteristicComponent.thickness.to} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subcharacteristicComponent.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${category} ${element.number}, the ${category} wherein the ${subcharacteristicComponent.defined.description} m.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }

                        const additionalCharacteristic =
                            formRef.getFieldValue<AdditionalCharacteristics>([
                                'additionalCharacteristic',
                                element.uid as string,
                            ])
                        if (additionalCharacteristic) {
                            if (additionalCharacteristic.thermalConductivity) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a thermal conductivity of ${
                                                    (
                                                        additionalCharacteristic.thermalConductivity as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        additionalCharacteristic.thermalConductivity as Range
                                                    ).to
                                                } W/(cm °C).`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (additionalCharacteristic.meltingPoint) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a melting point of ${
                                                    (
                                                        additionalCharacteristic.meltingPoint as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        additionalCharacteristic.meltingPoint as Range
                                                    ).to
                                                } °C.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (additionalCharacteristic.specificHeat) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a specific heat of ${
                                                    (
                                                        additionalCharacteristic.specificHeat as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        additionalCharacteristic.specificHeat as Range
                                                    ).to
                                                } J/kg°C.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (additionalCharacteristic.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${element.number}, the ${category} wherein the  ${additionalCharacteristic.defined.description} .`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }

                        const subadditionalCharacteristic =
                            formRef.getFieldValue<
                                MachineValues['subAdditionalCharacteristic']
                            >([
                                'subAdditionalCharacteristic',
                                element.uid as string,
                            ])

                        if (subadditionalCharacteristic) {
                            if (
                                subadditionalCharacteristic.thermalConductivity
                            ) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a thermal conductivity of ${
                                                    (
                                                        subadditionalCharacteristic.thermalConductivity as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        subadditionalCharacteristic.thermalConductivity as Range
                                                    ).to
                                                } W/(cm °C).`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subadditionalCharacteristic.meltingPoint) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a melting point of ${
                                                    (
                                                        subadditionalCharacteristic.meltingPoint as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        subadditionalCharacteristic.meltingPoint as Range
                                                    ).to
                                                } °C.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subadditionalCharacteristic.specificHeat) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a specific heat of ${
                                                    (
                                                        subadditionalCharacteristic.specificHeat as Range
                                                    ).from
                                                } to ${
                                                    (
                                                        subadditionalCharacteristic.specificHeat as Range
                                                    ).to
                                                } J/kg°C.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subadditionalCharacteristic.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${element.number}, the ${category} wherein the  ${subadditionalCharacteristic.defined.description} .`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }

                        const formatUserDefined = formRef.getFieldValue<
                            ValueOf<MachineForm['formatUserDefined']>
                        >(['formatUserDefined', element.uid as string])
                        if (formatUserDefined) {
                            if (formatUserDefined.from) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a ${
                                                    (
                                                        formatUserDefined.from as UserDefinedFormat
                                                    ).name
                                                } ${
                                                    (
                                                        formatUserDefined.from as UserDefinedFormat
                                                    ).maximum
                                                }  ${
                                                    (
                                                        formatUserDefined.from as UserDefinedFormat
                                                    ).units
                                                }`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (formatUserDefined.equal) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a ${
                                                    (
                                                        formatUserDefined.equal as UserDefinedFormat
                                                    ).name
                                                } ${
                                                    (
                                                        formatUserDefined.equal as UserDefinedFormat
                                                    ).maximum
                                                }  ${
                                                    (
                                                        formatUserDefined.equal as UserDefinedFormat
                                                    ).units
                                                }`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (formatUserDefined.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `[${counter}]___ `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${element.number}, the ${category} wherein the  ${formatUserDefined.defined.description}.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }

                        const subformatUserDefined = formRef.getFieldValue<
                            ValueOf<MachineForm['formatUserDefined']>
                        >(['formatUserDefined', element.uid as string])

                        if (subformatUserDefined) {
                            if (subformatUserDefined.from) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        text: `[${counter}]____  `,
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a ${
                                                    (
                                                        subformatUserDefined.from as UserDefinedFormat
                                                    ).name
                                                } ${
                                                    (
                                                        subformatUserDefined.from as UserDefinedFormat
                                                    ).maximum
                                                }  ${
                                                    (
                                                        subformatUserDefined.from as UserDefinedFormat
                                                    ).units
                                                }`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subformatUserDefined.equal) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${
                                                    element.name
                                                } comprise the ${
                                                    element.number
                                                }, the ${category} having a ${
                                                    (
                                                        subformatUserDefined.equal as UserDefinedFormat
                                                    ).name
                                                } ${
                                                    (
                                                        subformatUserDefined.equal as UserDefinedFormat
                                                    ).maximum
                                                }  ${
                                                    (
                                                        formatUserDefined.equal as UserDefinedFormat
                                                    ).units
                                                }`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            if (subformatUserDefined.defined) {
                                counter++
                                newArr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `Some ${element.name} comprise the ${element.number}, the ${category} wherein the  ${subformatUserDefined.defined.description}.`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                        }
                    })
                })
            }
            const isCheckedBoilerPlate = formRef.getFieldValue([
                'detailedDescription',
                'checked',
            ])
            if (isCheckedBoilerPlate) {
                counter++
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `[${counter}]___ `,
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                            new TextRun({
                                text: `Additional features and options for a ${category}  of this disclosure are illustrated throughout the Figures.  Nonetheless, the embodiments illustrated in the Figures are only illustrative, not limiting.  Using this disclosure for reference, a skilled person would understand that many different modifications of the expressly disclosed embodiments are possible.  For example, any ${category} feature described in this disclosure can be omitted from or added to any of the ${category} described in this disclosure to create additional ${category}.  Additionally, the order of a step in any ${category} can be swapped with another feature or otherwise reordered to create additional embodiments.`,
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
                const described = formRef.getFieldValue([
                    'detailedDescription',
                    'described',
                ])
                if (described) {
                    counter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `Moreover, any feature or combination of feature of a ${category} described in this disclosure can be repeated one, two, three or any number of times.  Since any ${category} can involve performing a second feature or features similar to a first feature previously described with reference to the ${category}, every element described with reference to the first ${category}, can be considered a first element of the kind described.  For example, ${described}`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                } else {
                    counter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `Moreover, any feature or combination of feature of a ${category} described in this disclosure can be repeated one, two, three or any number of times.  Since any ${category} can involve performing a second feature or features similar to a first feature previously described with reference to the ${category}, every element described with reference to the first ${category}, can be considered a first element of the kind described.  For example, __`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
                counter++
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `[${counter}]___ `,
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                            new TextRun({
                                text: `Additionally, because the feature or a selection of features can be performed any number of times in any order, subsequent performance of a step that has previously been performed can be given a name indicating that the features is one of several features, for example, the name can include “subsequent,” “additional,” “second,” “third,” “fourth,” “fifth,” “sixth” or any other appropriate designation relating to number or order or both number and order.`,
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
            }
            for (let i = 19; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }
        if (question === 'abstractOfTheDisclosure') {
            if (
                isMachineForm(formRef, question) &&
                isCompositionForm(formRef, question)
            ) {
                const newArr = []
                for (let i = 0; i <= 11; i++) {
                    newArr.push(arr[i])
                }
                const summary = formRef.getFieldValue<
                    FormValues['summaryOfSelectedEmbodimentsOfTheInvention']
                >('summaryOfSelectedEmbodimentsOfTheInvention')
                const test = formRef.getFieldValue<
                    MachineValues['subSummaryOfSelectedEmbodimentsOfTheInvention']
                >('subSummaryOfSelectedEmbodimentsOfTheInvention')
                const allSubComponents = formRef.getFieldValue<
                    MachineForm['primaryComponentSubComponentsList']
                >(MachineNames.primaryComponentSubComponentsList)
                newArr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: 250,
                            after: 250,
                        },
                        children: [
                            new TextRun({
                                text: `SUMMARY OF SELECTED EMBODIMENTS OF THE INVENTION`,
                                color: '000000',
                                size: 16,
                                // bold: true,
                                underline: {
                                    type: UnderlineType.SINGLE,
                                    color: '000000',
                                },
                            }),
                        ],
                    })
                )

                counter++

                if (summary && summary.length) {
                    const temp1: any[] = []
                    summary.forEach((component) => {
                        const allComponents = formRef.getFieldValue<
                            MachineForm['primaryComponents']
                        >(MachineNames.primaryComponents)
                        const foundComponent = allComponents.find(
                            (x) => x.id === component
                        )
                        if (foundComponent && foundComponent?.component) {
                            temp1.push(foundComponent?.component)
                        }
                    })
                    const temp = `${summary.map((item: any, index) => {
                        if (allSubComponents === undefined) {
                            return ''
                        }
                        return allSubComponents[item] === undefined
                            ? ''
                            : `The ${temp1[index]} comprises ${allSubComponents[
                                  item
                              ].map((item1: any) => `${item1.component}`)}`
                    })}`

                    const temp2 = temp1.join().replace(/.$/, '.')

                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `In a first aspect, the invention comprises a ${category}. The ${category} comprises ${temp2} ${temp}`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                } else {
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `[${counter}]___ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: ``,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
                for (let i = 20; i <= arr.length - 1; i++) {
                    newArr.push(arr[i])
                }
                arr = newArr
                const abstractOfTheDisclosure = formRef.getFieldValue([
                    'abstractOfTheDisclosure',
                    'defined',
                ])
                if (abstractOfTheDisclosure) {
                    arr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                            spacing: {
                                before: 250,
                                after: 250,
                            },
                            children: [
                                new TextRun({
                                    text: `ABSTRACT OF THE DISCLOSURE`,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                    underline: {
                                        type: UnderlineType.SINGLE,
                                        color: '000000',
                                    },
                                }),
                            ],
                        })
                    )
                    arr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${abstractOfTheDisclosure}`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
            }
        }
        if (
            question === MethodsNames.methodOutline &&
            isMethodsForm(formRef, question)
        ) {
            const steps = formRef.getFieldValue<MethodsForm['methodOutline']>(
                MethodsNames.methodOutline
            )
            if (steps) {
                arr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: 250,
                            after: 250,
                        },
                        children: [
                            new TextRun({
                                text: `CLAIMS`,
                                color: '000000',
                                size: 16,
                                // bold: true,
                                underline: {
                                    type: UnderlineType.SINGLE,
                                    color: '000000',
                                },
                            }),
                        ],
                    })
                )
                arr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `What is claimed is:`,
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
                claimsCounter++
                arr.push(
                    new Paragraph({
                        heading: HeadingLevel.HEADING_6,
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${claimsCounter}. `,
                                color: '000000',
                                size: 16,
                                // bold: true,
                            }),
                            new TextRun({
                                text: `A ${category} comprising:`,
                                color: '000000',
                                size: 16,
                            }),
                        ],
                    })
                )
                const filteredSteps = steps.filter((step) => step !== undefined)
                filteredSteps.forEach((step, i: number) => {
                    let lastButOne = false
                    let last = false
                    const { methodStep: name } = step
                    if (i === filteredSteps.length - 2) {
                        lastButOne = true
                    }
                    if (i === filteredSteps.length - 1) {
                        last = true
                    }
                    arr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `____ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `${name || ''}${last ? '.' : ';'} ${
                                        lastButOne ? 'and' : ''
                                    }`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                })
            }
        }
        if (
            question === 'methodOutlineDescription' &&
            isMethodsForm(formRef, question)
        ) {
            const steps =
                formRef.getFieldValue<MethodsForm['methodOutline']>(
                    'methodOutline'
                )
            const stepsDescription = formRef.getFieldValue<
                MethodsForm['methodOutlineDescription']
            >('methodOutlineDescription')

            if (stepsDescription) {
                for (let i = steps.length - 1; i >= 0; i--) {
                    const { methodStep: step } = steps[i]
                    let lastButOne = false
                    let last = false
                    if (i === steps.length - 2) {
                        lastButOne = true
                    }
                    if (i === steps.length - 1) {
                        last = true
                    }
                    if (stepsDescription[step]) {
                        if (stepsDescription[step].finalSentence) {
                            const { finalSentence } = stepsDescription[step]
                            arr[arr.length + (i - steps.length)] =
                                new Paragraph({
                                    heading: HeadingLevel.HEADING_6,
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `____ `,
                                            color: '000000',
                                            size: 16,
                                            // bold: true,
                                        }),
                                        new TextRun({
                                            text: `${finalSentence}${
                                                last ? '.' : ';'
                                            } ${lastButOne ? 'and' : ''}`,
                                            color: '000000',
                                            size: 16,
                                        }),
                                    ],
                                })
                        }
                    }
                }
            }
        }
        if (
            question === MachineNames.primaryComponents &&
            isMachineForm(formRef, question)
        ) {
            const newArr = []
            for (let i = 0; i <= 30; i++) {
                newArr.push(arr[i])
            }
            const primaryComponents = formRef.getFieldValue<
                MachineForm['primaryComponents']
            >(MachineNames.primaryComponents)

            if (primaryComponents) {
                const summaryOfSelectedEmbodimentsOfTheInvention =
                    formRef.getFieldValue<
                        FormValues['summaryOfSelectedEmbodimentsOfTheInvention']
                    >(['summaryOfSelectedEmbodimentsOfTheInvention'])
                const importantComponents = formRef.getFieldValue<
                    ManufactureValues['importantComponents']
                >(['importantComponents'])
                const filteredComponents = primaryComponents.filter((item) => {
                    if (
                        summaryOfSelectedEmbodimentsOfTheInvention &&
                        item !== undefined
                    ) {
                        return summaryOfSelectedEmbodimentsOfTheInvention.includes(
                            item.id ?? ''
                        )
                    }
                    if (importantComponents && item !== undefined) {
                        return importantComponents.includes(
                            item.component ?? ''
                        )
                    }
                    return item !== undefined
                })
                if (filteredComponents.length > 0) {
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                            spacing: {
                                before: 250,
                                after: 250,
                            },
                            children: [
                                new TextRun({
                                    text: `CLAIMS`,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                    underline: {
                                        type: UnderlineType.SINGLE,
                                        color: '000000',
                                    },
                                }),
                            ],
                        })
                    )
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `What is claimed is:`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                    claimsCounter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${claimsCounter}. `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `A ${category} comprising:`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
                filteredComponents.forEach((item, i: number) => {
                    let lastButOne = false
                    let last = false
                    const { indefiniteArticle, component } = item
                    if (i === filteredComponents.length - 2) {
                        lastButOne = true
                    }
                    if (i === filteredComponents.length - 1) {
                        last = true
                    }
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `____ `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `${indefiniteArticle || ''} ${
                                        component || ''
                                    }${last ? '.' : ';'} ${
                                        lastButOne ? 'and' : ''
                                    }`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                })
                let text = ''
                const commonName = formRef.getFieldValue(
                    SharedNames.commonName
                ) as FormValues['commonName']

                if (commonName.notSet) {
                    text = 'no common name'
                } else {
                    const { indefiniteArticle: article, commonName: name } =
                        commonName
                    text = `${article} ${name}`
                }

                if (filteredComponents.length > 0) {
                    claimsCounter++
                    newArr.push(
                        new Paragraph({
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${claimsCounter}. `,
                                    color: '000000',
                                    size: 16,
                                    // bold: true,
                                }),
                                new TextRun({
                                    text: `The ${category} of claim ${
                                        claimsCounter - 1
                                    }, the ${category} being ${text}.`,
                                    color: '000000',
                                    size: 16,
                                }),
                            ],
                        })
                    )
                }
            }
            for (let i = 20; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }
        if (
            question === MachineNames.primaryComponentSubComponentsList &&
            pushSubcomponents &&
            isMachineForm(formRef, question)
        ) {
            pushSubcomponents = false
            const list =
                formRef.getFieldValue<MachineForm['mainSubcomponents']>(
                    MachineNames.mainSubcomponents
                ) ?? []
            list.forEach((mainComponent) => {
                const allComponents = formRef.getFieldValue<
                    MachineForm['primaryComponents']
                >(MachineNames.primaryComponents)
                const componentData = allComponents.find(
                    (x) => x.id === mainComponent
                )

                if (componentData) {
                    if (
                        isMachineForm(formRef, question) ||
                        isMethodsForm(formRef, question) ||
                        isManufactureForm(formRef, question)
                    ) {
                        const subComponents = formRef.getFieldValue([
                            MachineNames.primaryComponentSubComponentsList,
                            mainComponent,
                        ]) as ValueOf<
                            MachineForm['primaryComponentSubComponentsList']
                        >

                        if (subComponents) {
                            claimsCounter++
                            const filteredSubComponents = subComponents.filter(
                                (item) => item !== undefined
                            )
                            if (filteredSubComponents.length > 0) {
                                arr.push(
                                    new Paragraph({
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${claimsCounter}. `,
                                                color: '000000',
                                                size: 16,
                                                // bold: true,
                                            }),
                                            new TextRun({
                                                text: `The ${category} of claim 1, the ${componentData.component!} comprising:`,
                                                color: '000000',
                                                size: 16,
                                            }),
                                        ],
                                    })
                                )
                            }
                            filteredSubComponents.forEach(
                                (subComponent, i: number) => {
                                    let lastButOne = false
                                    let last = false
                                    const { component } = subComponent
                                    const indefiniteArticle =
                                        subComponent.indefiniteArticle ?? ''
                                    if (i === subComponents.length - 2) {
                                        lastButOne = true
                                    }
                                    if (i === subComponents.length - 1) {
                                        last = true
                                    }
                                    arr.push(
                                        new Paragraph({
                                            heading: HeadingLevel.HEADING_6,
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `____ `,
                                                    color: '000000',
                                                    size: 16,
                                                    // bold: true,
                                                }),
                                                new TextRun({
                                                    text: `${
                                                        indefiniteArticle || ''
                                                    } ${component || ''}${
                                                        last ? '.' : ';'
                                                    } ${
                                                        lastButOne ? 'and' : ''
                                                    }`,
                                                    color: '000000',
                                                    size: 16,
                                                }),
                                            ],
                                        })
                                    )
                                    if (i === subComponents.length - 1) {
                                        last = true
                                    }
                                }
                            )
                        }
                    }
                }
            })
        }
        if (
            question === MachineNames.characteristic &&
            pushCharacteristics &&
            isMachineForm(formRef, question)
        ) {
            const newArr = []
            for (let i = 0; i <= 30; i++) {
                newArr.push(arr[i])
            }
            pushCharacteristics = false
            const characteristicComponents = formRef.getFieldValue(
                MachineNames.componentsForCharacteristics
            ) as MachineValues['componentsForCharacteristics']
            characteristicComponents.forEach((component: string) => {
                const allComponents = formRef.getFieldValue<
                    MachineForm['primaryComponents']
                >(MachineNames.primaryComponents)
                const foundComponent = allComponents.find(
                    (x) => x.id === component
                )
                if (foundComponent) {
                    const characteristic = formRef.getFieldValue([
                        MachineNames.characteristic,
                        component,
                    ]) as ValueOf<MachineValues['characteristic']>
                    if (characteristic) {
                        Object.entries(characteristic).forEach((entity) => {
                            const [entityKey, val] = entity
                            const key = entityKey as keyof typeof UNITS
                            if (val) {
                                claimsCounter++
                                if (typeof val === 'object' && val !== null) {
                                    if (key === 'defined') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category}  of claim 1, wherein the ${
                                                            'description' in val
                                                                ? val.description
                                                                : '__'
                                                        } .`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    } else {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, the ${foundComponent.component!} having a ${key} from ${
                                                            'from' in val
                                                                ? val.from
                                                                : '__'
                                                        } to ${
                                                            'to' in val
                                                                ? val.to
                                                                : '__'
                                                        } ${UNITS[key]}.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                } else if (
                                    typeof val !== 'object' &&
                                    val === null
                                ) {
                                    if (key === 'defined') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ___.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    } else {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, the ${foundComponent.component!} having a ${key} from __ to __ ${
                                                            UNITS[key]
                                                        }.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                }
                            }
                        })
                    }
                }
            })
            for (let i = 20; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }
        if (question === 'characteristicCompositon' && pushCharacteristics) {
            let letAll = false
            const importantCharacteristic = (formRef as any).getFieldValue([
                'importantCharacteristic',
            ])
            if (!importantCharacteristic) {
                letAll = true
            }
            const characteristic = (formRef as any).getFieldValue(
                'characteristicCompositon'
            )
            if (characteristic) {
                Object.entries(characteristic).forEach((entity: any) => {
                    const [entityKey, val] = entity
                    const key = entityKey as keyof typeof UNITS
                    const minAcc = val['min-acceptable']
                    const maxAcc = val['max-acceptable']
                    const acc = minAcc || maxAcc
                    const minPref = val['min-preferable']
                    const maxPref = val['max-preferable']
                    const pref = minPref || maxPref
                    let text = ''
                    const commonName =
                        formRef.getFieldValue<FormValues['commonName']>(
                            'commonName'
                        )
                    if (commonName.notSet) text = 'COMMON NAME'
                    else {
                        const { commonName: name } = commonName
                        text = name
                    }
                    claimsCounter++
                    let additionalText = ''
                    let pushParagraph = false
                    Object.entries(val).forEach((item: any) => {
                        const [, value] = item
                        if (
                            value &&
                            (importantCharacteristic?.includes(key) || letAll)
                        ) {
                            pushParagraph = true
                        }
                    })
                    if (pushParagraph) {
                        if (key === 'brinell') {
                            additionalText = `Measured using ${
                                val.condition || '__'
                            }.`
                        }
                        if (key === 'viscosity') {
                            additionalText = `Pa*s at temperature equals ${
                                val.temperature || '__'
                            } [C] and Pa*s at shear rate is equal to ${
                                val.rate || '__'
                            } [s^-1].`
                        }
                        arr.push(
                            new Paragraph({
                                heading: HeadingLevel.HEADING_6,
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${claimsCounter}. `,
                                        color: '000000',
                                        size: 16,
                                        // bold: true,
                                    }),
                                    new TextRun({
                                        text: `The ${text} having a ${
                                            acc
                                                ? `acceptable ${key} from ${
                                                      minAcc || '__'
                                                  } to ${maxAcc || '__'} ${
                                                      UNITS[key]
                                                  }`
                                                : ''
                                        } 
                                        ${acc && pref ? 'and' : ''} 
                                        ${
                                            pref
                                                ? `preferable ${key} from ${
                                                      minPref || '__'
                                                  } to ${maxPref || '__'} ${
                                                      UNITS[key]
                                                  }. ${additionalText}`
                                                : ''
                                        }`,
                                        color: '000000',
                                        size: 16,
                                    }),
                                ],
                            })
                        )
                    }
                })
            }
            const customCharacteristic = (formRef as any).getFieldValue(
                'characteristicCustom'
            )
            if (customCharacteristic) {
                Object.entries(customCharacteristic).forEach((entity: any) => {
                    const [entityKey, val] = entity
                    const key = entityKey
                    const minAcc = val['min-acceptable']
                    const maxAcc = val['max-acceptable']
                    const acc = minAcc || maxAcc
                    const minPref = val['min-preferable']
                    const maxPref = val['max-preferable']
                    const pref = minPref || maxPref
                    const units = val.unitSI
                    let text = ''
                    const commonName =
                        formRef.getFieldValue<FormValues['commonName']>(
                            'commonName'
                        )
                    if (commonName.notSet) text = 'COMMON NAME'
                    else {
                        const { commonName: name } = commonName
                        text = name
                    }
                    claimsCounter++
                    let pushParagraph = false
                    Object.entries(val).forEach((item: any) => {
                        const [, value] = item
                        if (
                            value &&
                            (importantCharacteristic?.includes(key) || letAll)
                        ) {
                            pushParagraph = true
                        }
                    })
                    if (pushParagraph) {
                        arr.push(
                            new Paragraph({
                                heading: HeadingLevel.HEADING_6,
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${claimsCounter}. `,
                                        color: '000000',
                                        size: 16,
                                        // bold: true,
                                    }),
                                    new TextRun({
                                        text: `The ${text} having a ${
                                            acc
                                                ? `acceptable ${key} from ${
                                                      minAcc || '__'
                                                  } to ${
                                                      maxAcc || '__'
                                                  } ${units}`
                                                : ''
                                        } 
                                        ${acc && pref ? 'and' : ''} 
                                        ${
                                            pref
                                                ? `preferable ${key} from ${
                                                      minPref || '__'
                                                  } to ${
                                                      maxPref || '__'
                                                  } ${units}`
                                                : ''
                                        }`,
                                        color: '000000',
                                        size: 16,
                                    }),
                                ],
                            })
                        )
                    }
                })
            }
        }
        if (
            question === 'additionalCharacteristic' &&
            pushAdditionalCharacteristic &&
            isMachineForm(formRef, question)
        ) {
            pushAdditionalCharacteristic = false
            const characteristicComponents = formRef.getFieldValue<
                MachineValues['componentsForCharacteristics']
            >('componentsForCharacteristics')
            const newArr = []
            for (let i = 0; i <= 30; i++) {
                newArr.push(arr[i])
            }
            characteristicComponents.forEach((component: string) => {
                const allComponents = formRef.getFieldValue<
                    MachineForm['primaryComponents']
                >(MachineNames.primaryComponents)
                const foundComponent = allComponents.find(
                    (x) => x.id === component
                )
                if (foundComponent) {
                    const characteristic = formRef.getFieldValue<
                        ValueOf<MachineValues['additionalCharacteristic']>
                    >(['additionalCharacteristic', component])
                    if (characteristic) {
                        Object.entries(characteristic).forEach((entity) => {
                            const [entityKey, val] = entity
                            const key = entityKey as keyof typeof UNITS
                            if (val) {
                                claimsCounter++
                                if (typeof val === 'object' && val !== null) {
                                    if ('description' in val) {
                                        if (key === 'defined') {
                                            arr.push(
                                                new Paragraph({
                                                    heading:
                                                        HeadingLevel.HEADING_6,
                                                    alignment:
                                                        AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({
                                                            text: `${claimsCounter}. `,
                                                            color: '000000',
                                                            size: 16,
                                                            // bold: true,
                                                        }),
                                                        new TextRun({
                                                            text: `The ${category} of claim 1, wherein the ${
                                                                'description' in
                                                                val
                                                                    ? val.description
                                                                    : '__'
                                                            } `,
                                                            color: '000000',
                                                            size: 16,
                                                        }),
                                                    ],
                                                })
                                            )
                                        }
                                    } else {
                                        if (key === 'thermalConductivity') {
                                            arr.push(
                                                new Paragraph({
                                                    heading:
                                                        HeadingLevel.HEADING_6,
                                                    alignment:
                                                        AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({
                                                            text: `${claimsCounter}. `,
                                                            color: '000000',
                                                            size: 16,
                                                            // bold: true,
                                                        }),
                                                        new TextRun({
                                                            text: `The ${category} of claim 1, the ${
                                                                foundComponent.component
                                                            } having a thermal conductivity from ${
                                                                val.from || '__'
                                                            } to ${
                                                                val.to || '__'
                                                            } ${UNITS[key]}.`,
                                                            color: '000000',
                                                            size: 16,
                                                        }),
                                                    ],
                                                })
                                            )
                                        }
                                        if (key === 'meltingPoint') {
                                            arr.push(
                                                new Paragraph({
                                                    heading:
                                                        HeadingLevel.HEADING_6,
                                                    alignment:
                                                        AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({
                                                            text: `${claimsCounter}. `,
                                                            color: '000000',
                                                            size: 16,
                                                            // bold: true,
                                                        }),
                                                        new TextRun({
                                                            text: `The ${category} of claim 1, the ${
                                                                foundComponent.component
                                                            } having a melting point from ${
                                                                val.from || '__'
                                                            } to ${
                                                                val.to || '__'
                                                            } ${UNITS[key]}.`,
                                                            color: '000000',
                                                            size: 16,
                                                        }),
                                                    ],
                                                })
                                            )
                                        }
                                        if (key === 'specificHeat') {
                                            arr.push(
                                                new Paragraph({
                                                    heading:
                                                        HeadingLevel.HEADING_6,
                                                    alignment:
                                                        AlignmentType.LEFT,
                                                    children: [
                                                        new TextRun({
                                                            text: `${claimsCounter}. `,
                                                            color: '000000',
                                                            size: 16,
                                                            // bold: true,
                                                        }),
                                                        new TextRun({
                                                            text: `The ${category} of claim 1, the ${
                                                                foundComponent.component
                                                            } having a specific heat from ${
                                                                val.from || '__'
                                                            } to ${
                                                                val.to || '__'
                                                            } ${UNITS[key]}.`,
                                                            color: '000000',
                                                            size: 16,
                                                        }),
                                                    ],
                                                })
                                            )
                                        }
                                    }
                                } else if (
                                    typeof val !== 'object' &&
                                    val === null
                                ) {
                                    if (key === 'thermalConductivity') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, the ${foundComponent.component} having a thermal conductivity from __ to __ ${UNITS[key]}.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'meltingPoint') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, the ${foundComponent.component} having a melting point from __ to __ ${UNITS[key]}.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'specificHeat') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, the ${foundComponent.component} having a specific heat from __ to __ ${UNITS[key]}.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'defined') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ___`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                }
                            }
                        })
                    }
                }
            })
            for (let i = 20; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }
        if (
            question === 'formatUserDefined' &&
            pushFormatUser &&
            isMachineForm(formRef, question)
        ) {
            const newArr = []
            for (let i = 0; i <= 30; i++) {
                newArr.push(arr[i])
            }
            pushFormatUser = false
            const characteristicComponents = formRef.getFieldValue<
                MachineValues['componentsForCharacteristics']
            >('componentsForCharacteristics')

            characteristicComponents.forEach((component: string) => {
                const characteristic = formRef.getFieldValue<
                    ValueOf<MachineValues['formatUserDefined']>
                >(['formatUserDefined', component])
                if (characteristic) {
                    Object.entries(characteristic).forEach((entity: any) => {
                        const [entityKey, val] = entity
                        const key = entityKey
                        const allComponents = formRef.getFieldValue<
                            MachineForm['primaryComponents']
                        >(MachineNames.primaryComponents)
                        const foundComponent = allComponents.find(
                            (x) => x.id === component
                        )
                        if (foundComponent) {
                            if (val) {
                                claimsCounter++
                                if (typeof val === 'object' && val !== null) {
                                    if (key === 'from') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ${foundComponent.component!} having a ${
                                                            val.name || '__'
                                                        } from ${
                                                            val.minimum || '__'
                                                        } to ${
                                                            val.maximum || '__'
                                                        } ${val.units || ''} .`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'equal') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ${
                                                            val.name || '__'
                                                        } of the ${foundComponent.component!} is equal to 
                                                             from ${
                                                                 val.minimum ||
                                                                 '__'
                                                             } to ${
                                                            val.maximum || '__'
                                                        } ${val.units || ''} .`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'defined') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ${
                                                            val.description ||
                                                            '___'
                                                        }
                                                         .`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                } else if (
                                    typeof val !== 'object' &&
                                    val === null
                                ) {
                                    if (key === 'body') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ___ having a ____ from __ to __.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'equal') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ___ of the ____ is equal to from __ to __.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                    if (key === 'defined') {
                                        arr.push(
                                            new Paragraph({
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${claimsCounter}. `,
                                                        color: '000000',
                                                        size: 16,
                                                        // bold: true,
                                                    }),
                                                    new TextRun({
                                                        text: `The ${category} of claim 1, wherein the ___.`,
                                                        color: '000000',
                                                        size: 16,
                                                    }),
                                                ],
                                            })
                                        )
                                    }
                                }
                            }
                        }
                    })
                }
            })
            for (let i = 20; i <= arr.length - 1; i++) {
                newArr.push(arr[i])
            }
            arr = newArr
        }
        if (
            question === 'subCharacteristic' &&
            pushSubcharacteristic &&
            isMachineForm(formRef, question)
        ) {
            pushSubcharacteristic = false
            const subcomponentsForCharacteristics = formRef.getFieldValue<
                MachineValues['subcomponentsForCharacteristics']
            >('subcomponentsForCharacteristics')

            const componentsForCharacteristics = formRef.getFieldValue<
                MachineValues['componentsForCharacteristics']
            >('componentsForCharacteristics')

            const subcharacteristicComponent = formRef.getFieldValue<
                ValueOf<MachineValues['primaryComponentSubComponentsList']>
            >(['primaryComponentSubComponentsList'])

            componentsForCharacteristics.forEach((component: string) => {
                if (subcomponentsForCharacteristics[component]) {
                    subcomponentsForCharacteristics[component].forEach(
                        (subcomponent: string) => {
                            const characteristicComponents = Array.isArray(
                                subcharacteristicComponent
                            )
                                ? subcharacteristicComponent
                                : (subcharacteristicComponent as any)[component]

                            const foundComponent =
                                characteristicComponents.find(
                                    (x: any) => x.id === subcomponent
                                )
                            if (foundComponent) {
                                const characteristic = formRef.getFieldValue<
                                    ValueOf<MachineValues['subCharacteristic']>
                                >(['subCharacteristic', subcomponent])
                                if (characteristic) {
                                    Object.entries(characteristic).forEach(
                                        (entity: any) => {
                                            const [entityKey, val] = entity
                                            const key =
                                                entityKey as keyof typeof UNITS
                                            if (val) {
                                                claimsCounter++
                                                if (
                                                    typeof val === 'object' &&
                                                    val !== null
                                                ) {
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category}  of claim 1, wherein the ${
                                                                                val.description ||
                                                                                '__'
                                                                            } .`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    } else {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${
                                                                                foundComponent.component
                                                                            } having a ${key} from ${
                                                                                val.from ||
                                                                                '__'
                                                                            } to ${
                                                                                val.to ||
                                                                                '__'
                                                                            } ${
                                                                                UNITS[
                                                                                    key
                                                                                ]
                                                                            }.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                } else if (
                                                    typeof val !== 'object' &&
                                                    val === null
                                                ) {
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ___.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    } else {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${foundComponent.component} having a ${key} from __ to __ ${UNITS[key]}.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        }
                    )
                }
            })
        }
        if (
            question === 'subAdditionalCharacteristic' &&
            pushSubAdditionalCharacteristic &&
            isMachineForm(formRef, question)
        ) {
            pushSubAdditionalCharacteristic = false
            const subcomponentsForCharacteristics = formRef.getFieldValue<
                MachineValues['subcomponentsForCharacteristics']
            >('subcomponentsForCharacteristics')

            const componentsForCharacteristics = formRef.getFieldValue<
                MachineValues['componentsForCharacteristics']
            >('componentsForCharacteristics')

            const subcharacteristicComponent = formRef.getFieldValue<
                ValueOf<MachineValues['primaryComponentSubComponentsList']>
            >(['primaryComponentSubComponentsList'])

            componentsForCharacteristics.forEach((component: string) => {
                if (subcomponentsForCharacteristics[component] != null) {
                    subcomponentsForCharacteristics[component].forEach(
                        (subcomponent: string) => {
                            const characteristicComponents = Array.isArray(
                                subcharacteristicComponent
                            )
                                ? subcharacteristicComponent
                                : (subcharacteristicComponent as any)[component]

                            const foundComponent =
                                characteristicComponents.find(
                                    (x: any) => x.id === subcomponent
                                )
                            if (foundComponent) {
                                const characteristic = formRef.getFieldValue<
                                    MachineValues['subAdditionalCharacteristic']
                                >(['subAdditionalCharacteristic', subcomponent])
                                if (characteristic) {
                                    Object.entries(characteristic).forEach(
                                        (entity: any) => {
                                            const [entityKey, val] = entity
                                            const key =
                                                entityKey as keyof typeof UNITS
                                            if (val) {
                                                claimsCounter++
                                                if (
                                                    typeof val === 'object' &&
                                                    val !== null
                                                ) {
                                                    if (
                                                        key ===
                                                        'thermalConductivity'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${
                                                                                foundComponent.component
                                                                            } having a thermal conductivity from ${
                                                                                val.from ||
                                                                                '__'
                                                                            } to ${
                                                                                val.to ||
                                                                                '__'
                                                                            } ${
                                                                                UNITS[
                                                                                    key
                                                                                ]
                                                                            }.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (
                                                        key === 'meltingPoint'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${
                                                                                foundComponent.component
                                                                            } having a melting point from ${
                                                                                val.from ||
                                                                                '__'
                                                                            } to ${
                                                                                val.to ||
                                                                                '__'
                                                                            } ${
                                                                                UNITS[
                                                                                    key
                                                                                ]
                                                                            }.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (
                                                        key === 'specificHeat'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${
                                                                                foundComponent.component
                                                                            } having a specific heat from ${
                                                                                val.from ||
                                                                                '__'
                                                                            } to ${
                                                                                val.to ||
                                                                                '__'
                                                                            } ${
                                                                                UNITS[
                                                                                    key
                                                                                ]
                                                                            }.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ${
                                                                                val.description ||
                                                                                '__'
                                                                            } `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                } else if (
                                                    typeof val !== 'object' &&
                                                    val === null
                                                ) {
                                                    if (
                                                        key ===
                                                        'thermalConductivity'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${foundComponent.component} having a thermal conductivity from __ to __ ${UNITS[key]}.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (
                                                        key === 'meltingPoint'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${foundComponent.component} having a melting point from __ to __ ${UNITS[key]}.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (
                                                        key === 'specificHeat'
                                                    ) {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, the ${foundComponent.component} having a specific heat from __ to __ ${UNITS[key]}.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ___`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        }
                    )
                }
            })
        }
        if (question === 'subFormatUserDefined' && pushSubFormatUser) {
            const typedFormRef = formRef as CustomFormInstance<MachineForm>
            pushSubFormatUser = false
            const subcomponentsForCharacteristics = typedFormRef.getFieldValue<
                MachineForm['subcomponentsForCharacteristics']
            >('subcomponentsForCharacteristics')

            const componentsForCharacteristics = typedFormRef.getFieldValue<
                MachineForm['componentsForCharacteristics']
            >('componentsForCharacteristics')

            const subcharacteristicComponent = formRef.getFieldValue<
                ValueOf<MachineValues['primaryComponentSubComponentsList']>
            >(['primaryComponentSubComponentsList'])

            componentsForCharacteristics.forEach((component: string) => {
                if (subcomponentsForCharacteristics[component] != null) {
                    subcomponentsForCharacteristics[component].forEach(
                        (subcomponent: string) => {
                            const characteristicComponents = Array.isArray(
                                subcharacteristicComponent
                            )
                                ? subcharacteristicComponent
                                : (subcharacteristicComponent as any)[component]

                            const foundComponent =
                                characteristicComponents.find(
                                    (x: any) => x.id === subcomponent
                                )
                            if (foundComponent) {
                                const characteristic =
                                    typedFormRef.getFieldValue<
                                        MachineForm['subFormatUserDefined']
                                    >(['subFormatUserDefined', subcomponent])
                                if (characteristic) {
                                    Object.entries(characteristic).forEach(
                                        (entity: any) => {
                                            const [entityKey, val] = entity
                                            const key = entityKey
                                            if (val) {
                                                claimsCounter++
                                                if (
                                                    typeof val === 'object' &&
                                                    val !== null
                                                ) {
                                                    if (key === 'from') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ${
                                                                                foundComponent.component
                                                                            } having a ${
                                                                                val.name ||
                                                                                '__'
                                                                            } from ${
                                                                                val.minimum ||
                                                                                '__'
                                                                            } to ${
                                                                                val.maximum ||
                                                                                '__'
                                                                            } ${
                                                                                val.units ||
                                                                                ''
                                                                            } .`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'equal') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ${
                                                                                val.name ||
                                                                                '__'
                                                                            } of the ${
                                                                                foundComponent.component
                                                                            } is equal to 
                                                                 from ${
                                                                     val.minimum ||
                                                                     '__'
                                                                 } to ${
                                                                                val.maximum ||
                                                                                '__'
                                                                            } ${
                                                                                val.units ||
                                                                                ''
                                                                            } .`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ${
                                                                                val.description ||
                                                                                '___'
                                                                            }
                                                             .`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                } else if (
                                                    typeof val !== 'object' &&
                                                    val === null
                                                ) {
                                                    if (key === 'body') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ___ having a ____ from __ to __.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'equal') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ___ of the ____ is equal to from __ to __.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                    if (key === 'defined') {
                                                        arr.push(
                                                            new Paragraph({
                                                                heading:
                                                                    HeadingLevel.HEADING_6,
                                                                alignment:
                                                                    AlignmentType.LEFT,
                                                                children: [
                                                                    new TextRun(
                                                                        {
                                                                            text: `${claimsCounter}. `,
                                                                            color: '000000',
                                                                            size: 16,
                                                                            // bold: true,
                                                                        }
                                                                    ),
                                                                    new TextRun(
                                                                        {
                                                                            text: `The ${category} of claim 1, wherein the ___.`,
                                                                            color: '000000',
                                                                            size: 16,
                                                                        }
                                                                    ),
                                                                ],
                                                            })
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        }
                    )
                }
            })
        }
        if (question === 'importantComponentsConnections') {
            const importantComponentsConnections = formRef.getFieldValue<
                MachineForm['importantComponentsConnections']
            >(['importantComponentsConnections'])
            if (importantComponentsConnections != null) {
                Object.entries(importantComponentsConnections).forEach(
                    (entry: any) => {
                        const [key, val] = entry
                        let finalText: string | undefined
                        claimsCounter++
                        const notSet = val?.notSet
                        const firstComponent = val?.['1stComponent']
                        const secondComponent = val?.['2ndComponent']
                        if (notSet) {
                            const firstPart = val?.['1stPart']
                            const secondPart = val?.['2ndPart']
                            const thirdPart = val?.['3rdPart']
                            if (
                                firstPart &&
                                firstComponent &&
                                secondPart &&
                                secondComponent &&
                                thirdPart
                            ) {
                                finalText = `${firstPart} the ${firstComponent} ${secondPart} the ${secondComponent} ${thirdPart}`
                            }
                        } else {
                            const connectionType = val?.connectionType
                            if (
                                firstComponent &&
                                connectionType &&
                                secondComponent
                            ) {
                                finalText = `The ${firstComponent} is ${connectionType.toLowerCase()} the ${secondComponent}`
                            }
                        }
                        if (finalText) {
                            arr.push(
                                new Paragraph({
                                    heading: HeadingLevel.HEADING_6,
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${claimsCounter}. `,
                                            color: '000000',
                                            size: 16,
                                            // bold: true,
                                        }),
                                        new TextRun({
                                            text: `${finalText}.`,
                                            color: '000000',
                                            size: 16,
                                        }),
                                    ],
                                })
                            )
                        }
                    }
                )
            }
        }
        if (question === 'functionalConnections') {
            const functionalConnections = formRef.getFieldValue<
                MachineForm['functionalConnections']
            >(['functionalConnections'])
            if (functionalConnections != null) {
                Object.entries(functionalConnections).forEach((entry: any) => {
                    const [key, val] = entry
                    let finalText: string | undefined
                    claimsCounter++
                    const notSet = val?.notSet
                    const firstComponent = val?.['1stComponent']
                    const secondComponent = val?.['2ndComponent']
                    if (notSet) {
                        const firstPart = val?.['1stPart']
                        const secondPart = val?.['2ndPart']
                        const thirdPart = val?.['3rdPart']
                        if (
                            firstPart &&
                            firstComponent &&
                            secondPart &&
                            secondComponent &&
                            thirdPart
                        ) {
                            finalText = `${firstPart} the ${firstComponent} ${secondPart} the ${secondComponent} ${thirdPart}`
                        }
                    } else if (firstComponent && secondComponent) {
                        finalText = `The ${firstComponent} is functionally connected with the ${secondComponent}`
                    }
                    if (finalText) {
                        arr.push(
                            new Paragraph({
                                heading: HeadingLevel.HEADING_6,
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${claimsCounter}. `,
                                        color: '000000',
                                        size: 16,
                                        // bold: true,
                                    }),
                                    new TextRun({
                                        text: `${finalText}.`,
                                        color: '000000',
                                        size: 16,
                                    }),
                                ],
                            })
                        )
                    }
                })
            }
        }
        if (question === 'dependantComponentsConnections') {
            const dependantComponentsConnections = formRef.getFieldValue<
                MachineForm['dependantComponentsConnections']
            >(['dependantComponentsConnections'])
            if (dependantComponentsConnections != null) {
                Object.entries(dependantComponentsConnections).forEach(
                    (entry: any) => {
                        const [key, val] = entry
                        let finalText: string | undefined
                        claimsCounter++
                        const notSet = val?.notSet
                        const firstComponent = val?.['1stComponent']
                        const secondComponent = val?.['2ndComponent']
                        if (notSet) {
                            const firstPart = val?.['1stPart']
                            const secondPart = val?.['2ndPart']
                            const thirdPart = val?.['3rdPart']
                            if (
                                firstPart &&
                                firstComponent &&
                                secondPart &&
                                secondComponent &&
                                thirdPart
                            ) {
                                finalText = `${firstPart} the ${firstComponent} ${secondPart} the ${secondComponent} ${thirdPart}`
                            }
                        } else {
                            const connectionType =
                                val?.connectionType?.toLowerCase()
                            if (
                                firstComponent &&
                                connectionType &&
                                secondComponent
                            ) {
                                finalText = `The ${firstComponent} is ${connectionType} the ${secondComponent}`
                            }
                        }
                        if (finalText) {
                            arr.push(
                                new Paragraph({
                                    heading: HeadingLevel.HEADING_6,
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${claimsCounter}. `,
                                            color: '000000',
                                            size: 16,
                                            // bold: true,
                                        }),
                                        new TextRun({
                                            text: `${finalText}.`,
                                            color: '000000',
                                            size: 16,
                                        }),
                                    ],
                                })
                            )
                        }
                    }
                )
            }
        }
        if (question === 'componentWeights') {
            const typedFormRef = formRef as any
            const weightImportance = typedFormRef.getFieldValue([
                'componentWeights',
                'weightImportance',
            ])
            const weights = typedFormRef.getFieldValue('componentWeights')
            if (weights) {
                Object.entries(weights).forEach((entry: any) => {
                    const [key, val] = entry
                    let pushWeight = false
                    Object.entries(val).forEach((valEntry: any) => {
                        const [entryKey, entryVal] = valEntry
                        if (entryVal) {
                            pushWeight = true
                        }
                    })
                    if (pushWeight) {
                        const minAcc = val['min-acceptable-weight']
                        const maxAcc = val['max-acceptable-weight']
                        const ifAcc = val['acceptable-weight-importance']
                        const acc =
                            (minAcc || maxAcc) && (ifAcc || !weightImportance)
                        const minPref = val['min-preferable-weight']
                        const maxPref = val['max-preferable-weight']
                        const ifPref = val['preferable-weight-importance']
                        const pref =
                            (minPref || maxPref) &&
                            (ifPref || !weightImportance)
                        claimsCounter++
                        if (ifAcc || ifPref) {
                            arr.push(
                                new Paragraph({
                                    heading: HeadingLevel.HEADING_6,
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${claimsCounter}. `,
                                            color: '000000',
                                            size: 16,
                                            // bold: true,
                                        }),
                                        new TextRun({
                                            text: `The ${category} of claim 1, the ${key} having a ${
                                                acc
                                                    ? `acceptable percentage of weight from ${
                                                          minAcc || '__'
                                                      }% to ${maxAcc || '__ '}%`
                                                    : ''
                                            } 
                                        ${acc && pref ? 'and' : ''}
                                        ${
                                            pref
                                                ? `preferable percentage of weight from ${
                                                      minPref || '__'
                                                  }% to ${maxPref || '__'}%`
                                                : ''
                                        }.`,
                                            color: '000000',
                                            size: 16,
                                        }),
                                    ],
                                })
                            )
                        }
                    }
                })
            }
        }
        if (question === 'characteristicExplanation') {
            const typedFormRef = formRef as any
            const characteristicExplanation = typedFormRef.getFieldValue(
                'characteristicExplanation'
            )
            if (characteristicExplanation) {
                Object.entries(characteristicExplanation).forEach(
                    (entry: any) => {
                        const [key, val] = entry
                        if (val) {
                            claimsCounter++

                            arr.push(
                                new Paragraph({
                                    heading: HeadingLevel.HEADING_6,
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${claimsCounter}. `,
                                            color: '000000',
                                            size: 16,
                                            // bold: true,
                                        }),
                                        new TextRun({
                                            text: `Provided ${key} characteristic being advantagous because of ${val}`,
                                            color: '000000',
                                            size: 16,
                                        }),
                                    ],
                                })
                            )
                        }
                    }
                )
            }
        }
    })

    const docx = new Document({
        sections: [
            {
                properties: {},
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        size: 16,
                                        color: '000000',
                                        children: [
                                            'United States Patent Application Page ',
                                            PageNumber.CURRENT,
                                            ' of ',
                                            PageNumber.TOTAL_PAGES,
                                            ' ',
                                            formRef.getFieldValue([
                                                'titleComponent',
                                                'footerNumber',
                                            ]),
                                        ],
                                    }),
                                ],
                                alignment: AlignmentType.CENTER,
                            }),
                        ],
                    }),
                },
                children: arr,
            },
        ],
        styles: docxStyles,
        numbering: {
            config: [
                {
                    levels: [
                        {
                            level: 0,
                            format: LevelFormat.DECIMAL,
                            text: '[%1]',
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: { start: 200 },
                                },
                            },
                        },
                        {
                            level: 1,
                            format: LevelFormat.DECIMAL,
                            text: '[%1]     %2.',
                            alignment: AlignmentType.START,
                            style: {
                                paragraph: {
                                    indent: { start: 200 },
                                },
                            },
                        },
                    ],
                    reference: 'my-reference',
                },
            ],
        },
    })
    return docx
}
