import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import DeleteIcon from '../img/delete.svg'
import ClaimSelect from '../components/ClaimSelect'
import { isClaim } from '../components/ClaimSelect/ClaimSelect'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { setClaims } from '../store/slices/claims/claims'
import { setFigures as setReduxFigures } from '../store/slices/figures/figures'
import ClaimHandler from './components/ClaimHandler'
import { IClaimHandlerPosition } from './components/ClaimHandler/ClaimHandler.types'
import ElementHandler from './components/ElementHandler'
import NodeHandler from './components/NodeHandler'
import {
    StyledContainer,
    Flow,
    Sidebar,
    StyledFileInput,
    StyledDownloadButton,
    FullscreenLoader,
    StyledFigureNumber,
    StyledDeleteIcon,
} from './DiagramsLib.styles'
import {
    IClaimNode,
    IDiagramsLib,
    IDiagramsLibHandlers,
    IFigure,
    INode,
    NODE_KEYS,
    NODE_TYPES,
} from './DiagramsLib.types'
import DraggableHandler from './components/DraggableHandler'
import { Text } from '../components/DiagramTools'

const initialFigure: IFigure = {
    elements: [],
    claimElements: [],
    claims: {
        claim: undefined,
        components: [],
    },
}

const setId = (activeFigure: number, elements: INode[]) => {
    let returnNumber = 0
    for (let i = 0; i <= elements.length; i++) {
        const found = elements.find(
            (element) => element.id === `${activeFigure}${i}`
        )
        if (!found) returnNumber = i
    }
    return returnNumber
}

const DiagramsLib: React.ForwardRefRenderFunction<
    IDiagramsLibHandlers,
    IDiagramsLib
> = (props, ref) => {
    const dispatch = useAppDispatch()
    const { initialState } = props
    const { claims } = useAppSelector((state) => state)
    const [activeFigure, setActiveFigure] = useState<number>(0)
    const [figures, setFigures] = useState<IFigure[]>(
        initialState || [initialFigure]
    )
    const subscribeClaims = figures[activeFigure].claims
    const [selectedClaims, setSelectedClaims] = useState<any>([])
    const handleRefNumbers = () => {
        const newSelectedClaims: any[] = []
        const newFigures = figures
        newFigures.forEach((figure, i: number) => {
            const findMainClaim = newSelectedClaims.find(
                (x) => x.id === figure.claims.claim
            )
            if (!findMainClaim) {
                const fNum = i + 1 > 9 ? `${i + 1}` : `0${i + 1}`
                newSelectedClaims.push({
                    id: figure.claims.claim,
                    number: `${fNum}${figure.claims.claim}`,
                })
            }
            figure.claims.components.forEach((el) => {
                const isFound = newSelectedClaims.find((x) => x.id === el)
                if (!isFound) {
                    const fNum = i + 1 > 9 ? `${i + 1}` : `0${i + 1}`
                    newSelectedClaims.push({
                        id: el,
                        number: `${fNum}${el}`,
                    })
                }
            })
        })
        setSelectedClaims(newSelectedClaims)
        const newReduxClaimsComponents = claims.components
            .map((el) => {
                const found = newSelectedClaims.find((x) => x.id === el.id)
                if (found) {
                    return {
                        ...el,
                        number: found.number,
                    }
                }
                return el
            })
            .map((el) => {
                if (isClaim(el)) {
                    return {
                        ...el,
                        components: el.components.map((component) => {
                            const findSub = newSelectedClaims.find(
                                (x) => x.id === component.id
                            )
                            if (findSub) {
                                return {
                                    ...component,
                                    number: findSub.number,
                                }
                            }
                            return component
                        }),
                    }
                }
                return el
            })
        const newer = newReduxClaimsComponents.map((el) => {
            if (
                el.number.length !== 3 &&
                !newSelectedClaims.find((x) => x.id === el.id)
            ) {
                return {
                    ...el,
                    number: el.id,
                }
            }
            if (isClaim(el)) {
                const newComponents: any[] = []
                el.components.forEach((component) => {
                    if (component.number.length !== 3) {
                        const found = newSelectedClaims.find(
                            (x) => x.id === component.id
                        )
                        if (!found) {
                            newComponents.push({
                                ...component,
                                number: component.id,
                            })
                        } else newComponents.push(component)
                    } else {
                        newComponents.push(component)
                    }
                })
                return {
                    ...el,
                    components: newComponents,
                }
            }
            return el
        })
        const findParentClaim = newSelectedClaims.find((x) => x.id === '000')
        if (findParentClaim) {
            dispatch(
                setClaims({
                    ...claims,
                    number: findParentClaim.number,
                    components: newer,
                })
            )
        } else {
            dispatch(
                setClaims({
                    ...claims,
                    components: newer,
                })
            )
        }
    }
    useEffect(() => {
        const newClaimElements: IClaimNode[] = []
        subscribeClaims.components.forEach((component) => {
            let exists = false
            figures[activeFigure].claimElements.forEach((element) => {
                if (component === element.id) {
                    exists = true
                    newClaimElements.push(element)
                }
            })
            if (!exists)
                newClaimElements.push({
                    id: component,
                    top: '0',
                    left: '0',
                })
        })
        let mainClaimExists = false
        figures[activeFigure].claimElements.forEach((element) => {
            if (subscribeClaims.claim === element.id) {
                mainClaimExists = true
                newClaimElements.push(element)
            }
        })
        if (!mainClaimExists) {
            newClaimElements.push({
                id: `${subscribeClaims.claim}`,
                top: '0',
                left: '0',
            })
        }
        setFigures((prev) => {
            return prev.map((figure, i) => {
                if (i === activeFigure) {
                    return {
                        ...figure,
                        claimElements: newClaimElements,
                    }
                }
                return figure
            })
        })
    }, [JSON.stringify(subscribeClaims)])
    useImperativeHandle(ref, () => ({
        save: () => figures,
    }))
    const handleAddImage = (e: any): void => {
        const fr = new FileReader()
        fr.addEventListener('load', (ev) => {
            setFigures((prev) => {
                return prev.map((figure, i) => {
                    if (i === activeFigure) {
                        const { length } = figure.elements
                        const lastItem = figure.elements[length - 1]
                        const nextId = length > 0 ? lastItem.id + 1 : 0
                        return {
                            ...figure,
                            elements: [
                                ...figure.elements,
                                {
                                    id: `${nextId}`,
                                    src: ev.target?.result as string,
                                    width: '50px',
                                    height: '50px',
                                    rotation: '0deg',
                                    top: '100px',
                                    left: '100px',
                                    component: 'image',
                                    text: 'Text',
                                },
                            ],
                        }
                    }
                    return figure
                })
            })
        })
        const selectedFile = e.target.files[0]
        fr.readAsDataURL(selectedFile)
    }
    const handleSetElement = (id: string, data: any): void => {
        console.log(id, data)
        setFigures((prev) =>
            prev.map((figure, i) => {
                if (i === activeFigure) {
                    return {
                        ...figure,
                        elements: figure.elements.map((element) => {
                            if (element.id === id)
                                return {
                                    ...element,
                                    ...data,
                                }
                            return element
                        }),
                    }
                }
                return figure
            })
        )
    }

    const handleSetClaimPosition = (
        id: string,
        data: IClaimHandlerPosition
    ): void => {
        setFigures((prev) => {
            return prev.map((figure, i) => {
                if (i === activeFigure) {
                    return {
                        ...figure,
                        claimElements: figure.claimElements.map((element) => {
                            if (element.id === id) {
                                return {
                                    ...element,
                                    ...data,
                                }
                            }
                            return element
                        }),
                    }
                }
                return figure
            })
        })
    }
    const handleClaimChange = (
        claim: string | undefined,
        components: string[]
    ): void => {
        const newFigures = figures.map((figure, i) => {
            if (i === activeFigure) {
                return {
                    ...figure,
                    claims: {
                        claim,
                        components:
                            claim !== figure.claims.claim ? [] : components,
                    },
                }
            }
            return figure
        })
        setFigures(newFigures)
    }
    const handleAddElement = (el: NODE_KEYS | 'text') => {
        const newFigures = figures.map((figure, i) => {
            if (i === activeFigure) {
                if (el === 'text') {
                    return {
                        ...figure,
                        elements: [
                            ...figure.elements,
                            {
                                id: `${activeFigure}${setId(
                                    activeFigure,
                                    figure.elements
                                )}`,
                                width: '200px',
                                height: '200px',
                                rotation: '0deg',
                                left: '0',
                                top: '0',
                                text: 'Text',
                            } as INode,
                        ],
                    }
                }
                return {
                    ...figure,
                    elements: [
                        ...figure.elements,
                        {
                            id: `${activeFigure}${setId(
                                activeFigure,
                                figure.elements
                            )}`,
                            width: '200px',
                            height: el.toLowerCase().includes('arrow')
                                ? '25px'
                                : '200px',
                            rotation: '0deg',
                            left: '0',
                            top: '0',
                            component: el,
                        } as INode,
                    ],
                }
            }
            return figure
        })
        setFigures(newFigures)
    }
    const handleRemoveElement = (id: string) => {
        setFigures((prev) =>
            prev.map((figure, i) => {
                if (i === activeFigure) {
                    const newElements: INode[] = []
                    figure.elements.forEach((element) => {
                        if (element.id !== id) {
                            newElements.push(element)
                            console.log('DONT DELETE', element.id, id)
                        } else {
                            console.log('DELETE', element.id, id)
                        }
                    })
                    return {
                        ...figure,
                        elements: newElements,
                    }
                }
                return figure
            })
        )
    }
    const screenshot = async () => {
        return html2canvas(
            document.querySelector('#diagramsLibContainer')!
        ).then((canvas) => {
            return canvas
        })
    }
    const [flag, setFlag] = useState<boolean>(false)
    const [figuresScreenshots, setFiguresScreenshots] = useState<any[]>([])
    const [screenshotLoader, setScreenshotLoader] = useState<boolean>(false)
    useEffect(() => {
        if (flag) {
            if (activeFigure === figures.length - 1) setFlag(false)
            setTimeout(() => {
                screenshot().then((res) => {
                    setFiguresScreenshots((prev) => [...prev, res])
                    if (activeFigure < figures.length - 1) {
                        setActiveFigure((prev) => prev + 1)
                    }
                })
            }, 1000)
        }
    }, [flag, activeFigure])
    const downloadFigures = async () => {
        setScreenshotLoader(true)
        if (activeFigure > 0) setActiveFigure(0)
        setFiguresScreenshots([])
        setFlag(true)
    }
    useEffect(() => {
        const func = async () => {
            const diagramsSelector = document.querySelector(
                '#diagramsLibContainer'
            )!
            const { clientWidth, clientHeight } = diagramsSelector
            const aspectRatio = clientWidth / clientHeight
            const [width, height] = [632, 632 / aspectRatio]
            if (figuresScreenshots.length === figures.length) {
                // eslint-disable-next-line new-cap
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [632, 632 / aspectRatio],
                })
                figuresScreenshots.forEach((figureScreenshot, i) => {
                    pdf.addImage(figureScreenshot, 'PNG', 0, 0, width, height)
                    if (i < figuresScreenshots.length - 1) {
                        pdf.addPage()
                    }
                })
                pdf.save()
                setScreenshotLoader(false)
            }
        }
        func()
    }, [figuresScreenshots])
    useEffect(() => {
        handleRefNumbers()
        dispatch(setReduxFigures(figures))
        console.log(figures[0].elements)
    }, [figures])
    useEffect(() => {
        handleRefNumbers()
    }, [JSON.stringify(claims)])
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <StyledContainer loading={loading}>
            {screenshotLoader && (
                <FullscreenLoader>
                    {figures.length === figuresScreenshots.length
                        ? `The file will download soon`
                        : `We're preparing figures`}
                </FullscreenLoader>
            )}
            <Flow id="diagramsLibContainer" screenshotLoader={screenshotLoader}>
                {figures[activeFigure].elements.map(
                    ({
                        id,
                        src,
                        width,
                        height,
                        rotation,
                        top,
                        left,
                        text,
                        component = undefined,
                    }) => {
                        if (!component) {
                            const data = {
                                src,
                                width,
                                height,
                                rotation,
                                top,
                                left,
                                text,
                            }
                            return (
                                <DraggableHandler
                                    id={`${id}`}
                                    data={data}
                                    removeElement={handleRemoveElement}
                                    setElementData={handleSetElement}
                                    child={<Text />}
                                />
                            )
                        }
                        if (component === 'image') {
                            const data = {
                                src,
                                width,
                                height,
                                rotation,
                                top,
                                left,
                            }
                            return (
                                <NodeHandler
                                    id={id}
                                    data={data}
                                    setElement={handleSetElement}
                                >
                                    <img
                                        alt=""
                                        src={src}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </NodeHandler>
                            )
                        }
                        const data = {
                            src,
                            width,
                            height,
                            rotation,
                            top,
                            left,
                        }
                        const Component = NODE_TYPES[component]
                        return (
                            <ElementHandler
                                key={`${id}`}
                                id={`${id}`}
                                data={data}
                                resizable={!component.includes('text')}
                                resizeHeight={
                                    !component.toLowerCase().includes('arrow')
                                }
                                setElementData={handleSetElement}
                                removeElement={handleRemoveElement}
                            >
                                <Component />
                            </ElementHandler>
                        )
                    }
                )}
                {figures[activeFigure].claimElements.map(
                    ({ id, top, left }) => {
                        const data = {
                            top,
                            left,
                        }
                        return (
                            <ClaimHandler
                                id={id}
                                data={data}
                                setClaimPosition={handleSetClaimPosition}
                            >
                                {id}
                            </ClaimHandler>
                        )
                    }
                )}
                {!screenshotLoader ? (
                    <StyledDeleteIcon id="#deleteElement">
                        <img src={DeleteIcon} alt="Delete" />
                    </StyledDeleteIcon>
                ) : (
                    ''
                )}
                <StyledFigureNumber>{`Figure ${activeFigure + 1}/${
                    figures.length
                }`}</StyledFigureNumber>
                <StyledFileInput
                    type="file"
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={handleAddImage}
                />
                {!screenshotLoader ? (
                    <StyledDownloadButton
                        type="primary"
                        onClick={downloadFigures}
                    >
                        Download figures
                    </StyledDownloadButton>
                ) : (
                    ''
                )}
            </Flow>
            <Sidebar>
                <Divider>Figures</Divider>
                {figures.map((_figure, i) => (
                    <Button
                        type={i === activeFigure ? 'primary' : 'dashed'}
                        onClick={() => setActiveFigure(i)}
                        style={{ marginTop: '.5rem' }}
                    >
                        Figure {i + 1}
                    </Button>
                ))}
                <Button
                    type="default"
                    style={{ marginTop: '.5rem' }}
                    icon={<PlusOutlined />}
                    onClick={() =>
                        setFigures((prev) => [...prev, initialFigure])
                    }
                >
                    Add figure
                </Button>
                <ClaimSelect
                    data={figures[activeFigure].claims}
                    onChange={handleClaimChange}
                />
                {Object.entries(NODE_TYPES).map((entry) => {
                    const [key] = entry
                    if (key === 'image') return null
                    return (
                        <Button
                            type="default"
                            style={{ marginTop: '.5rem' }}
                            icon={<PlusOutlined />}
                            onClick={() => handleAddElement(key as NODE_KEYS)}
                        >
                            Add {key}
                        </Button>
                    )
                })}
                <Button
                    type="default"
                    style={{ marginTop: '.5rem' }}
                    icon={<PlusOutlined />}
                    onClick={() => handleAddElement('text')}
                >
                    Add text
                </Button>
            </Sidebar>
        </StyledContainer>
    )
}

export default React.forwardRef(DiagramsLib)
