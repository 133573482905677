import { StyledCylinder } from './Cylinder.styles'

const Cylinder: React.FC = (): JSX.Element => {
    return (
        <StyledCylinder>
            <div />
        </StyledCylinder>
    )
}

export default Cylinder
