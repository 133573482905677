import { MinusCircleOutlined } from '@ant-design/icons'
import { Form, Input, Col, Row, Button } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, CompositionForm } from '../types'

type Props = {
    form: CustomFormInstance<CompositionForm>
}

const CustomBackgroundOfInnovation: FC<Props> = ({ form }) => {
    return (
        <>
            <Form.List name={['backgroundInvention', 'customCharacteristic']}>
                {(fields, { add, remove }, errors) => (
                    <>
                        {fields.map((field) => (
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) =>
                                    prevValues.commonName?.notSet !==
                                    curValues.commonName?.notSet
                                }
                                key={field.key}
                            >
                                <Row gutter={[12, 0]}>
                                    <Col span={22}>
                                        <Form.Item
                                            {...field}
                                            name={[field.name]}
                                            fieldKey={[field.fieldKey]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required:
                                                        !form.getFieldValue([
                                                            'backgroundInvention',
                                                            'notSet',
                                                        ]) ?? true,
                                                    message:
                                                        'Characteristic is required',
                                                },
                                            ]}
                                        >
                                            <Input type="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        style={{
                                            textAlign: 'center',
                                            paddingTop: '0.4rem',
                                        }}
                                        span={2}
                                    >
                                        {fields.length > 0 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{
                                                    color: '#f00',
                                                    fontSize: '1.2rem',
                                                }}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            </Form.Item>
                        ))}

                        <Form.ErrorList errors={errors.errors} />
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add custom characteristic
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}

export default CustomBackgroundOfInnovation
