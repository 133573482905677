import { Button, Divider, Form, Input, Spin } from 'antd'
import { useState } from 'react'
import { passwordRepeatRules } from '../../pages/SignUp/SignUp.consts'
import { passwordRules } from '../../utils/consts'
import {
    fetchSaveUser,
    getRegularRules,
    isLoading,
} from '../../utils/functions'
import { REQUEST_STATUS } from '../../utils/types'
import { CustomForm } from './PasswordForm.styles'
import { IPasswordData } from './PasswordForm.types'

const PasswordForm: React.FC = (): JSX.Element => {
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(
        REQUEST_STATUS.NONE
    )
    const onSubmit = (formData: unknown): void => {
        setRequestStatus(REQUEST_STATUS.PENDING)
        const data = formData as IPasswordData
        fetchSaveUser(data)
            .then(() => {
                setRequestStatus(REQUEST_STATUS.SUCCESS)
            })
            .catch(() => {
                setRequestStatus(REQUEST_STATUS.FAILED)
            })
    }
    const onSubmitFailed = (): void => {
        setRequestStatus(REQUEST_STATUS.FAILED)
    }
    return (
        <Spin spinning={isLoading(requestStatus)}>
            <CustomForm
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                layout="vertical"
                requiredMark="optional"
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
            >
                <Divider plain>Change password</Divider>
                <Form.Item
                    label="New password"
                    name="password"
                    rules={passwordRules}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Repeat new password"
                    name="repeat_password"
                    dependencies={['password']}
                    rules={passwordRepeatRules}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 2 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </CustomForm>
        </Spin>
    )
}

export default PasswordForm
