import { Form, Checkbox } from 'antd'
import { FC } from 'react'
import { useFormItemWatcher } from '../../../../hooks'
import { Common, CustomFormInstance } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import {
    MachineForm,
    MachineNames,
    ManufactureForm,
    SharedQuestionProps,
} from '../types'

type Values = Common<MachineForm, ManufactureForm>

type Props = {
    form: CustomFormInstance<Values>
    characteristicComponentIndex: number
} & SharedQuestionProps

const FormatUserDefined: FC<Props> = ({
    form,
    characteristicComponentIndex,
    setContentBasedItemsToReview,
}) => {
    const componentsForCharacteristics =
        form.getFieldValue<Values['componentsForCharacteristics']>(
            MachineNames.componentsForCharacteristics
        ) ?? []

    const primaryComponents =
        form.getFieldValue<Values['primaryComponents']>(
            MachineNames.primaryComponents
        ) ?? []

    const primaryComponentId =
        componentsForCharacteristics[characteristicComponentIndex]

    const primaryComponent = primaryComponents.find(
        (comp) => comp?.id === primaryComponentId
    )

    const componentId = primaryComponent?.id ?? ''
    const componentName = primaryComponent?.component ?? ''
    const baseFormPath = [MachineNames.formatUserDefined, componentId] as [
        MachineNames.formatUserDefined,
        string
    ]

    useFormItemWatcher<Values, boolean>({
        form,
        path: [...baseFormPath, 'defined'],
        callback: (isDefined) => {
            if (setContentBasedItemsToReview) {
                if (isDefined) {
                    setContentBasedItemsToReview((prev) => [
                        ...prev,
                        MachineNames.formatUserDefined,
                    ])
                } else {
                    setContentBasedItemsToReview((prev) => [
                        ...prev.filter(
                            (item) => item !== MachineNames.formatUserDefined
                        ),
                    ])
                }
            }
        },
    })

    return (
        <>
            <Form.Item name={[...baseFormPath, 'from']} valuePropName="checked">
                <Checkbox>
                    <StyledSpan>the {componentName} having</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'name']}>
                        <StyledInput
                            placeholder="insert variable name"
                            style={{ width: '150px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>from</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'minimum']}>
                        <StyledInput
                            placeholder="insert minimum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'maximum']}>
                        <StyledInput
                            placeholder="insert maximum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <Form.Item name={[...baseFormPath, 'from', 'units']}>
                        <StyledInput
                            placeholder="insert units"
                            style={{
                                width: '100px',
                            }}
                            type="text"
                        />
                    </Form.Item>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'equal']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>the</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'equal', 'name']}>
                        <StyledInput
                            placeholder="insert variable name"
                            type="test"
                        />
                    </StyledFormItem>
                    <StyledSpan>of the {componentName} is equal to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'equal', 'minimum']}
                    >
                        <StyledInput
                            placeholder="insert minimum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'equal', 'maximum']}
                    >
                        <StyledInput
                            placeholder="insert maximum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledFormItem name={[...baseFormPath, 'equal', 'units']}>
                        <StyledInput
                            placeholder="insert units"
                            style={{ width: '100px' }}
                            type="text"
                        />
                    </StyledFormItem>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'defined']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>a user defined format:</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'defined', 'description']}
                    >
                        <textarea
                            style={{
                                border: 'none',
                                borderBottom: '2px solid black',
                                width: '150px',
                                transform: 'translateY(-8px)',
                                backgroundColor: 'transparent',
                                outline: 'none',
                                padding: '0px 5px',
                            }}
                            rows={1}
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        (a patent professional will be notified to review this)
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default FormatUserDefined
