import { Row } from 'antd'
import Form from 'antd/lib/form/Form'
import Layout from 'antd/lib/layout/layout'
import styled from 'styled-components'

export const StyledLayout = styled(Layout)`
    height: 100vh;
    overflow-y: scroll;
`

export const CustomRow = styled(Row)<{ visible: boolean }>`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    height: calc(100% - 11.5rem);
    position: relative;
`

export const CustomForm = styled(Form)`
    padding: 2rem 3rem;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: 0.3s;
    position: relative;
    width: 30rem;
`

export const DOCX = styled.div`
    padding: 3.5rem;
    height: 100%;
    background: #eff2f5;
    display: flex;
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
    flex-direction: column;
    h1 {
        width: 100%;
        text-align: center;
    }
`
