import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
    name: string
}

const SummaryOfSelectedEmbodimentsOfTheInvention: FC<Props> = ({
    form,
    name,
}) => {
    const primaryComponents =
        form.getFieldValue<MachineForm['primaryComponents']>(
            'primaryComponents'
        ) ?? []
    // const primaryComponentName =
    //     componentsForCharacteristics[subComponentIndex - 1]

    // const subcomponents =
    //     form.getFieldValue(`subComponents`)[primaryComponentName]
    return (
        <>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: true,
                        message: 'This field is required!',
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    options={primaryComponents.map((item) => {
                        return {
                            value: item.id ?? '',
                            label: item.component,
                        }
                    })}
                />
            </Form.Item>
        </>
    )
}

export default SummaryOfSelectedEmbodimentsOfTheInvention
