import { FC } from 'react'
import { Typography, Row, Col } from 'antd'
import styled from 'styled-components'
import { ValidQuestionKeys } from '../../hooks/useGerateQuestions'
import { mapQuestionNames } from '../../const'
import { CustomFormInstance } from '../../types'
import { FormValues } from '../../utils/forms/methods/types'

const { Title, Text } = Typography

type Props = {
    formRef: CustomFormInstance
    onItemClick: (name: ValidQuestionKeys) => void
    markedQuestions: ValidQuestionKeys[]
    contentBasedMarkedQuestions: ValidQuestionKeys[]
}

const RequestForHelp: FC<Props> = ({
    formRef,
    onItemClick,
    markedQuestions,
    contentBasedMarkedQuestions,
}) => {
    const titleKey = 'titleComponent'
    const data = formRef.getFieldValue<FormValues['titleComponent']>([titleKey])

    const mergedQuestionsList = [
        ...new Set([...markedQuestions, ...contentBasedMarkedQuestions]),
    ]

    const getName = (item: ValidQuestionKeys): string => {
        if (item === titleKey && data?.title) {
            return data?.title.toUpperCase().replace('MACHINE FOR ', '')
        }
        return `${mapQuestionNames[item]}`
    }

    return (
        <StyledContainer>
            <Title level={4}>Marked questions</Title>
            <Row>
                {mergedQuestionsList.map((item, index) => (
                    <Col span={24}>
                        <Text>{index + 1}.</Text>
                        <StyledText underline onClick={() => onItemClick(item)}>
                            {getName(item)}
                        </StyledText>
                    </Col>
                ))}
            </Row>
        </StyledContainer>
    )
}

export default RequestForHelp

const StyledContainer = styled.div`
    min-height: 300px;
`
const StyledText = styled(Text)`
    cursor: pointer;
    margin-left: 10px;
`
