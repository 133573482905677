import { FormInstance, Form, Checkbox } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FC, useCallback, useEffect, useState } from 'react'
import { Common, CustomFormInstance } from '../../../../types'
import { CompositionForm, MachineForm } from '../types'

type Values = Common<CompositionForm, MachineForm>

type Props = {
    form: CustomFormInstance<Values>
}

const AbstractOfTheDisclosure: FC<Props> = ({ form }) => {
    const summary = form.getFieldValue<
        Values['summaryOfSelectedEmbodimentsOfTheInvention']
    >('summaryOfSelectedEmbodimentsOfTheInvention')

    const test = form.getFieldValue<
        Values['subSummaryOfSelectedEmbodimentsOfTheInvention']
    >('subSummaryOfSelectedEmbodimentsOfTheInvention')

    const allSubComponents = form.getFieldValue<
        MachineForm['primaryComponentSubComponentsList']
    >('primaryComponentSubComponentsList')

    const temp1: any[] = []
    summary.forEach((component) => {
        const allComponents =
            form.getFieldValue<MachineForm['primaryComponents']>(
                'primaryComponents'
            )
        const foundComponent = allComponents.find((x) => x.id === component)
        if (foundComponent && foundComponent?.component) {
            temp1.push(foundComponent?.component)
        }
    })
    const getTerms = (components: any[]): string => {
        return components.join(', ').replace(/,(?=[^,]*$)/, ' and')
    }

    const temp = `${summary.map((item: any, index) => {
        if (allSubComponents === undefined) {
            return ''
        }
        return allSubComponents[item] === undefined
            ? ''
            : `The ${temp1[index]} comprises ${getTerms(
                  allSubComponents[item].map(
                      (item1: any) => `${item1.component}`
                  )
              )}.`
    })}`

    // const temp2 = temp1.join().replace(/.$/, '.')

    const handleInput = `In a first aspect, the invention comprises a machine. The machine comprises ${temp1}. ${temp}`

    const [{ content, wordCount }, setContent] = useState({
        content: handleInput,
        wordCount: 0,
    })

    const setFormattedContent = useCallback(
        (text) => {
            const words = text.split(' ').filter(Boolean)
            if (words.length > 150) {
                setContent({
                    content: words.slice(0, 150).join(' '),
                    wordCount: 10,
                })
            } else {
                setContent({ content: text, wordCount: words.length })
            }
        },
        [setContent]
    )

    useEffect(() => {
        setFormattedContent(content)
    }, [])

    return (
        <>
            {content !== undefined ? (
                <>
                    <Form.Item
                        name={['abstractOfTheDisclosure', 'checked']}
                        rules={[
                            {
                                required: true,
                                transform: (value) => value || undefined,
                                type: 'boolean', // Do the magic
                                message: 'This checkbox is required',
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            The automatically generated abstract of the
                            disclosure is adequate.
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name={['abstractOfTheDisclosure', 'defined']}
                        initialValue={content}
                    >
                        <TextArea
                            onChange={(event) =>
                                setFormattedContent(event.target.value)
                            }
                            value={content}
                        />
                    </Form.Item>
                    <div
                        style={{
                            color: '#b0adab',
                            position: 'absolute',
                            right: 0,
                        }}
                    >
                        {wordCount}/150 words
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default AbstractOfTheDisclosure
