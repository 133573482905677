import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { Router } from './components'
import 'antd/dist/antd.css'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { StyledSpin } from './utils/styles'
import { fetchUser } from './store/slices/user/user'

const App: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { isAuth, user } = useAppSelector((state) => state)
    const [initialChecks, setInitialChecks] = useState<boolean>(isAuth)
    useEffect(() => {
        if (isAuth && !user) {
            dispatch(fetchUser())
        }
        if (isAuth && user) {
            setInitialChecks(false)
        }
    }, [isAuth, user, dispatch])
    return (
        <StyledSpin spinning={initialChecks}>
            <Helmet
                titleTemplate="InnovationX | %s"
                defaultTitle="Missing title"
            >
                <meta name="description" content="A React.js aapplication" />
            </Helmet>
            <Router />
        </StyledSpin>
    )
}

export default App
