import { Form, Select, Checkbox } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm } from '../types'

type Props = {
    form:
        | CustomFormInstance<ManufactureForm>
        | CustomFormInstance<CompositionForm>
}

type Values = ManufactureForm | CompositionForm

const ImportantComponents: FC<Props> = ({ form }) => {
    const primaryComponents =
        form.getFieldValue<Values['primaryComponents']>('primaryComponents') ??
        []
    const componentWeights =
        form.getFieldValue<Values['componentWeights']>('componentWeights') ?? []

    const [component, setComponent] = useState<string>(
        primaryComponents[0].component ?? ''
    )
    const [basePath, setbasePath] = useState<string[]>([
        'componentWeights',
        component,
    ])
    const handleChange = (value: SelectValue) => {
        const choosenComponent = (value ?? '').toString()
        setComponent(choosenComponent)
        setbasePath(['componentWeights', choosenComponent])
    }

    let acceptableWeights = null
    let preferableWeights = null

    if (componentWeights[component] != null) {
        if (
            componentWeights[component]['max-acceptable-weight'] ||
            componentWeights[component]['min-acceptable-weight']
        ) {
            acceptableWeights = (
                <Form.Item
                    name={[...basePath, 'acceptable-weight-importance']}
                    valuePropName="checked"
                >
                    <Checkbox defaultChecked={false}>
                        Acceptable weight percentage [
                        {componentWeights[component]['min-acceptable-weight'] ??
                            '__'}
                        % to{' '}
                        {componentWeights[component]['max-acceptable-weight'] ??
                            '__'}
                        %]
                    </Checkbox>
                </Form.Item>
            )
        }

        if (
            componentWeights[component]['max-preferable-weight'] ||
            componentWeights[component]['min-preferable-weight']
        ) {
            preferableWeights = (
                <Form.Item
                    name={[...basePath, 'preferable-weight-importance']}
                    valuePropName="checked"
                >
                    <Checkbox defaultChecked={false}>
                        Preferable weight percentage [
                        {componentWeights[component]['min-preferable-weight'] ??
                            '__'}
                        % to{' '}
                        {componentWeights[component]['max-preferable-weight'] ??
                            '__'}
                        %]
                    </Checkbox>
                </Form.Item>
            )
        }
    }

    return (
        <>
            <Form.Item label="Choose component:">
                <Select
                    options={primaryComponents.map((item: any) => ({
                        value: item.component,
                        label: item.component,
                    }))}
                    onChange={handleChange}
                    value={component}
                />
            </Form.Item>
            {acceptableWeights}
            {preferableWeights}
        </>
    )
}

export default ImportantComponents
