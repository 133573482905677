import { StyledCone } from './Cone.styles'

const Cone: React.FC = (): JSX.Element => {
    return (
        <StyledCone>
            <div />
        </StyledCone>
    )
}

export default Cone
