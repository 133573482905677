import { Input, Button, Form, Checkbox, Select } from 'antd'
import { Paragraph } from 'docx'
import { Questions } from '../../../hooks/useGerateQuestions'
import MethodOutlineDescription from './components/MethodOutlineDescription'
import MethodOutlineInputs from './components/MethodOutlineInputs'
import CharacteristicMethod from './components/CharacteristicMethod'
import CharacteristicMethodOutline from './components/CharacteristicMethodOutline'
import MethodSteps from './components/MethodSteps'
import CharacteristicExplanation from './components/CharacteristicExplanation'
import ImportantCharacteristic from './components/ImportantCharacteristic'
import CharacteristicCustom from './components/CharacteristicCompositionCustom'
import { getCurrentListItemIndex } from '../../getCurrentListItemIndex'
import MethodComponentStepPracticalLimit from './components/MethodComponentStepPracticalLimit'
import MethodComponentStepPracticalPercentageLimit from './components/MethodComponentStepPracticalPercentageLimit'
import Title from './components/Title'
import { CustomFormInstance } from '../../../types'
import { MethodsForm, MethodsNames, SharedNames } from './types'
import DrawingsDescription from './components/DrawingsDescription'

type GetMethodsElementsArgs = {
    form: CustomFormInstance<MethodsForm>
    setActiveQuestions: React.Dispatch<
        React.SetStateAction<(keyof MethodsForm)[]>
    >
    activeQuestions: string[]
    currentQuestionIndex: number
}

const checkGerund = (_: any, methodStep: string) => {
    const firstWord = methodStep.split(' ')[0]
    if (firstWord.substr(firstWord.length - 3, 3) === 'ing') {
        return Promise.resolve()
    }
    return Promise.reject(new Error('Method must have gerund form!'))
}

export const getMethodElements = ({
    form,
    setActiveQuestions,
    activeQuestions,
    currentQuestionIndex,
}: GetMethodsElementsArgs): Questions<MethodsForm> => ({
    titleComponent: {
        component: <Title initialValue="COMPOSITION FOR" />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.backgroundInvention
                return newValues
            })
        },
        name: 'titleComponent',
        formInputProps: {
            label: 'Please select the title of the invention and list all the inventors',
        },
    },
    backgroundInvention: {
        component: (
            <>
                <Form.Item
                    name={['backgroundInvention', 'cross-reference']}
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        type="text"
                        placeholder="Cross-Reference and Related Applications"
                    />
                </Form.Item>
                <Form.Item label="Technical">
                    <Form.List name={['backgroundInvention', 'technical']}>
                        {(fields, { add }) => (
                            <>
                                {fields.map((field) => (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'technical']}
                                        fieldKey={[field.fieldKey, 'technical']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'This field is required!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                    >
                                        Add technical field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item label="Background">
                    <Form.List name={['backgroundInvention', 'background']}>
                        {(fields, { add }) => (
                            <>
                                {fields.map((field) => (
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'background']}
                                        fieldKey={[
                                            field.fieldKey,
                                            'background',
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'This field is required!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                ))}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                    >
                                        Add background
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                return newValues
            })
        },
        name: 'backgroundInvention',
        formInputProps: {
            label: 'Please write backround of the invention ',
        },
    },
    summaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <Form.List
                    name={[
                        'summaryOfSelectedEmbodimentsOfTheInvention',
                        'summary',
                    ]}
                >
                    {(fields, { add }) => (
                        <>
                            {fields.map((field) => (
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'summary']}
                                    fieldKey={[field.fieldKey, 'summary']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add summary
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.commonName
                return newValues
            })
        },
        name: 'summaryOfSelectedEmbodimentsOfTheInvention',
        formInputProps: {
            label: 'Please write summary of selected embodiments of the invention',
        },
    },
    commonName: {
        name: SharedNames.commonName,
        formInputProps: {
            label: 'Describe what your method is for?',
        },
        component: (
            <Form.Item noStyle>
                <Form.Item
                    label="Common name"
                    name={['commonName', 'commonName']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input placeholder="my common name" />
                </Form.Item>
            </Form.Item>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = MethodsNames.methodOutline
                return newValues
            })
        },
    },
    methodOutline: {
        component: (
            <Form.List
                name={MethodsNames.methodOutline}
                rules={[
                    {
                        validator: async (_, components) => {
                            if (
                                components === undefined ||
                                components?.length === 0
                            )
                                throw new Error('At least one step!')
                        },
                    },
                ]}
            >
                {(fields, { add }, { errors }) => (
                    <>
                        {fields.map((field) => (
                            <Form.Item
                                {...field}
                                label="Method step in gerund form"
                                name={[field.name, 'methodStep']}
                                fieldKey={[field.fieldKey, 'methodStep']}
                                rules={[
                                    { validator: checkGerund },
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        ))}
                        <Form.ErrorList errors={errors} />
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add method step
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.methodOutlineDescription
                return newValues
            })
        },
        name: MethodsNames.methodOutline,

        formInputProps: {
            label: 'Now let?s create an outline of the method steps you want to use.  In each blank, list a single word in the form of a gerund [verb form ending in ?ing? like mixing, reacting, washing, drying etc.] that describes your method steps.  The steps should be listed in sequential order.',
        },
    },
    methodOutlineDescription: {
        name: MethodsNames.methodOutlineDescription,
        formInputProps: {
            label: "Now let's get additional information on each step",
        },
        component: (
            <MethodOutlineDescription
                form={form}
                name={MethodsNames.methodOutlineDescription}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.methodOutlineInputs
                return newValues
            })
        },
    },
    methodOutlineInputs: {
        name: MethodsNames.methodOutlineInputs,
        formInputProps: {
            label: 'Does a component act on the input or inputs in this step?',
        },
        component: (
            <MethodOutlineInputs
                form={form}
                name={MethodsNames.methodOutlineInputs}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = MethodsNames.subSteps
                return newValues
            })
        },
    },
    characteristicMethod: {
        name: MethodsNames.characteristicMethod,
        formInputProps: {
            label: 'Does the method include important measurable or detectable properties?',
        },
        component: (
            <CharacteristicMethod
                name={MethodsNames.characteristicMethod}
                form={form}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.characteristicMethodOutline
                return newValues
            })
        },
    },
    characteristicMethodOutline: {
        name: MethodsNames.characteristicMethodOutline,
        formInputProps: {
            label: 'Does the method include important measurable or detectable properties?',
        },
        component: (
            <CharacteristicMethodOutline
                name={MethodsNames.characteristicMethodOutline}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.characteristicCustom
                return newValues
            })
        },
    },
    measurableProperties: {
        name: MethodsNames.measurableProperties,
        component: (
            <>
                <Form.Item
                    name={['measurableProperties', 'checkbox']}
                    valuePropName="checked"
                    style={{ marginBottom: '20px' }}
                >
                    <Checkbox defaultChecked={false}>Yes</Checkbox>
                </Form.Item>
            </>
        ),
        formInputProps: {
            label: 'Do any of the input components, the product compositions, or their constituents have important measurable or detectable dimensions or properties?',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.measurableProperties
                return newValues
            })
        },
    },
    subSteps: {
        component: <MethodSteps form={form} name={MethodsNames.subSteps} />,
        name: MethodsNames.subSteps,
        onNext: () => {
            const subSteps = form.getFieldValue('subSteps')
            // TODO
            // const nextComponent =
            //     subSteps && subSteps.length
            //         ? MethodsNames.methodSubOutline
            //         : MethodsNames.embodimentNecessaryStep
            const nextComponent = MethodsNames.embodimentNecessaryStep
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },

        formInputProps: {
            label: 'Please select the steps that have substeps you would like to describe:',
        },
    },
    embodimentNecessaryStep: {
        component: (
            <MethodSteps
                form={form}
                name={MethodsNames.embodimentNecessaryStep}
            />
        ),
        name: MethodsNames.embodimentNecessaryStep,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.stepPracticalLimit
                return newValues
            })
        },

        formInputProps: {
            label: 'Please select the steps that are always required in an embodiment of the method for the method to accomplish its intended purpose.',
        },
    },
    stepPracticalLimit: {
        component: (
            <MethodSteps form={form} name={MethodsNames.stepPracticalLimit} />
        ),
        name: MethodsNames.stepPracticalLimit,
        onNext: () => {
            const stepPracticalLimit =
                form.getFieldValue<any>('stepPracticalLimit') ?? []

            const nextComponent = stepPracticalLimit.length
                ? MethodsNames.componentStepPracticalLimit
                : MethodsNames.stepPracticalPercentageLimit
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },

        formInputProps: {
            label: 'Please select the step that has practical limits on the weight ratios of one input component to another input component.',
        },
    },
    componentStepPracticalLimit: {
        component: (
            <MethodComponentStepPracticalLimit
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MethodsNames.componentStepPracticalLimit
                )}
            />
        ),
        name: MethodsNames.componentStepPracticalLimit,
        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                MethodsNames.componentStepPracticalLimit
            )
            const stepComponent =
                form.getFieldValue<any>('stepPracticalLimit') ?? []
            const nextComponent =
                currentListItemIndex < stepComponent.length
                    ? MethodsNames.componentStepPracticalLimit
                    : MethodsNames.stepPracticalPercentageLimit
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
        formInputProps: {
            label: 'For each component, list a maximum acceptable weight ratio and a minimum acceptable weight ratio of the component.  Also, for each component, list a maximum preferred weight ratio and a minimum preferred weight ratio',
        },
    },
    stepPracticalPercentageLimit: {
        component: (
            <MethodSteps
                form={form}
                name={MethodsNames.stepPracticalPercentageLimit}
            />
        ),
        name: MethodsNames.stepPracticalPercentageLimit,
        onNext: () => {
            const stepPracticalLimit =
                form.getFieldValue<any>('stepPracticalPercentageLimit') ?? []
            const nextComponent = stepPracticalLimit.length
                ? MethodsNames.componentStepPracticalPercentageLimit
                : MethodsNames.characteristicMethod
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
        formInputProps: {
            label: 'Please select the step that has practical limits on the percentage of a constituent in an input component or output component.',
        },
    },
    componentStepPracticalPercentageLimit: {
        component: (
            <MethodComponentStepPracticalPercentageLimit
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MethodsNames.componentStepPracticalPercentageLimit
                )}
            />
        ),
        name: MethodsNames.componentStepPracticalPercentageLimit,
        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                MethodsNames.componentStepPracticalPercentageLimit
            )
            const stepComponent =
                form.getFieldValue<any>('stepPracticalPercentageLimit') ?? []
            const nextComponent =
                currentListItemIndex < stepComponent.length
                    ? MethodsNames.componentStepPracticalPercentageLimit
                    : MethodsNames.characteristicMethod
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
        formInputProps: {
            label: 'For each input component or product composition, list a maximum acceptable weight percentage and a minimum acceptable weight percentage of the constituent component or components as a percentage of the total weight of the input component or product composition.  Also, for each input component or product composition, list a maximum preferred weight percentage and a minimum preferred weight percentage.',
        },
    },
    characteristicCustom: {
        name: MethodsNames.characteristicCustom,
        formInputProps: {
            label: 'Would you like to add a property that has not already been included?',
        },
        component: (
            <CharacteristicCustom
                form={form}
                name={MethodsNames.characteristicCustom}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.characteristicExplanation
                return newValues
            })
        },
    },
    characteristicExplanation: {
        component: (
            <CharacteristicExplanation
                form={form as any}
                name={MethodsNames.characteristicExplanation}
            />
        ),
        name: MethodsNames.characteristicExplanation,
        formInputProps: {
            label: 'Please explain why each property is advantageous',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MethodsNames.importantCharacteristic
                return newValues
            })
        },
    },
    importantCharacteristic: {
        component: <ImportantCharacteristic form={form as any} />,
        name: MethodsNames.importantCharacteristic,
        formInputProps: {
            label: 'Are any of the properties always required for the method to achieve its objectives?',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.drawingsDescription
                return newValues
            })
        },
    },
    [SharedNames.drawingsDescription]: {
        component: (
            <DrawingsDescription
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MethodsNames.characteristicCompositon
                )}
            />
        ),
        name: SharedNames.drawingsDescription,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.doneDescribing
                return newValues
            })
        },
    },
    doneDescribing: {
        component: (
            <Form.Item
                label="Indefinite Article"
                name={['doneDescribing', 'indefiniteArticle']}
                rules={[
                    {
                        required: true,
                        message: 'Indefinite article is required',
                    },
                ]}
                hasFeedback
            >
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        isLastQuestion: true,
        name: 'doneDescribing',
        formInputProps: {
            label: 'Are you done describing the method?',
        },
    },
    newProperty: {
        component: (
            <Form.Item
                label="Indefinite Article"
                name={['doneDescribing', 'indefiniteArticle']}
                rules={[
                    {
                        required: true,
                        message: 'Indefinite article is required',
                    },
                ]}
                hasFeedback
            >
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        isLastQuestion: true,
        name: 'doneDescribing',
        formInputProps: {
            label: 'Are you done describing the method?',
        },
    },
})
