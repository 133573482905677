import { Form, Row, Col, InputNumber } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    characteristicComponentIndex: number
}

const MethodComponentStepPracticalLimit: FC<Props> = ({
    form,
    characteristicComponentIndex,
}) => {
    const stepComponent = form.getFieldValue<any>('stepPracticalLimit') ?? []
    const allOutlineSteps = form.getFieldValue<any>('methodOutlineInputs') ?? []
    const baseName = [
        'componentStepPracticalLimit',
        stepComponent[characteristicComponentIndex],
    ]
    return (
        <>
            {allOutlineSteps[
                `${stepComponent[characteristicComponentIndex]}`
            ].map((item: any) => (
                <Row gutter={[12, 0]} align="middle">
                    <Col span={12}>
                        <p>{item.inputComponent}</p>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={[...baseName, item.inputComponent, 'min']}
                        >
                            <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={[...baseName, item.inputComponent, 'max']}
                        >
                            <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default MethodComponentStepPracticalLimit
