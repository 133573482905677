import { Col, Layout, Row } from 'antd'
import { Helmet } from 'react-helmet'
import ProfileDetailsForm from '../../components/ProfileDetailsForm'
import PasswordForm from '../../components/PasswordForm'
import { StyledLayout } from './Profile.styles'
import { TopBar } from '../../components'

const { Content } = Layout

const Profile: React.FC = () => {
    return (
        <StyledLayout>
            <Helmet title="Profile" />
            <Content style={{ padding: '0 50px' }}>
                <TopBar breadcrumb={['Profile']} />
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <ProfileDetailsForm />
                    </Col>
                    <Col span={6}>
                        <PasswordForm />
                    </Col>
                </Row>
            </Content>
        </StyledLayout>
    )
}

export default Profile
