import { Form, Select } from 'antd'
import { FC } from 'react'
import { Common, CustomFormInstance } from '../../../../types'
import { CompositionForm, ManufactureForm, MethodsForm } from '../types'

type Values = Common<Common<CompositionForm, MethodsForm>, ManufactureForm>

type Props = {
    form: CustomFormInstance<Values>
}

const ImportantCharacteristic: FC<Props> = ({ form }) => {
    let characteristics: string[] = []
    const characteristicCompositon =
        form.getFieldValue<Values['characteristicCompositon']>(
            'characteristicCompositon'
        ) ?? undefined

    if (characteristicCompositon != null) {
        const characteristicKeys = Object.keys(characteristicCompositon)
        characteristics = characteristics.concat(characteristicKeys)
    }

    const characteristicCustom =
        form.getFieldValue<Values['characteristicCustom']>(
            'characteristicCustom'
        ) ?? undefined

    if (characteristicCustom != null) {
        const characteristicCustomKeys = Object.keys(characteristicCustom)
        characteristics = characteristics.concat(characteristicCustomKeys)
    }

    return (
        <Form.Item
            name="importantCharacteristic"
            label="Choose which characteristics are important (if there are any)"
        >
            <Select
                mode="multiple"
                options={characteristics.map((item) => ({
                    value: item,
                    label: item,
                }))}
            />
        </Form.Item>
    )
}

export default ImportantCharacteristic
