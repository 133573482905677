import styled from 'styled-components'
import { IStyledDiagramTool } from '../types'

export const StyledCircle = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid black;
    background: #f2f2f2;
`
