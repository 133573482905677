import { Button } from 'antd'
import styled from 'styled-components'

export const StyledContainer = styled.div<{
    loading: boolean
}>`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    &::after {
        display: ${({ loading }) => (loading ? 'flex' : 'none')};
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 1.3rem);
        height: 100%;
        background: white;
        content: 'Loading...';
        justify-content: center;
        align-items: center;
    }
`

export const Flow = styled.div<{ screenshotLoader: boolean }>`
    overflow: hidden;
    border: 1px solid
        ${({ screenshotLoader }) =>
            screenshotLoader ? 'transparent' : '#cccccc'};
    margin-right: 1rem;
    background: white;
    position: relative;
    width: 100%;
`

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledDeleteIcon = styled.div`
    position: absolute;
    top: 0rem;
    right: 0rem;
    padding: 1rem;
    pointer-events: none;
    img {
        pointer-events: none;
        width: 2rem;
    }
`

export const StyledFigureNumber = styled.div`
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
`

export const FullscreenLoader = styled.div`
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 1.4rem;
`

export const StyledDownloadButton = styled(Button)`
    position: absolute;
    left: 8.5rem;
    top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledFileInput = styled.input`
    position: absolute;
    width: 8.2rem;
    top: 1rem;
    left: 1rem;
    font-size: 0px;
    &::-webkit-file-upload-button {
        visibility: hidden;
    }
    &::before {
        content: 'Upload image';
        display: inline-block;
        background: linear-gradient(top, #f9f9f9, #e3e3e3);
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
    }
    &::before {
        border-color: black;
    }
`
