import styled from 'styled-components'

export const StyledArrow = styled.div`
    width: 100%;
    height: 1px;
    top: -2px;
    display: inline-block;
    background: black;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
`
