import styled from 'styled-components'

export const StyledSquigglyArrowPointer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    color: black;
    font-weight: lighter;
    font-size: 3rem;
    letter-spacing: -8px;
    p {
        width: 100%;
        background: red;
        height: 100%;
        margin-top: -5px;
        margin-right: 40px;
        direction: rtl;
    }
    img {
        position: absolute;
        top: 7px;
        right: -2px;
        height: 15px;
        width: 10px;
    }
`
