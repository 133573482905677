import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, MachineNames } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
    subComponentIndex: number
}

const SubcomponentsForCharacteristics: FC<Props> = ({
    form,
    subComponentIndex,
}) => {
    const primaryComponents =
        form.getFieldValue<MachineForm['primaryComponents']>(
            MachineNames.primaryComponents
        ) ?? []

    const componentsForCharacteristics =
        form.getFieldValue<MachineForm['componentsForCharacteristics']>(
            MachineNames.componentsForCharacteristics
        ) ?? []

    const primaryComponentSubComponentsList = form.getFieldValue<
        MachineForm['primaryComponentSubComponentsList']
    >(MachineNames.primaryComponentSubComponentsList)

    const selectedPrimaryComponentId =
        componentsForCharacteristics[subComponentIndex - 1]

    const primaryComponentName =
        primaryComponents.find((comp) => comp.id === selectedPrimaryComponentId)
            ?.component ?? ''

    const subComponentsList =
        primaryComponentSubComponentsList[selectedPrimaryComponentId]

    return (
        <>
            <Form.Item
                name={[
                    'subcomponentsForCharacteristics',
                    selectedPrimaryComponentId,
                ]}
                label={`Select the following subcomponents from the ${primaryComponentName} component that have
                    characteristics that you would like to further describe:`}
            >
                <Select
                    mode="multiple"
                    options={subComponentsList.map((item) => {
                        return {
                            value: item.id ?? '',
                            label: item.component,
                        }
                    })}
                />
            </Form.Item>
        </>
    )
}

export default SubcomponentsForCharacteristics
