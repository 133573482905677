import { Form, Checkbox } from 'antd'
import { FC } from 'react'
import { useFormItemWatcher } from '../../../../hooks'
import { CustomFormInstance, ValueOf } from '../../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from '../methods.styles'
import { MachineForm, MachineNames, SharedQuestionProps } from '../types'

type Props = {
    form: CustomFormInstance<MachineForm>
    characteristicComponentIndex: number
    characteristicSubComponentIndex: number
    countDiff: number
} & SharedQuestionProps

const SubFormatUserDefined: FC<Props> = ({
    form,
    characteristicComponentIndex,
    characteristicSubComponentIndex,
    countDiff,
    setContentBasedItemsToReview,
}) => {
    const componentsForCharacteristics =
        form.getFieldValue<MachineForm['componentsForCharacteristics']>(
            'componentsForCharacteristics'
        ) ?? []

    const componentId =
        componentsForCharacteristics[characteristicComponentIndex - 1]

    const subComponentsForCharacteristics =
        form.getFieldValue<MachineForm['subcomponentsForCharacteristics']>(
            'subcomponentsForCharacteristics'
        ) ?? []

    const subComponents =
        form.getFieldValue<
            ValueOf<MachineForm['primaryComponentSubComponentsList']>
        >(['primaryComponentSubComponentsList', componentId]) ?? []

    const subComponentId =
        subComponentsForCharacteristics[componentId][
            characteristicSubComponentIndex - countDiff
        ]

    const subComponentName =
        subComponents.find((item) => item.id === subComponentId)?.component ??
        ''

    const baseFormPath = ['subFormatUserDefined', subComponentId] as [
        MachineNames.subFormatUserDefined,
        string
    ]

    useFormItemWatcher<MachineForm, boolean>({
        form,
        path: [...baseFormPath, 'defined'],
        callback: (isDefined) => {
            if (setContentBasedItemsToReview) {
                if (isDefined) {
                    setContentBasedItemsToReview((prev) => [
                        ...prev,
                        MachineNames.subFormatUserDefined,
                    ])
                } else {
                    setContentBasedItemsToReview((prev) => [
                        ...prev.filter(
                            (item) => item !== MachineNames.subFormatUserDefined
                        ),
                    ])
                }
            }
        },
    })
    return (
        <>
            <Form.Item name={[...baseFormPath, 'from']} valuePropName="checked">
                <Checkbox>
                    <StyledSpan>the {subComponentName} has</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'name']}>
                        <StyledInput
                            placeholder="insert variable name"
                            style={{ width: '150px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>from</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'minimum']}>
                        <StyledInput
                            placeholder="insert minimum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'from', 'maximum']}>
                        <StyledInput
                            placeholder="insert maximum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <Form.Item name={[...baseFormPath, 'from', 'units']}>
                        <StyledInput
                            placeholder="insert units"
                            style={{
                                width: '100px',
                            }}
                            type="text"
                        />
                    </Form.Item>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'equal']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>the</StyledSpan>
                    <StyledFormItem name={[...baseFormPath, 'equal', 'name']}>
                        <StyledInput
                            placeholder="insert variable name"
                            style={{ width: '150px' }}
                            type="test"
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        of the {subComponentName} is equal to
                    </StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'equal', 'minimum']}
                    >
                        <StyledInput
                            placeholder="insert minimum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledSpan>to</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'equal', 'maximum']}
                    >
                        <StyledInput
                            placeholder="insert maximum"
                            style={{ width: '120px' }}
                            type="text"
                        />
                    </StyledFormItem>
                    <StyledFormItem name={[...baseFormPath, 'equal', 'units']}>
                        <StyledInput
                            placeholder="insert units"
                            style={{ width: '100px' }}
                            type="text"
                        />
                    </StyledFormItem>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={[...baseFormPath, 'defined']}
                valuePropName="checked"
            >
                <Checkbox>
                    <StyledSpan>a user defined format:</StyledSpan>
                    <StyledFormItem
                        name={[...baseFormPath, 'defined', 'description']}
                    >
                        <textarea
                            style={{
                                border: 'none',
                                borderBottom: '2px solid black',
                                width: '150px',
                                transform: 'translateY(-8px)',
                                backgroundColor: 'transparent',
                                outline: 'none',
                                padding: '0px 5px',
                            }}
                            rows={1}
                        />
                    </StyledFormItem>
                    <StyledSpan>
                        (a patent professional will be notified to review this)
                    </StyledSpan>
                </Checkbox>
            </Form.Item>
        </>
    )
}

export default SubFormatUserDefined
