import { Form, Input, Select, Tooltip } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { FC, ReactNode, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    name: string
}

type Properties = {
    [key: string]: ReactNode | null
}

type Units = {
    [key: string]: string
}

const CharacteristicComponent: FC<Props> = ({ name, form }) => {
    const properties = ['time', 'temperature', 'pressure', 'user property']

    const timeUnits = [
        'days',
        'hours',
        'minutes',
        'seconds',
        'milliseconds',
        'microseconds',
    ]

    const [property, setProperty] = useState<string>(properties[0])

    const handlePropertyChange = (value: SelectValue) => {
        setProperty((value ?? '').toString())
    }

    const basePath = [name, property]

    const characteristicElements: Properties = {
        time: (
            <>
                <Form.Item
                    label="The step lasts for at least:"
                    name={[...basePath, 'time1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Choose timeunit:"
                    name={[...basePath, 'timeunit1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="The step lasts for no more than:"
                    name={[...basePath, 'time1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Choose timeunit:"
                    name={[...basePath, 'timeunit1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="The step lasts for at least:"
                    name={[...basePath, 'time1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Choose timeunit:"
                    name={[...basePath, 'timeunit1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="The step lasts for at least:"
                    name={[...basePath, 'time1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Choose timeunit:"
                    name={[...basePath, 'timeunit1']}
                >
                    <Select
                        options={timeUnits.map((item: any) => ({
                            value: item.component,
                            label: item.component,
                        }))}
                    />
                </Form.Item>
            </>
        ),
        temperature: (
            <>
                <Form.Item
                    name={[...basePath, 'temperature']}
                    label="Pa*s at temperature [C]"
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name={[...basePath, 'rate']}
                    label="Pa*s at shear rate [s^-1]"
                >
                    <Input type="number" />
                </Form.Item>
            </>
        ),
        pressure: null,
        'user property': (
            <Form.Item
                name={[...basePath, 'condition']}
                label={
                    <Tooltip title="specified conditions in SI units used to obtain the value">
                        <span style={{ textDecoration: 'underline' }}>
                            Measured using:
                        </span>
                    </Tooltip>
                }
            >
                <Input type="text" />
            </Form.Item>
        ),
    }

    const characteristicsUnits: Units = {
        density: 'kg/m^3',
        viscosity: 'Pa*s',
        porosity: '0 - 1',
        brinell: 'kg_f/mm^2',
        ph: '1 - 10',
    }

    return (
        <>
            <Form.Item label="Choose property:">
                <Select
                    options={properties.map((item: string) => ({
                        value: item,
                        label: item,
                    }))}
                    onChange={handlePropertyChange}
                    value={property}
                />
            </Form.Item>
            {characteristicElements[property]}
            <Form.Item
                name={[...basePath, 'max-acceptable']}
                label={`Maximum acceptable ${property} [${characteristicsUnits[property]}]`}
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'min-acceptable']}
                label={`Minimum acceptable ${property} [${characteristicsUnits[property]}]`}
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'max-preferable']}
                label={`Maximum preferable ${property} [${characteristicsUnits[property]}]`}
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
            <Form.Item
                name={[...basePath, 'min-preferable']}
                label={`Minimum preferable ${property} [${characteristicsUnits[property]}]`}
            >
                <Input type="number" max="100" min="0" />
            </Form.Item>
        </>
    )
}

export default CharacteristicComponent
