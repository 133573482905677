import { Button, Select, Form, Checkbox, Row, Col, Tooltip } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { Questions } from '../../../hooks/useGerateQuestions'
import MainSubcomponents from './components/MainSubcomponents'
import ComponentSubComponents from './components/ComponentSubComponents'
import AdditionalCharacteristic from './components/AdditionalCharacteristic'
import ComponentsForCharacteristics from './components/ComponentsForCharacteristics'
import FormatUserDefined from './components/FormatUserDefined'
import Characteristic from './components/Characteristic'
import { getCurrentListItemIndex } from '../../getCurrentListItemIndex'
import SubcomponentsForCharacteristics from './components/MachineSubcomponentsForCharacteristics'
import CrossReferenceAndReletedApplication from './components/CrossReferenceAndReletedApplicationMachineForm'
import SubCharacteristic from './components/SubCharacteristic'
import SubAdditionalCharacteristic from './components/SubAdditionalCharacteristic'
import SubFormatUserDefined from './components/SubFormatUserDefined'
import SummaryOfSelectedEmbodimentsOfTheInvention from './components/MachineSummaryOfSelectedEmbodimentsOfTheInvention'
import DrawingsDescription from './components/DrawingsDescription'
import PartsOfIndependentClaim from './components/PartsOfIndependentClaim'
import SubSummaryOfSelectedEmbodimentsOfTheInvention from './components/MachineSubSummaryOfSelectedEmbodimentsOfTheInvention'
import AbstractOfTheDisclosure from './components/MachineAbstractOfTheDisclosure'
import Title from './components/Title'
import PrimaryComponents from './components/PrimaryComponents'
import CommonName from './components/CommonName'
import CustomBackgroundOfInnovation from './components/CustomBackgroundOfInnovationMachineForm'
import DetailedDescription from './components/DetailedDescription'
import { MachineForm, MachineNames, MachineValues, SharedNames } from './types'
import { CustomFormInstance } from '../../../types'
import { StyledSpan, StyledFormItem, StyledInput } from './methods.styles'

// export enum MachineNames {
//     title = 'titleComponent',
//     primaryComponents = 'primaryComponents',
//     commonName = 'commonName',
//     mainSubcomponents = 'mainSubcomponents',
//     primaryComponentSubComponentsList = 'primaryComponentSubComponentsList',
//     componentsForCharacteristics = 'componentsForCharacteristics',
//     characteristic = 'characteristic',
//     additionalCharacteristic = 'additionalCharacteristic',
//     formatUserDefined = 'formatUserDefined',
//     subcomponentsForCharacteristics = 'subcomponentsForCharacteristics',
//     subCharacteristic = 'subCharacteristic',
//     subAdditionalCharacteristic = 'subAdditionalCharacteristic',
//     subFormatUserDefined = 'subFormatUserDefined',
//     drawingsDescription = 'drawingsDescription',
//     partsOfIndependentClaim = 'partsOfIndependentClaim',
//     importantComponentsConnections = 'importantComponentsConnections',
//     functionalConnections = 'functionalConnections',
//     dependantComponentsConnections = 'dependantComponentsConnections',
//     backgroundInvention = 'backgroundInvention',
//     customBackgroundOfInvention = 'customBackgroundOfInvention',
//     summaryOfSelectedEmbodimentsOfTheInvention = 'summaryOfSelectedEmbodimentsOfTheInvention',
//     subSummaryOfSelectedEmbodimentsOfTheInvention = 'subSummaryOfSelectedEmbodimentsOfTheInvention',
//     abstractOfTheDisclosure = 'abstractOfTheDisclosure',
//     detailedDescription = 'detailedDescription',
//     doneDescribing = 'doneDescribing',
//     extraOptions = 'extraOptions',
//     lastPane = 'lastPane',
// }

type GetMethodsElementsArgs = {
    form: CustomFormInstance<MachineForm>
    setActiveQuestions: React.Dispatch<React.SetStateAction<string[]>>
    activeQuestions: string[]
    currentQuestionIndex: number
    setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>
}
const prepareCharacteristicQuestionsForComp = (
    questionsArray: string[],
    startIndex: number
) => {
    const questionsCopy = [...questionsArray]
    questionsCopy[startIndex + 1] = MachineNames.characteristic
    questionsCopy[startIndex + 2] = MachineNames.additionalCharacteristic
    questionsCopy[startIndex + 3] = MachineNames.formatUserDefined
    return questionsCopy
}

const prepareCharacteristicQuestionsForSubComp = (
    questionsArray: string[],
    startIndex: number
) => {
    const questionsCopy = [...questionsArray]
    questionsCopy[startIndex + 1] = MachineNames.subCharacteristic
    questionsCopy[startIndex + 2] = MachineNames.subAdditionalCharacteristic
    questionsCopy[startIndex + 3] = MachineNames.subFormatUserDefined
    return questionsCopy
}

let subcomponentDiff = 0
let currentComponent = 0

export const getMachineElements = ({
    form,
    setActiveQuestions,
    activeQuestions,
    currentQuestionIndex,
    setCurrentQuestionIndex,
}: GetMethodsElementsArgs): Questions<MachineForm> => ({
    titleComponent: {
        component: <Title initialValue="MACHINE FOR" />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = SharedNames.commonName
                return newValues
            })
        },
        name: SharedNames.titleComponent,
        formInputProps: {
            label: 'Please select the title of the invention and list all the inventors',
        },
    },
    commonName: {
        component: <CommonName />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.primaryComponents
                return newValues
            })
        },
        name: SharedNames.commonName,
        formInputProps: {
            label: 'If the machine has a common name, what is it?',
        },
    },
    primaryComponents: {
        component: <PrimaryComponents name={MachineNames.primaryComponents} />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.mainSubcomponents
                return newValues
            })
        },
        name: MachineNames.primaryComponents,
        formInputProps: {
            label: (
                <label>
                    Please list the{' '}
                    <Tooltip
                        title="Examples of primary components include components that you believe are necessary
                     for the invention to work, necessary to describe how the invention is novel, or necessary to explain how any other primary components are connected."
                    >
                        <span style={{ textDecoration: 'underline' }}>
                            primary components
                        </span>
                    </Tooltip>{' '}
                    of the machine and select a corresponding indefinite article
                </label>
            ),
        },
    },
    mainSubcomponents: {
        component: <MainSubcomponents form={form} />,
        name: MachineNames.mainSubcomponents,
        onNext: () => {
            const mainSubcomponents = form.getFieldValue<
                MachineForm['mainSubcomponents']
            >(MachineNames.mainSubcomponents)
            const nextComponent =
                mainSubcomponents && mainSubcomponents.length
                    ? MachineNames.primaryComponentSubComponentsList
                    : MachineNames.componentsForCharacteristics
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
        formInputProps: {
            label: 'Please select the primary components that have subcomponents you would like to describe:',
        },
    },
    [MachineNames.primaryComponentSubComponentsList]: {
        component: (
            <ComponentSubComponents
                form={form}
                subComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.primaryComponentSubComponentsList
                )}
            />
        ),
        name: MachineNames.primaryComponentSubComponentsList,
        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                MachineNames.primaryComponentSubComponentsList
            )
            const mainSubcomponents =
                form.getFieldValue<MachineForm['mainSubcomponents']>(
                    MachineNames.mainSubcomponents
                ) ?? []

            const nextComponent =
                currentListItemIndex < mainSubcomponents.length
                    ? MachineNames.primaryComponentSubComponentsList
                    : MachineNames.componentsForCharacteristics

            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
    },
    [MachineNames.componentsForCharacteristics]: {
        name: MachineNames.componentsForCharacteristics,
        component: <ComponentsForCharacteristics form={form} />,
        formInputProps: {
            label: 'Select the following components that have characteristics that you would like to further describe:',
        },
        onNext: () => {
            const componentsForCharacteristics = form.getFieldValue<
                MachineForm['componentsForCharacteristics']
            >(MachineNames.componentsForCharacteristics)
            if (
                componentsForCharacteristics &&
                componentsForCharacteristics.length
            ) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.partsOfIndependentClaim
                    return newValues
                })
            }
        },
    },
    characteristic: {
        component: (
            <Characteristic
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
            />
        ),
        name: MachineNames.characteristic,
        formInputProps: {
            label: undefined,
        },
    },
    [MachineNames.additionalCharacteristic]: {
        component: (
            <AdditionalCharacteristic
                form={form as any}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.additionalCharacteristic
                )}
            />
        ),
        name: MachineNames.additionalCharacteristic,
        formInputProps: {
            label: 'Select the additional characteristics that you would like to add and fill in any applicable blanks.',
        },
    },
    [MachineNames.formatUserDefined]: {
        component: (
            <FormatUserDefined
                form={form as any}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.formatUserDefined
                )}
            />
        ),
        name: MachineNames.formatUserDefined,
        onNext: () => {
            const currentListItemIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                MachineNames.formatUserDefined
            )

            const componentsForCharacteristics =
                form.getFieldValue<MachineForm['componentsForCharacteristics']>(
                    'componentsForCharacteristics'
                ) ?? []

            const primaryComponentId =
                componentsForCharacteristics[
                    getCurrentListItemIndex(
                        activeQuestions,
                        currentQuestionIndex,
                        MachineNames.formatUserDefined
                    )
                ]

            const mainSubcomponents =
                form.getFieldValue<MachineForm['mainSubcomponents']>(
                    'mainSubcomponents'
                ) ?? []

            if (currentListItemIndex < componentsForCharacteristics.length) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.backgroundInvention
                    return newValues
                })
            }
            if (mainSubcomponents.includes(primaryComponentId)) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.subcomponentsForCharacteristics
                    return newValues
                })
            }
        },
        formInputProps: {
            label: 'Select a format for the user defined variable and fill in the applicable blanks.',
        },
    },
    subcomponentsForCharacteristics: {
        name: MachineNames.subcomponentsForCharacteristics,
        component: (
            <SubcomponentsForCharacteristics
                form={form}
                subComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
            />
        ),
        formInputProps: {
            label: undefined,
        },
        onNext: () => {
            const componentsForCharacteristics =
                form.getFieldValue<MachineForm['componentsForCharacteristics']>(
                    'componentsForCharacteristics'
                ) ?? []
            const primaryComponentIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex,
                MachineNames.characteristic
            )
            const subComponentIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex,
                MachineNames.subCharacteristic
            )
            if (currentComponent !== primaryComponentIndex - 1) {
                subcomponentDiff += subComponentIndex
                currentComponent = primaryComponentIndex - 1
            }
            const primaryComponentId =
                componentsForCharacteristics[primaryComponentIndex - 1]

            const subcomponentsForCharacteristics = form.getFieldValue<
                MachineForm['subcomponentsForCharacteristics']
            >('subcomponentsForCharacteristics')

            if (subcomponentsForCharacteristics) {
                const choosenSubcomponents =
                    subcomponentsForCharacteristics[primaryComponentId]
                if (choosenSubcomponents && choosenSubcomponents.length) {
                    const newQuestions =
                        prepareCharacteristicQuestionsForSubComp(
                            activeQuestions,
                            currentQuestionIndex
                        )
                    setActiveQuestions(newQuestions)
                }
                // TODO: add proper fallback there
                // setActiveQuestions((prev) => {
                //     const newValues = [...prev]
                //     newValues[currentQuestionIndex + 1] =
                //         MachineNames.partsOfIndependentClaim
                //     return newValues
                // })
            } else if (
                primaryComponentIndex < componentsForCharacteristics.length
            ) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.partsOfIndependentClaim
                    return newValues
                })
            }
        },
    },
    [MachineNames.subCharacteristic]: {
        component: (
            <SubCharacteristic
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
                characteristicSubComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.subCharacteristic
                )}
                countDiff={subcomponentDiff}
            />
        ),
        name: MachineNames.subCharacteristic,
        formInputProps: {
            label: undefined,
        },
    },
    [MachineNames.subAdditionalCharacteristic]: {
        component: (
            <SubAdditionalCharacteristic
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
                characteristicSubComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.subAdditionalCharacteristic
                )}
                countDiff={subcomponentDiff}
            />
        ),
        name: MachineNames.subAdditionalCharacteristic,
        formInputProps: {
            label: 'Select the additional characteristics that you would like to add and fill in any applicable blanks.',
        },
    },
    [MachineNames.subFormatUserDefined]: {
        component: (
            <SubFormatUserDefined
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
                characteristicSubComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.subFormatUserDefined
                )}
                countDiff={subcomponentDiff}
            />
        ),
        name: MachineNames.subFormatUserDefined,
        onNext: () => {
            const currentListComponentIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex,
                MachineNames.characteristic
            )
            const componentsForCharacteristics = form.getFieldValue<
                MachineForm['componentsForCharacteristics']
            >('componentsForCharacteristics')

            const componentName =
                componentsForCharacteristics[currentListComponentIndex - 1]

            const currentListSubComponentIndex = getCurrentListItemIndex(
                activeQuestions,
                currentQuestionIndex + 1,
                MachineNames.subFormatUserDefined
            )

            const subcomponentsForCharacteristics = form.getFieldValue<
                MachineForm['subcomponentsForCharacteristics']
            >('subcomponentsForCharacteristics')[componentName]

            if (
                currentListSubComponentIndex - subcomponentDiff <
                subcomponentsForCharacteristics.length
            ) {
                const newQuestions = prepareCharacteristicQuestionsForSubComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else if (
                currentListComponentIndex < componentsForCharacteristics.length
            ) {
                const newQuestions = prepareCharacteristicQuestionsForComp(
                    activeQuestions,
                    currentQuestionIndex
                )
                setActiveQuestions(newQuestions)
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.partsOfIndependentClaim
                    return newValues
                })
            }
        },
    },
    partsOfIndependentClaim: {
        name: MachineNames.partsOfIndependentClaim,
        component: (
            <PartsOfIndependentClaim
                form={form}
                name={MachineNames.partsOfIndependentClaim}
            />
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.importantComponentsConnections
                return newValues
            })
        },
        formInputProps: {
            label: (
                <label>
                    Select all components that should end up in Independant
                    Claim. All other components will end up in Dependant Claim.
                </label>
            ),
        },
    },
    importantComponentsConnections: {
        component: (
            <Form.List name="importantComponentsConnections">
                {(fields, { add, remove }) => {
                    const primaryOptions = form.getFieldValue<
                        MachineForm['partsOfIndependentClaim']
                    >('partsOfIndependentClaim')
                    const formatedOptions = primaryOptions.map(
                        (item: string) => {
                            item = `${item.replace('+=>', ' of the ')}`
                            return item
                        }
                    )
                    return (
                        <>
                            {fields.map((field) => (
                                <Form.Item style={{ marginBottom: '24px' }}>
                                    <Row align="middle" gutter={[12, 0]}>
                                        <Col span={10}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="1st component"
                                                        name={[
                                                            field.name,
                                                            '1stComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '1stComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '1st component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={formatedOptions.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="2nd component"
                                                        name={[
                                                            field.name,
                                                            '2ndComponent',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            '2ndComponent',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    '2nd component is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={formatedOptions.map(
                                                                (
                                                                    item: string
                                                                ) => ({
                                                                    value: item,
                                                                    label: item,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={2.5}>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{
                                                    color: '#f00',
                                                    fontSize: '1.6rem',
                                                    marginTop: '0.6rem',
                                                }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={[field.name, 'notSet']}
                                                valuePropName="checked"
                                                style={{ marginBottom: '20px' }}
                                            >
                                                <Checkbox>
                                                    I want to describe the
                                                    connection
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        {!form.getFieldValue<
                                            MachineForm['importantComponentsConnections']
                                        >('importantComponentsConnections')[
                                            field.name
                                        ]?.notSet ? (
                                            <Col span={24}>
                                                <Form.Item noStyle shouldUpdate>
                                                    {() => (
                                                        <Form.Item
                                                            {...field}
                                                            label="Connection type"
                                                            name={[
                                                                field.name,
                                                                'connectionType',
                                                            ]}
                                                            fieldKey={[
                                                                field.fieldKey,
                                                                'connectionType',
                                                            ]}
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        'Connection type is required',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                options={[
                                                                    {
                                                                        value: 'Rotatably connected to',
                                                                        label: 'Rotatably connected to',
                                                                    },
                                                                    {
                                                                        value: 'Slidably engaged with',
                                                                        label: 'Slidably engaged with',
                                                                    },
                                                                    {
                                                                        value: 'Coupled to',
                                                                        label: 'Coupled to',
                                                                    },
                                                                    {
                                                                        value: 'Integral with',
                                                                        label: 'Integral with',
                                                                    },
                                                                    {
                                                                        value: 'Coextruded with',
                                                                        label: 'Coextruded with',
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col span={24}>
                                                <Form.Item
                                                    noStyle
                                                    name={[
                                                        field.name,
                                                        'additionalConnectionInfo',
                                                    ]}
                                                    rules={[
                                                        {
                                                            validator:
                                                                async () => {
                                                                    const importantComponentsConnections =
                                                                        form.getFieldValue<
                                                                            MachineForm['importantComponentsConnections']
                                                                        >(
                                                                            'importantComponentsConnections'
                                                                        )[
                                                                            field
                                                                                .key
                                                                        ]
                                                                    if (
                                                                        importantComponentsConnections &&
                                                                        importantComponentsConnections.notSet
                                                                    ) {
                                                                        const errorList =
                                                                            []
                                                                        if (
                                                                            importantComponentsConnections[
                                                                                '1stPart'
                                                                            ] ==
                                                                                null ||
                                                                            importantComponentsConnections[
                                                                                '1stPart'
                                                                            ] ===
                                                                                ''
                                                                        ) {
                                                                            errorList.push(
                                                                                '1st part of description is required'
                                                                            )
                                                                        }
                                                                        if (
                                                                            importantComponentsConnections[
                                                                                '2ndPart'
                                                                            ] ==
                                                                                null ||
                                                                            importantComponentsConnections[
                                                                                '2ndPart'
                                                                            ] ===
                                                                                ''
                                                                        ) {
                                                                            errorList.push(
                                                                                ' 2nd part of description is required'
                                                                            )
                                                                        }
                                                                        if (
                                                                            importantComponentsConnections[
                                                                                '3rdPart'
                                                                            ] ==
                                                                                null ||
                                                                            importantComponentsConnections[
                                                                                '3rdPart'
                                                                            ] ===
                                                                                ''
                                                                        ) {
                                                                            errorList.push(
                                                                                ' 3rd part of description is required'
                                                                            )
                                                                        }
                                                                        if (
                                                                            errorList.length >
                                                                            0
                                                                        ) {
                                                                            throw new Error(
                                                                                errorList.toString()
                                                                            )
                                                                        }
                                                                    }
                                                                },
                                                        },
                                                    ]}
                                                >
                                                    <StyledFormItem
                                                        name={[
                                                            field.name,
                                                            '1stPart',
                                                        ]}
                                                    >
                                                        <StyledInput
                                                            type="text"
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        />
                                                    </StyledFormItem>
                                                    <StyledSpan>
                                                        the{' '}
                                                        {form.getFieldValue<
                                                            MachineForm['importantComponentsConnections']
                                                        >(
                                                            'importantComponentsConnections'
                                                        )[field.key]?.[
                                                            '1stComponent'
                                                        ] ?? '1st Component'}
                                                    </StyledSpan>
                                                    <StyledFormItem
                                                        name={[
                                                            field.name,
                                                            '2ndPart',
                                                        ]}
                                                    >
                                                        <StyledInput
                                                            type="text"
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        />
                                                    </StyledFormItem>
                                                    <StyledSpan>
                                                        the{' '}
                                                        {form.getFieldValue<
                                                            MachineForm['importantComponentsConnections']
                                                        >(
                                                            'importantComponentsConnections'
                                                        )[field.key]?.[
                                                            '2ndComponent'
                                                        ] ?? '2nd Component'}
                                                    </StyledSpan>
                                                    <StyledFormItem
                                                        name={[
                                                            field.name,
                                                            '3rdPart',
                                                        ]}
                                                    >
                                                        <StyledInput
                                                            type="text"
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                        />
                                                    </StyledFormItem>
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add connection
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.functionalConnections
                return newValues
            })
        },
        name: MachineNames.importantComponentsConnections,
        formInputProps: {
            label: (
                <label>
                    Specify the{' '}
                    <Tooltip
                        title="In response to this prompt, specify physical connections for each component of the independent claims.  
                        Every component in the independent claims (e.g., claims that do not refer to any other claims, for example, claim 1) should be directly or indirectly related to every other component of the independent claim.  
                        If the connection is not a direct physical connection as requested in this prompt, an arrangement, orientation or functional relationship can be specified in later forms.

                        Ultimately, each component in an independent claim should be directly or indirectly related to every other component of the independent claim.  
                        Similarly, each component of a dependent claim should be directly or indirectly related to at least one component of an independent claim.  
                        As an example, component C can be indirectly related to component A if C is connected to Component B and component B is connected to component A."
                    >
                        <span style={{ textDecoration: 'underline' }}>
                            physical connections
                        </span>
                    </Tooltip>{' '}
                    (if applicable) between components.
                </label>
            ),
        },
    },
    functionalConnections: {
        component: (
            <Form.List name="functionalConnections">
                {(fields, { add, remove }) => {
                    const primaryOptions = form.getFieldValue<
                        MachineForm['partsOfIndependentClaim']
                    >('partsOfIndependentClaim')
                    const formatedOptions = primaryOptions.map(
                        (item: string) => {
                            item = `${item.replace('+=>', ' of the ')}`
                            return item
                        }
                    )
                    return (
                        <>
                            {fields.map((field) => (
                                <Row align="middle" gutter={[12, 0]}>
                                    <Col span={11}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="1st component"
                                                    name={[
                                                        field.name,
                                                        '1stComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '1stComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '1st component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={formatedOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="2nd component"
                                                    name={[
                                                        field.name,
                                                        '2ndComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '2ndComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '2nd component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={formatedOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2.5}>
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                            style={{
                                                color: '#f00',
                                                fontSize: '1.6rem',
                                                marginTop: '0.6rem',
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[field.name, 'notSet']}
                                            valuePropName="checked"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Checkbox>
                                                I want to describe the
                                                connection
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {form.getFieldValue<
                                        MachineValues['functionalConnections']
                                    >('functionalConnections')[field.name]
                                        ?.notSet ? (
                                        <Col span={24}>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    field.name,
                                                    'additionalConnectionInfo',
                                                ]}
                                                rules={[
                                                    {
                                                        validator: async () => {
                                                            const functionalConnections =
                                                                form.getFieldValue<
                                                                    MachineForm['functionalConnections']
                                                                >(
                                                                    'functionalConnections'
                                                                )[field.key]
                                                            if (
                                                                functionalConnections &&
                                                                functionalConnections.notSet
                                                            ) {
                                                                const errorList =
                                                                    []
                                                                if (
                                                                    functionalConnections[
                                                                        '1stPart'
                                                                    ] == null ||
                                                                    functionalConnections[
                                                                        '1stPart'
                                                                    ] === ''
                                                                ) {
                                                                    errorList.push(
                                                                        '1st part of description is required'
                                                                    )
                                                                }
                                                                if (
                                                                    functionalConnections[
                                                                        '2ndPart'
                                                                    ] == null ||
                                                                    functionalConnections[
                                                                        '2ndPart'
                                                                    ] === ''
                                                                ) {
                                                                    errorList.push(
                                                                        ' 2nd part of description is required'
                                                                    )
                                                                }
                                                                if (
                                                                    functionalConnections[
                                                                        '3rdPart'
                                                                    ] == null ||
                                                                    functionalConnections[
                                                                        '3rdPart'
                                                                    ] === ''
                                                                ) {
                                                                    errorList.push(
                                                                        ' 3rd part of description is required'
                                                                    )
                                                                }
                                                                if (
                                                                    errorList.length >
                                                                    0
                                                                ) {
                                                                    throw new Error(
                                                                        errorList.toString()
                                                                    )
                                                                }
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '1stPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                                <StyledSpan>
                                                    the{' '}
                                                    {form.getFieldValue<
                                                        MachineForm['functionalConnections']
                                                    >('functionalConnections')[
                                                        field.key
                                                    ]?.['1stComponent'] ??
                                                        '1st Component'}
                                                </StyledSpan>
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '2ndPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                                <StyledSpan>
                                                    the{' '}
                                                    {form.getFieldValue<
                                                        MachineForm['functionalConnections']
                                                    >('functionalConnections')[
                                                        field.key
                                                    ]?.['2ndComponent'] ??
                                                        '2nd Component'}
                                                </StyledSpan>
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '3rdPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add relationship
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            const partsOfDependentClaim = form.getFieldValue<
                MachineForm['partsOfDependentClaim']
            >('partsOfDependentClaim')
            if (partsOfDependentClaim && partsOfDependentClaim.length > 0) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.dependantComponentsConnections
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.backgroundInvention
                    return newValues
                })
            }
        },
        name: MachineNames.functionalConnections,
        formInputProps: {
            label: (
                <label>
                    Are any components related via a functional relationship
                    rather than physical contact? If yes, add the relationships
                </label>
            ),
        },
    },
    dependantComponentsConnections: {
        component: (
            <Form.List name="dependantComponentsConnections">
                {(fields, { add }) => {
                    const partsOfIndependentClaim = form.getFieldValue<
                        MachineValues['partsOfIndependentClaim']
                    >('partsOfIndependentClaim')

                    const partsOfDependentClaim = form.getFieldValue<
                        MachineValues['partsOfDependentClaim']
                    >('partsOfDependentClaim')
                    const formatedIndependentOptions =
                        partsOfIndependentClaim.map((item: string) => {
                            item = `${item.replace('+=>', ' of the ')}`
                            return item
                        })
                    const formatedDependentOptions = partsOfDependentClaim
                        .filter((x: string) => !!x)
                        .map((item: string) => {
                            item = `${item.replace('+=>', ' of the ')}`
                            return item
                        })
                    return (
                        <>
                            {fields.map((field) => (
                                <Row gutter={[12, 0]}>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="1st component"
                                                    name={[
                                                        field.name,
                                                        '1stComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '1stComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '1st component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={formatedDependentOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="2nd component"
                                                    name={[
                                                        field.name,
                                                        '2ndComponent',
                                                    ]}
                                                    fieldKey={[
                                                        field.fieldKey,
                                                        '2ndComponent',
                                                    ]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                '2nd component is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        options={formatedIndependentOptions.map(
                                                            (item: string) => ({
                                                                value: item,
                                                                label: item,
                                                            })
                                                        )}
                                                    />
                                                </Form.Item>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[field.name, 'notSet']}
                                            valuePropName="checked"
                                            style={{ marginBottom: '20px' }}
                                        >
                                            <Checkbox>
                                                I want to describe the
                                                connection
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {!form.getFieldValue<
                                        MachineForm['dependantComponentsConnections']
                                    >('dependantComponentsConnections')[
                                        field.name
                                    ]?.notSet ? (
                                        <Col span={24}>
                                            <Form.Item noStyle shouldUpdate>
                                                {() => (
                                                    <Form.Item
                                                        {...field}
                                                        label="Connection type"
                                                        name={[
                                                            field.name,
                                                            'connectionType',
                                                        ]}
                                                        fieldKey={[
                                                            field.fieldKey,
                                                            'connectionType',
                                                        ]}
                                                        hasFeedback
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Connection type is required',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={[
                                                                {
                                                                    value: 'Rotatably connected to',
                                                                    label: 'Rotatably connected to',
                                                                },
                                                                {
                                                                    value: 'Slidably engaged with',
                                                                    label: 'Slidably engaged with',
                                                                },
                                                                {
                                                                    value: 'Coupled to',
                                                                    label: 'Coupled to',
                                                                },
                                                                {
                                                                    value: 'Integral with',
                                                                    label: 'Integral with',
                                                                },
                                                                {
                                                                    value: 'Coextruded with',
                                                                    label: 'Coextruded with',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col span={24}>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    field.name,
                                                    'additionalConnectionInfo',
                                                ]}
                                                rules={[
                                                    {
                                                        validator: async (
                                                            _,
                                                            item
                                                        ) => {
                                                            const dependantComponentsConnections =
                                                                form.getFieldValue<
                                                                    MachineForm['dependantComponentsConnections']
                                                                >(
                                                                    'dependantComponentsConnections'
                                                                )[field.key]
                                                            if (
                                                                dependantComponentsConnections &&
                                                                dependantComponentsConnections.notSet &&
                                                                (item == null ||
                                                                    item === '')
                                                            ) {
                                                                throw new Error(
                                                                    'Description about connectivity is required'
                                                                )
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '1stPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                                <StyledSpan>
                                                    the{' '}
                                                    {form.getFieldValue<
                                                        MachineForm['dependantComponentsConnections']
                                                    >(
                                                        'dependantComponentsConnections'
                                                    )[field.key]?.[
                                                        '1stComponent'
                                                    ] ?? '1st Component'}
                                                </StyledSpan>
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '2ndPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                                <StyledSpan>
                                                    the{' '}
                                                    {form.getFieldValue<
                                                        MachineForm['dependantComponentsConnections']
                                                    >(
                                                        'dependantComponentsConnections'
                                                    )[field.key]?.[
                                                        '2ndComponent'
                                                    ] ?? '2nd Component'}
                                                </StyledSpan>
                                                <StyledFormItem
                                                    name={[
                                                        field.name,
                                                        '3rdPart',
                                                    ]}
                                                >
                                                    <StyledInput
                                                        type="text"
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    />
                                                </StyledFormItem>
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    Add connection
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.backgroundInvention
                return newValues
            })
        },
        name: MachineNames.dependantComponentsConnections,
        formInputProps: {
            label: (
                <label>
                    Add connections between components from Independant Claim
                    and Dependant Claim. Remember that all components from
                    Dependant Claim should have connections with minimum one
                    component from Independent Claim
                </label>
            ),
        },
    },
    backgroundInvention: {
        component: <CrossReferenceAndReletedApplication form={form} />,
        onNext: () => {
            const customSet = form.getFieldValue<
                MachineForm['backgroundInvention']['custom']
            >(['backgroundInvention', 'custom'])
            if (customSet) {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.customBackgroundOfInvention
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                    return newValues
                })
            }
        },
        name: SharedNames.backgroundInvention,
        formInputProps: {
            label: 'Please write Cross-Reference and Related Applications',
        },
    },
    customBackgroundOfInvention: {
        component: <CustomBackgroundOfInnovation form={form} />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                return newValues
            })
        },
        name: MachineNames.customBackgroundOfInvention,
        formInputProps: {
            label: 'List the advantage or desirable characteristic that your invention provides but existing options or alternatives fail to provide',
        },
    },
    summaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <SummaryOfSelectedEmbodimentsOfTheInvention
                    form={form}
                    name={
                        SharedNames.summaryOfSelectedEmbodimentsOfTheInvention
                    }
                />
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const summaryOfSelectedEmbodimentsOfTheInvention =
                    form.getFieldValue<
                        MachineForm['summaryOfSelectedEmbodimentsOfTheInvention']
                    >('summaryOfSelectedEmbodimentsOfTheInvention')
                let nextComponent
                const primaryComponentSubComponentsList = form.getFieldValue<
                    MachineForm['primaryComponentSubComponentsList']
                >(`primaryComponentSubComponentsList`)
                if (
                    primaryComponentSubComponentsList === undefined ||
                    primaryComponentSubComponentsList[
                        summaryOfSelectedEmbodimentsOfTheInvention[0]
                    ] === undefined
                ) {
                    nextComponent = MachineNames.abstractOfTheDisclosure
                } else {
                    nextComponent =
                        summaryOfSelectedEmbodimentsOfTheInvention &&
                        summaryOfSelectedEmbodimentsOfTheInvention.length
                            ? MachineNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                            : MachineNames.abstractOfTheDisclosure
                }
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = nextComponent
                return newValues
            })
        },
        name: SharedNames.summaryOfSelectedEmbodimentsOfTheInvention,
        formInputProps: {
            label: 'Please select the combination of components that you belive are novel over the prior art.',
        },
    },
    subSummaryOfSelectedEmbodimentsOfTheInvention: {
        component: (
            <>
                <SubSummaryOfSelectedEmbodimentsOfTheInvention
                    form={form as any}
                    subComponentIndex={getCurrentListItemIndex(
                        activeQuestions,
                        currentQuestionIndex,
                        MachineNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                    )}
                />
            </>
        ),
        onNext: () => {
            setActiveQuestions((prev) => {
                const currentListItemIndex = getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex + 1,
                    MachineNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                )
                let nextComponent
                const summaryOfSelectedEmbodimentsOfTheInvention =
                    form.getFieldValue<
                        MachineForm['summaryOfSelectedEmbodimentsOfTheInvention']
                    >('summaryOfSelectedEmbodimentsOfTheInvention') ?? []
                const isExistSubcomponent = currentListItemIndex
                if (
                    isExistSubcomponent <=
                    summaryOfSelectedEmbodimentsOfTheInvention.length
                ) {
                    if (
                        form.getFieldValue<
                            MachineForm['primaryComponentSubComponentsList']
                        >('primaryComponentSubComponentsList')[
                            summaryOfSelectedEmbodimentsOfTheInvention[
                                isExistSubcomponent
                            ]
                        ] === undefined
                    ) {
                        const newValues = [...prev]
                        newValues[currentQuestionIndex + 1] =
                            MachineNames.abstractOfTheDisclosure
                        return newValues
                    }
                    nextComponent =
                        currentListItemIndex <
                        summaryOfSelectedEmbodimentsOfTheInvention.length
                            ? MachineNames.subSummaryOfSelectedEmbodimentsOfTheInvention
                            : MachineNames.abstractOfTheDisclosure
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] = nextComponent
                    return newValues
                }
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.abstractOfTheDisclosure
                return newValues
            })
        },
        name: MachineNames.subSummaryOfSelectedEmbodimentsOfTheInvention,
        formInputProps: {
            label: undefined,
        },
    },
    abstractOfTheDisclosure: {
        component: <AbstractOfTheDisclosure form={form as any} />,
        name: MachineNames.abstractOfTheDisclosure,

        formInputProps: {
            label: 'Abstract of the disclosure',
        },
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.detailedDescription
                return newValues
            })
        },
    },
    detailedDescription: {
        component: <DetailedDescription />,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.drawingsDescription
                return newValues
            })
        },
        name: SharedNames.backgroundInvention,
        formInputProps: {
            label: 'Please write detailed  description ',
        },
    },
    [MachineNames.drawingsDescription]: {
        component: (
            <DrawingsDescription
                form={form}
                characteristicComponentIndex={getCurrentListItemIndex(
                    activeQuestions,
                    currentQuestionIndex,
                    MachineNames.characteristic
                )}
            />
        ),
        name: MachineNames.drawingsDescription,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] =
                    MachineNames.doneDescribing
                return newValues
            })
        },
    },
    doneDescribing: {
        component: (
            <Form.Item name={['doneDescribing', 'indefiniteArticle']}>
                <Select
                    labelInValue
                    options={[
                        {
                            value: 'yes',
                            label: 'yes',
                        },
                        {
                            value: 'no',
                            label: 'no',
                        },
                    ]}
                />
            </Form.Item>
        ),
        onNext: () => {
            const done = form.getFieldValue<
                MachineForm['doneDescribing']['indefiniteArticle']
            >(['doneDescribing', 'indefiniteArticle'])
            if (done.value === 'yes') {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] = MachineNames.lastPane
                    return newValues
                })
            } else {
                setActiveQuestions((prev) => {
                    const newValues = [...prev]
                    newValues[currentQuestionIndex + 1] =
                        MachineNames.extraOptions
                    return newValues
                })
            }
        },
        name: MachineNames.doneDescribing,
        formInputProps: {
            label: 'Are you done describing the machine?',
        },
    },
    extraOptions: {
        component: (
            <>
                <Form.Item label="Do you want to add another component?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    MachineNames.primaryComponents
                                )
                            )
                        }}
                        block
                    >
                        Go to components
                    </Button>
                </Form.Item>
                <Form.Item label="Do you want to add feature to components?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    MachineNames.mainSubcomponents
                                )
                            )
                        }}
                        block
                    >
                        Go to subcomponents
                    </Button>
                </Form.Item>
                <Form.Item label="Do you want to change characteristics?">
                    <Button
                        onClick={() => {
                            setCurrentQuestionIndex(
                                activeQuestions.indexOf(
                                    MachineNames.componentsForCharacteristics
                                )
                            )
                        }}
                        block
                    >
                        Go to characteristics
                    </Button>
                </Form.Item>
                <Form.Item
                    label="Do you need to talk to patent professional?"
                    name={[MachineNames.extraOptions, 'patent-proffesional']}
                    initialValue="no"
                >
                    <Select
                        labelInValue
                        options={[
                            {
                                value: 'yes',
                                label: 'yes',
                            },
                            {
                                value: 'no',
                                label: 'no',
                            },
                        ]}
                    />
                </Form.Item>
            </>
        ),
        name: MachineNames.extraOptions,
        onNext: () => {
            setActiveQuestions((prev) => {
                const newValues = [...prev]
                newValues[currentQuestionIndex + 1] = MachineNames.lastPane
                return newValues
            })
        },
    },
    lastPane: {
        component: <></>,
        isLastQuestion: true,
        name: MachineNames.lastPane,
        formInputProps: {
            label: 'Thank you for completing this form!',
        },
    },
})
