import { Form, Select, Input, Button, Col, Row } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { ChangeEvent, FC, useState } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MethodsForm, MethodsNames } from '../types'

type Props = {
    form: CustomFormInstance<MethodsForm>
    name: MethodsNames.methodOutlineInputs
}

const MethodOutlineInputs: FC<Props> = ({ form, name }) => {
    const methodOutline =
        form.getFieldValue<MethodsForm['methodOutline']>('methodOutline') ?? []

    const [choosenMethodOutline, setChoosenMethodOutline] = useState<string>(
        methodOutline[0].methodStep
    )
    const handleChange = (value: SelectValue) => {
        const method = (value ?? '').toString()
        setChoosenMethodOutline(method)
    }

    const handleTextChange = (
        index: number,
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { finalSentence } = form.getFieldValue<
            MethodsForm['methodOutlineDescription']
        >('methodOutlineDescription')[choosenMethodOutline]
        const methodOutlineInputs = form.getFieldValue<any>(name)
        methodOutlineInputs[choosenMethodOutline][
            index
        ].optionSentence = `${event?.target?.value} ${finalSentence}`
        form.setFieldsValue({ methodOutlineInputs })
    }

    const options = methodOutline.map((item: any) => ({
        value: item.methodStep,
        label: item.methodStep,
    }))

    return (
        <>
            <Form.Item label="Choose method step you want to describe">
                <Select
                    options={options}
                    onChange={handleChange}
                    value={choosenMethodOutline}
                />
            </Form.Item>
            <Form.List name={[name, choosenMethodOutline]}>
                {(fields, { add }) => (
                    <>
                        {fields.map((field) => (
                            <Row gutter={[12, 0]}>
                                <Col span={10}>
                                    <Form.Item noStyle shouldUpdate>
                                        {() => (
                                            <Form.Item
                                                {...field}
                                                label="Indefinite Article"
                                                name={[
                                                    field.name,
                                                    'indefiniteArticle',
                                                ]}
                                                fieldKey={[
                                                    field.fieldKey,
                                                    'indefiniteArticle',
                                                ]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Indefinite article is required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={[
                                                        {
                                                            value: 'a',
                                                            label: 'a',
                                                        },
                                                        {
                                                            value: 'an',
                                                            label: 'an',
                                                        },
                                                        {
                                                            value: '',
                                                            label: 'none',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        {...field}
                                        label="Method input component"
                                        name={[field.name, 'inputComponent']}
                                        fieldKey={[
                                            field.fieldKey,
                                            'inputComponent',
                                        ]}
                                    >
                                        <Input
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                handleTextChange(
                                                    field.fieldKey,
                                                    e
                                                )
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add method input component
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}

export default MethodOutlineInputs
