import { Form, Select } from 'antd'
import { FC } from 'react'
import { CustomFormInstance } from '../../../../types'
import { MachineForm, ManufactureForm } from '../types'

type FormValues = MachineForm | ManufactureForm

type Props = {
    form: CustomFormInstance<MachineForm> | CustomFormInstance<ManufactureForm>
}

const MainSubcomponents: FC<Props> = ({ form }) => {
    const primaryComponents =
        form.getFieldValue<FormValues['primaryComponents']>(
            'primaryComponents'
        ) ?? []

    return (
        <Form.Item name="mainSubcomponents">
            <Select
                mode="multiple"
                options={primaryComponents.map((item) => ({
                    value: item.id ?? '',
                    label: item.component,
                }))}
            />
        </Form.Item>
    )
}

export default MainSubcomponents
