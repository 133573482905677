import { IPasswordData } from '../components/PasswordForm/PasswordForm.types'
import { IProfileData } from '../components/ProfileDetailsForm/ProfileDataForm.types'

export enum REQUEST_STATUS {
    NONE = 'NONE',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift'

export type FixedLengthArray<
    T,
    L extends number,
    TObj = [T, ...Array<T>]
> = Pick<TObj, Exclude<keyof TObj, ArrayLengthMutationKeys>> & {
    readonly length: L
    [I: number]: T
    [Symbol.iterator]: () => IterableIterator<T>
}

export type ISaveUser = IProfileData | IPasswordData
