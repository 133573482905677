import { FormInstance, Form, Select, Input, Checkbox, Col, Row } from 'antd'
import { FC, useState } from 'react'

const DetailedDescription: FC = () => {
    const [checked, setChecked] = useState(false)

    return (
        <>
            <p>Detailed Description</p>
            <Form.Item
                name={['detailedDescription', 'describe-disclosure']}
                rules={[
                    {
                        required: true,
                        message: 'This field is required',
                    },
                ]}
                hasFeedback
            >
                <Input type="text" />
            </Form.Item>
            <Form.Item
                name={['detailedDescription', 'checked']}
                valuePropName="checked"
                style={{ marginBottom: '20px' }}
            >
                <Checkbox>add boiler plate</Checkbox>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.commonName?.notSet !==
                    curValues.commonName?.notSet
                }
            >
                {({ getFieldValue }) => {
                    const bool = getFieldValue([
                        'detailedDescription',
                        'checked',
                    ])
                    return bool ? (
                        <Row align="middle" gutter={[12, 0]}>
                            <Col span={14}>
                                <Form.Item
                                    name={['detailedDescription', 'described']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: !bool,
                                            message: 'example is required',
                                        },
                                    ]}
                                >
                                    <Input placeholder="example" />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null
                }}
            </Form.Item>
        </>
    )
}

export default DetailedDescription
